import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchThemesData } from '@brame/components/src/services';
import CampaignService from '../../api-client/campaigns.service';
import AssetsService from '../../api-client/assets.service';
import { AssetType } from '../../types';
import TemplateService from '../../api-client/template.service';

interface campaignIProps {
  companyId: string | undefined;
  campaignId: string | undefined;
}

const fetchInitialStateAsync = createAsyncThunk(
  'campaigns/fetchByCompanyAndCampaign',
  async (payload: campaignIProps) => {
    const campaignData =
      await CampaignService.getCampaignDataByCompanyAndCampaign(
        payload.companyId,
        payload.campaignId
      );
    return campaignData;
  }
);

const fetchInitialThemeState = createAsyncThunk(
  'themes/fetchThemes',
  async (companyId: string | undefined) => {
    return await fetchThemesData(companyId);
  }
);

const fetchCampaignMetaDataAsync = createAsyncThunk(
  'campaign/fetchCampaignData',
  async (campaignId: string | undefined) => {
    return await CampaignService.getCampaignMetaData(campaignId);
  }
);

const fetchBrameAssets = createAsyncThunk(
  'assets/brame',
  (assetType: AssetType) => {
    return AssetsService.getBrameAssets(assetType);
  }
);

const fetchGlobalAssets = createAsyncThunk(
  'assets/global',
  async (assetType: AssetType) => {
    return await AssetsService.getAssets(assetType);
  }
);

const fetchPersonalAssets = createAsyncThunk(
  'assets/personal',
  async (companyId: string) => {
    return await AssetsService.getPersonalAssets(companyId);
  }
);

const fetchTemplatesData = createAsyncThunk(
  'templates/fetchTemplates',
  async (companyId: string | undefined) => {
    return await TemplateService.getTemplatesData(companyId);
  }
);

const searchGlobalAssets = createAsyncThunk(
  'assets/search',
  async ({ assetType, query }: { assetType: AssetType; query: string }) => {
    return await AssetsService.searchAssets(assetType, query);
  }
);

export {
  fetchInitialStateAsync,
  fetchCampaignMetaDataAsync,
  fetchInitialThemeState,
  fetchBrameAssets,
  fetchGlobalAssets,
  fetchPersonalAssets,
  searchGlobalAssets,
  fetchTemplatesData,
};
