import { css } from '@emotion/react';

export const useStyles = () => ({
  flexDiv: css`
    display: flex;
    gap: 35px;
  `,
  advancedDiv: css`
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  timeTitle: css`
    width: 50%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 19px;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.8);
    margin-left: 5px;
    margin-right: 35px;
  `,
  timeInnerTitle: css`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.7);
  `,
  timeDiv: css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  `,
  timeFlexWrapDiv: css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-left: 15px;
  `,
  flexWrapDiv: css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  `,
  timeSizing: css`
    box-sizing: border-box;
    width: 50%;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  `,
  sizing: css`
    box-sizing: border-box;
    margin-bottom: 15px;
    width: 40%;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  `,
  sizeTitle: css`
    margin-bottom: 6px;
    width: 50%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.7);
  `,
  startDivTitle: css`
    margin-bottom: 15px;
    margin-top: 15px;
    margin-left: -5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 19px;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.8);
  `,
  divTitle: css`
    margin-bottom: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.7);
  `,
  postTimeDivTitle: css`
    margin-top: 15px;
    margin-bottom: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.7);
  `,
  advancedTitle: css`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 19px;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.8);
    margin-left: -5px;
  `,
  enumStyle: css`
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-bottom: 10px;
    margin-left: 5px;
    width: 80%;
  `,
  visualDiv: css`
    margin-top: 15px;
    margin-bottom: 15px;
  `,
  marginLeft: css`
    margin-left: 5px;
  `,
});
