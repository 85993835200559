import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const useTabsStyles = (theme: Theme) => ({
  tabs: css`
    min-height: 40px;
    height: 40px;

    & .MuiTabs-indicator {
      height: 1px;
    }
  `,
  tab: css`
    color: ${theme.palette.text.primary};
    padding: 6px 15px;

    &.Mui-selected {
      color: ${theme.palette.text.primary};
    }
  `,
});
