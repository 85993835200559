import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../reducers/app/hooks';
import { selectAuth } from '../../reducers/auth/authSlice';
import AssetsService from '../../api-client/assets.service';
import { removePersonalAsset as removePersonalAssetAction } from '../../reducers/builder/assetSlice';
import { IAsset } from '../../types';

const useAssets = () => {
  const dispatch = useAppDispatch();
  const companyId = useAppSelector(selectAuth).parsedToken.company_id;
  const [assetIsRemoving, setAssetIsRemoving] = useState<boolean>(false);

  const removePersonalAsset = async (asset: IAsset) => {
    if (asset && companyId) {
      setAssetIsRemoving(true);
      await AssetsService.removePersonalAsset(companyId, asset.uuid)
        .then(() => {
          dispatch(removePersonalAssetAction({ id: asset.uuid }));
        })
        .finally(() => {
          setAssetIsRemoving(false);
        });
    }
  };

  return { removePersonalAsset, assetIsRemoving };
};

export default useAssets;
