import { css } from '@emotion/react';
import image from '../../assets/switch-white.svg';

export const useStyles = () => ({
  rightPanel: css`
    position: relative;
    box-shadow: 0 10px 20px #23470720;
  `,
  switch: css`
    width: 20px;
    height: 60px;
    background: url(${image}) center no-repeat;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
    cursor: pointer;
    filter: drop-shadow(0 10px 20px #23470720);
    z-index: 3;
  `,
  messageBox: css`
    padding: 20px;
  `,
  breadcrumbsBox: css`
    padding: 20px 5px 5px 5px;
  `,
  tabPanel: css`
    margin-top: 10px;
  `,
});
