/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC } from 'react';
import { Monitor, Icon } from 'phosphor-react';
import { useStyles } from './styles';

interface IProps {
  Icon: Icon;
  color?: string;
}

const CustomIcon: FC<IProps> = ({ Icon, color, ...props }) => {
  const styles = useStyles();
  return (
    <div css={styles.iconWrapper} {...props}>
      <Icon size={25} weight="regular" color={color} css={styles.iconUpper} />
      <Monitor size={50} weight="thin" color={color} />
    </div>
  );
};

export default CustomIcon;
