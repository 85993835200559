/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { DotsSixVertical } from 'phosphor-react';
import React from 'react';
import { useStyles } from './style';

const ResizeHandle: React.FC<{ side: 'right' | 'left' }> = ({ side }) => {
  const styles = useStyles();

  return (
    <div
      css={css([
        styles.resizableHandle,
        css`
          margin: 0 0 0 ${side === 'left' ? '-15px' : '5px'};
          border-radius: ${side === 'left' ? '16px 0 0 16px' : '0 16px 16px 0'};
        `,
      ])}
    >
      <DotsSixVertical color="#fff" size={16} />
    </div>
  );
};

export default ResizeHandle;
