/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FormControlLabel, Switch } from '@mui/material';
import { ToolbarCategory } from '../ToolbarCategory';
import useAnalytics from '../../hooks/useAnalytics';
import { CampaignLink } from '../CampaignLink';

const Settings = () => {
  const { trackingEnabled, setTracking } = useAnalytics();

  return (
    <div data-testid="settings">
      <ToolbarCategory title="campaign link">
        <CampaignLink />
      </ToolbarCategory>
      <ToolbarCategory title="settings">
        <FormControlLabel
          control={<Switch checked={trackingEnabled} />}
          label="Enable analytics"
          onChange={(e, checked) => setTracking(checked)}
        />
      </ToolbarCategory>
    </div>
  );
};

export default Settings;
