/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  ListItem,
  Popover,
  Typography,
} from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { spinToIndex } from '@brame/components/src/phaser/games/SlotMachine/SlotMachineScene';
import { IGameArrayElementProps } from '../../GameArrayEditor/IProps';
import { useAppSelector } from '../../../../reducers/app/hooks';
import { selectGameSettings } from '../../../../reducers/game/gameSlice';
import GameNumberEditor from '../../GameNumberEditor';
import GameStringEditor from '../../GameStringEditor';
import GameAssetEditor from '../../GameAssetEditor';
import { GameOutcomeInterface } from '../../GameInterfaces';
import GameEnumEditor from '../../GameEnumEditor';

import { useStyles } from './styles';

const SlotMachineIconEditor: FC<IGameArrayElementProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLImageElement | null>(null);

  const styles = useStyles();

  const gameState = useAppSelector(selectGameSettings);
  //@ts-ignore
  const texKey = gameState[props.name][props.index]['textureKey'];
  //@ts-ignore
  const iconUUID = gameState[props.name][props.index]['uuid'];
  //@ts-ignore
  const collecName = gameState[props.name][props.index]['name'];
  //@ts-ignore
  const imgSrc = gameState['assets'][texKey];

  const outcomeArray = gameState['outcomes'].map(
    (value: GameOutcomeInterface) => {
      return value.outcomeName;
    }
  );

  const uuidArray = gameState['outcomes'].map((value: GameOutcomeInterface) => {
    return value.uuid;
  });

  const handleOpen = (event: any) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setOpen(false);

  const handleRemove = () => {
    props.removeFunction(props.index);
  };

  const mockSpinTheWheel = () => {
    spinToIndex(iconUUID);
  };

  return (
    <ListItem css={styles.listItem} data-testid="sloticon-item">
      <Box css={styles.box1}>
        <Box css={styles.box2}>
          <img src={imgSrc} alt={collecName} height="100%" />
        </Box>
        <Box css={styles.box3}>
          <Typography sx={{ paddingLeft: 0.5 }}>{collecName}</Typography>
        </Box>
        <Box css={styles.box4}>
          <IconButton onClick={handleOpen}>
            <ModeEditOutlineOutlinedIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={handleRemove}>
            <DeleteOutlinedIcon fontSize="small" />
          </IconButton>
        </Box>
        <Popover open={open} onClose={handleClose} anchorEl={anchorEl}>
          <Box css={styles.box5}>
            <GameAssetEditor
              name="textureKey"
              index={props.index}
              arrayCategory={props.name}
              label="Asset"
              styles={styles.asset}
            />
            <GameStringEditor
              name="name"
              index={props.index}
              arrayCategory={props.name}
              label="Name"
              styles={styles.margin10}
            />
            <GameNumberEditor
              name="probability"
              index={props.index}
              arrayCategory={props.name}
              label="Probability"
              styles={styles.margin10}
              unit="%"
              denominator={1 / 100}
            />
            <GameEnumEditor
              name="outcomeUUID"
              index={props.index}
              arrayCategory={props.name}
              label="Outcome Name"
              styles={styles.margin10}
              descs={outcomeArray}
              enums={uuidArray}
            />
            <Button onClick={mockSpinTheWheel}>Test me</Button>
          </Box>
        </Popover>
      </Box>
    </ListItem>
  );
};

export default SlotMachineIconEditor;
