/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { FC, SyntheticEvent, useMemo, useState } from 'react';
import { Tab, useTheme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { IThemeCollection } from '@brame/theme/src/types';
import ThemePreviewBox from './ThemePreviewBox/ThemePreviewBox';
import { useStyles } from './styles';

interface IProps {
  themeCollection: IThemeCollection;
  currentThemeId: string | null;
  onApplyTheme: (themeId: string | null) => void;
}

const ThemeSwitcher: FC<IProps> = ({
  onApplyTheme,
  themeCollection,
  currentThemeId,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [currentTab, setCurrentTab] = useState('default');

  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  const defaults = useMemo(
    () =>
      Object.values(themeCollection).filter(
        (theme) => theme.type === 'default'
      ),
    [themeCollection]
  );

  const custom = useMemo(
    () =>
      Object.values(themeCollection).filter((theme) => theme.type === 'custom'),
    [themeCollection]
  );

  return (
    <div className="block-wrapper" data-testid="theme-switcher">
      <TabContext value={currentTab}>
        <TabList
          onChange={handleTabChange}
          centered
          css={styles.tabs}
          aria-label="Drop game tabs"
          indicatorColor="secondary"
        >
          <Tab label="Default" value="default" css={styles.tab} disableRipple />
          <Tab
            label="My themes"
            value="custom"
            css={styles.tab}
            disableRipple
          />
        </TabList>

        <TabPanel value="default" css={styles.tabPanel}>
          <div css={styles.themePreviewWrapper}>
            {defaults.map((theme) => (
              <ThemePreviewBox
                theme={themeCollection[theme.id]}
                key={theme.id}
                onSelect={onApplyTheme}
                isCurrent={currentThemeId === theme.id}
              />
            ))}
          </div>
        </TabPanel>
        <TabPanel value="custom" css={styles.tabPanel}>
          <div css={styles.themePreviewWrapper}>
            {custom.map((theme) => (
              <ThemePreviewBox
                theme={themeCollection[theme.id]}
                key={theme.id}
                onSelect={onApplyTheme}
                isCurrent={currentThemeId === theme.id}
              />
            ))}
          </div>
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default ThemeSwitcher;
