/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { usePhaserValueList } from '@brame/components/src/phaser/games/PhaserGameHooks';
import { TextEditor } from '../../TextEditor';
import { SelectInput } from '../../SelectInput';
import { TextArea } from '../../TextArea';
import { IPhaserTextEditorProps } from './IProps';

const PhaserTextEditor: FC<IPhaserTextEditorProps> = ({
  styleProps,
  elementProps,
  gameType,
  onChange,
  onReset,
  props,
}) => {
  //@ts-ignore
  const { valueID, suffix, prefix } = props;
  return (
    <div>
      <SelectInput
        value={valueID || ''}
        options={usePhaserValueList(gameType)}
        onChange={(value) =>
          onChange({
            valueID: value,
          })
        }
      />
      <TextArea
        label="prefix"
        value={prefix || ''}
        onChange={(value) => onChange({ prefix: value })}
      />
      <TextArea
        label="suffix"
        value={suffix || ''}
        onChange={(value) => onChange({ suffix: value })}
      />
      <TextEditor
        styleProps={styleProps}
        elementProps={elementProps}
        onChange={onChange}
        onReset={onReset}
        isPhaser={true}
      />
    </div>
  );
};

export default PhaserTextEditor;
