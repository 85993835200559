import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';
export const useStyles = () => ({
  title: css`
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 700;
    color: ${brameTheme.palette.text.primary};
  `,
  fontBlock: css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-bottom: 1px solid gray;
    padding: 20px 0;

    &:first-of-type {
      padding-top: 0;
    }
    &:last-of-type {
      border: none;
      padding-bottom: 0;
    }
  `,
  accordionSummary: css`
    padding-left: 0;
  `,
  buttonBlock: css`
    margin-top: 30px;
  `,
  button: css`
    &:not(:first-of-type) {
      margin-left: 20px;
    }
  `,
  accordion: css`
    &.Mui-expanded {
      margin: 25px 0;
    }
  `,
  titleBlock: css`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  `,
  fontProps: css`
    display: flex;
    gap: 16px;
  `,
});
