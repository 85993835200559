import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { fetchInitialStateAsync } from '../builder/asyncThunks';
import { Breakpoint, ICanvasDimensions, SharedSlice } from './types';

const initialState: SharedSlice = {
  dropIndicator: {
    show: false,
    top: `0 px`,
    left: `0 px`,
    width: `0 px`,
    height: `0 px`,
  },
  breakpoints: [
    {
      id: 'Mobile',
      breakpoint: 479,
      default: true,
    },
    {
      id: 'Tablet',
      breakpoint: 768,
    },
    {
      id: 'Desktop',
      breakpoint: 1024,
    },
  ],
  selectedBreakpoint: 'Mobile',
  canvasResponsiveness: undefined,
  canvasDimensions: { width: 600, height: 400 },
  dimensions: [],
};

const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    setDropIndicatorVisibility: (state, action: PayloadAction<boolean>) => {
      state.dropIndicator = {
        ...state.dropIndicator,
        show: action.payload,
      };
    },
    setDropIndicatorPosition: (state, action) => {
      state.dropIndicator = { ...state.dropIndicator, ...action.payload };
    },
    selectBreakpoint: (state, action: PayloadAction<string>) => {
      state.selectedBreakpoint = action.payload;
    },
    setCanvasResponsiveness: (state, action) => {
      state.canvasResponsiveness = action.payload;
    },
    setCanvasDimensions: (state, action) => {
      state.canvasDimensions = action.payload;
    },
    addCanvasDimension: (state, action) => {
      state.dimensions.push(action.payload);
    },
  },
  extraReducers: (shared) => {
    shared.addCase(fetchInitialStateAsync.fulfilled, (state, action) => {
      const data = action.payload.data.data;

      state.canvasDimensions = data.canvasDimensions;
      state.canvasResponsiveness = data.canvasResponsiveness;
      state.selectedBreakpoint = data.selectedBreakpoint;
      state.dimensions = data.dimensions;
    });
  },
});

export const {
  setDropIndicatorPosition,
  setDropIndicatorVisibility,
  selectBreakpoint,
  setCanvasResponsiveness,
  setCanvasDimensions,
  addCanvasDimension,
} = sharedSlice.actions;

export const getSelectedBreakpoint = (state: RootState): Breakpoint => {
  return (
    state.shared.breakpoints.find(
      ({ id }) => id === state.shared.selectedBreakpoint
    ) || state.shared.breakpoints[0]
  );
};

export const getBreakpoints = (state: RootState): Breakpoint[] =>
  state.shared.breakpoints;

export const getCanvasResponsiveness = (
  state: RootState
): boolean | undefined => state.shared.canvasResponsiveness;

export const getCanvasDimensions = (state: RootState): ICanvasDimensions =>
  state.shared.canvasDimensions;

export const getDimensions = (state: RootState): ICanvasDimensions[] =>
  state.shared.dimensions;

export default sharedSlice.reducer;
