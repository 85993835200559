/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useStyles } from './styles';

export enum NumberValidationType {
  Range = 'Range of Numbers',
  Mask = 'Number of Characters',
}

export interface IProps {
  props: any;
}

const EditNumber: FC<IProps> = ({ props }) => {
  const styles = useStyles();
  const validationTypes = Object.values(NumberValidationType);

  return (
    <div>
      <FormControl fullWidth sx={{ marginTop: '8px' }}>
        <Typography css={styles.label}>Requires</Typography>
        <Select
          id="numberValidationType"
          name="numberValidationType"
          value={props.values.numberValidationType}
          onChange={(event) => {
            props.handleChange(event);
            if (event.target.value === NumberValidationType.Mask) {
              props.setFieldValue('min', '');
              props.setFieldValue('max', '');
            } else {
              props.setFieldValue('minCharacters', '');
              props.setFieldValue('maxCharacters', '');
            }
          }}
        >
          {validationTypes.map((option) => (
            <MenuItem value={option} key={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div css={styles.container}>
        <FormControl fullWidth>
          <Typography css={styles.label}>Minimum</Typography>
          <TextField
            type="number"
            size="small"
            name={
              props.values.numberValidationType === NumberValidationType.Range
                ? 'min'
                : 'minCharacters'
            }
            value={
              props.values.numberValidationType === NumberValidationType.Range
                ? props.values.min
                : props.values.minCharacters
            }
            onChange={props.handleChange}
            fullWidth
          />
        </FormControl>
        <FormControl fullWidth>
          <Typography css={styles.label}>Maximum</Typography>
          <TextField
            type="number"
            size="small"
            name={
              props.values.numberValidationType === NumberValidationType.Range
                ? 'max'
                : 'maxCharacters'
            }
            value={
              props.values.numberValidationType === NumberValidationType.Range
                ? props.values.max
                : props.values.maxCharacters
            }
            onChange={props.handleChange}
            fullWidth
          />
        </FormControl>
      </div>
    </div>
  );
};

export default EditNumber;
