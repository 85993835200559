import { css } from '@emotion/react';

export const useStyles = () => ({
  bottomMargin: css`
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  visualDiv: css`
    margin-top: 15px;
    margin-bottom: 15px;
  `,
  divTitle: css`
    margin-bottom: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.7);
  `,
});
