import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';

export const useStyles = (inDropArea: boolean) => ({
  dropArea: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80px;
    border: 2px dashed
      ${inDropArea ? brameTheme.palette.primary.main : '#dedede'};
    padding: 8px 20px;
    margin-top: 8px;
    text-align: center;
  `,
  label: css`
    align-self: center;
    font-size: 14px;
    font-weight: 500;
    color: ${brameTheme.palette.text.primary};
  `,
  spinner: css`
    align-self: center;
    margin-top: 8px;
  `,
});
