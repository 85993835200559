import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';

export const useFormEditorStyles = () => ({
  orderButton: css`
    &.MuiListItemButton-root {
      min-width: 30px;
      max-width: 30px;
      justify-content: center;
      margin: 0 8px 0 0;
      padding: 8px;
      border-radius: ${brameTheme.shape.borderRadius}px;

      &:hover {
        color: ${brameTheme.palette.primary.contrastText};
        background: ${brameTheme.palette.primary.main};
      }
    }
  `,
  actionButton: css`
    width: 34px;
    min-width: 34px;
    height: 34px;
    background: ${brameTheme.palette.primary.contrastText};
    color: ${brameTheme.palette.secondary.main};
    border: none;
    border-radius: ${brameTheme.shape.borderRadius}px;
    cursor: pointer;
    padding: 0;
    margin: 0 0 0 9px;

    &:hover {
      background: ${brameTheme.palette.primary.main};
      color: ${brameTheme.palette.primary.contrastText};
    }
  `,
  label: css`
    text-transform: capitalize;
    margin-bottom: 8px;
  `,
  container: css`
    display: flex;
    margin-top: 8px;
  `,
  select: css`
    text-transform: capitalize;
  `,
  menuItem: css`
    text-transform: capitalize;
  `,
});
