import { css } from '@emotion/react';

export const useStyles = () => ({
  listItem: css`
    padding: 0;
  `,
  popoverBox: css`
    width: 250px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  `,
  wrapper: css`
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr 72px;
    height: 30px;
    width: 100%;
    gap: 16px;
  `,
  imageWrapper: css`
    height: 30px;
  `,
  itemText: css`
    text-align: left;
    display: block;
    width: 100%;
  `,
});
