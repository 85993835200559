import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';

export const useStyles = () => ({
  footer: css`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    max-width: calc(100vw - 344px);
    overflow-x: hidden;
    overflow-y: hidden;
    background: #f5f5f5;

    &::-webkit-scrollbar {
      display: none;
    }
  `,
  tabsContainer: css`
    width: 100%;
    height: 100px;
    background: #ffffff;
    border-top: 3px solid ${brameTheme.palette.primary.main};
    box-shadow: 0px -10px 20px rgba(174, 174, 174, 0.3);
    gap: 20px;
    outline: none;
    position: relative;
  `,
  tabs: css`
    border: none;
    background: none;
    display: flex;
    position: absolute;
    top: -34px;
    left: 20px;
    height: fit-content;
    gap: 4px;
  `,
  tab: css`
    font-size: 16px;
    height: 31px;
    border: none;
    background: #ffffff;
    color: ${brameTheme.palette.text.primary};
    border-radius: ${brameTheme.shape.borderRadius}px
      ${brameTheme.shape.borderRadius}px 0 0;
    padding: 8px 9px;
    cursor: pointer;

    &.Mui-selected {
      background: ${brameTheme.palette.primary.main};
      color: ${brameTheme.palette.primary.contrastText};
    }
  `,
});
