import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';

export const useStyles = () => ({
  divider: css`
    height: 100%;
    &.MuiDivider-root {
      border-right-width: 8px;
      border-style: solid;
      border-color: transparent;
    }
  `,
  droppable: css`
    &.MuiDivider-root {
      border-image: linear-gradient(
          to bottom,
          ${brameTheme.palette.primary.main},
          rgba(0, 0, 0, 0)
        )
        1 100%;
    }
  `,
});
