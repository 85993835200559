import React, { FC, useEffect, useMemo } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {
  availableFormFields,
  getCustomField,
  IFormField,
  IFormProps,
} from '@brame/components/src/components/Form';
import { IFormStyleProps } from '../../../types';
import { ToolbarCategory } from '../../ToolbarCategory';
import { FieldList } from '../FieldList';

interface IProps {
  elementProps: IFormProps;
  onChange: (props: IFormStyleProps | IFormProps) => void;
}

export const fieldVariants: IFormField[] = Object.values(
  availableFormFields
).filter((value) => !value.isCustom);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CUSTOM_FIELDS_LIMIT = 5;

const FormControlsEditor: FC<IProps> = ({ elementProps, onChange }) => {
  const { fields } = elementProps;
  const [selectedFields, setSelectedFields] = React.useState<string[]>(
    fields?.map((f) => f?.name) || []
  );

  useEffect(() => {
    const newFields: IFormField[] = [];

    fields?.forEach((f) => {
      if (selectedFields.includes(f.name) || f.isCustom) {
        newFields.push(f);
      }
    });

    selectedFields.forEach((f) => {
      if (!fields?.find((field) => field.name === f)) {
        newFields.push(availableFormFields[f]);
      }
    });

    handleFieldsChange(newFields);
  }, [selectedFields]);

  const handleFieldsChange = (newFields: IFormField[]) => {
    onChange({
      ...elementProps,
      fields: newFields,
    });
  };
  const handleFieldListChange = (newFields: IFormField[]) => {
    setSelectedFields(newFields.map((f) => f.name));
    handleFieldsChange(newFields);
  };

  const addField = () => {
    handleFieldsChange([...(fields || []), getCustomField()]);
  };

  const changeFieldSet = (event: SelectChangeEvent<typeof selectedFields>) => {
    const {
      target: { value },
    } = event;
    setSelectedFields(typeof value === 'string' ? value.split(',') : value);
  };

  const customFieldCantBeAdded = useMemo(
    () =>
      (fields || []).filter((field) => field.isCustom)?.length >=
      CUSTOM_FIELDS_LIMIT,
    [fields]
  );

  return (
    <div data-testid="form-control-editor">
      <ToolbarCategory>
        <Typography>
          The first selected field will be positioned first
        </Typography>
        <Typography>{`You can add up to ${CUSTOM_FIELDS_LIMIT} custom fields`}</Typography>
        <FormControl fullWidth sx={{ marginTop: '16px' }}>
          <Select
            variant="outlined"
            id="select-fields"
            multiple
            fullWidth
            value={selectedFields}
            onChange={changeFieldSet}
            renderValue={(selected) =>
              selected
                .filter((value) => !value.includes('custom'))
                .map(
                  (value) => fieldVariants.find((f) => f.name === value)?.label
                )
                .join(', ')
            }
            MenuProps={MenuProps}
          >
            {fieldVariants.map((field) => (
              <MenuItem key={field.name} value={field.name}>
                <Checkbox checked={selectedFields.indexOf(field.name) > -1} />
                <ListItemText primary={field.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          sx={{ marginTop: '8px' }}
          variant="contained"
          size="small"
          onClick={addField}
          disabled={customFieldCantBeAdded}
        >
          Add Custom Field
        </Button>
        <FieldList fields={fields || []} onChange={handleFieldListChange} />
      </ToolbarCategory>
    </div>
  );
};

export default FormControlsEditor;
