//@ts-nocheck
import { css } from '@emotion/react';

export const useStyles = (active: boolean) => {
  return {
    templateContainer: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100vh - (67px + 63px + 100px));
      overflow-y: auto;
      padding: 15px;
      box-sizing: border-box;
    `,
    successText: css`
      text-align: center;
      text-transform: capitalize;
      color: #88c559;
      font-size: 20px;
      margin: 24px 0;
      visibility: ${active ? `visible` : `hidden`};
      transition: 0.3s all ease-in-out;
    `,
    gridContainer: css`
      height: calc(100vh - (67px + 63px + 100px));
    `,
  };
};
