import { IElementProps, ITextStyleProps } from '../../types';

export const textStylePropsMock: ITextStyleProps = {
  backgroundColor: '#00000000',
  textAlign: 'left',
  font: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: 16,
    textTransform: 'none',
    fontColor: '#fff',
  },
  size: {
    width: { value: 80, unit: 'auto' },
    height: { value: 80, unit: 'auto' },
  },
  decoration: [],
  spacing: {
    margin: {
      top: { value: 1, unit: 'px' },
      right: { value: 1, unit: 'px' },
      bottom: { value: 1, unit: 'px' },
      left: { value: 1, unit: 'px' },
    },
    padding: {
      top: { value: 1, unit: 'px' },
      right: { value: 1, unit: 'px' },
      bottom: { value: 1, unit: 'px' },
      left: { value: 1, unit: 'px' },
    },
  },
};

export const textElementPropsMock: IElementProps = {
  value: 'Test text',
  option: { variant: 'body1' },
};

export const fallbackProps = {
  value: 'Text',
  backgroundColor: '#ffffff00',
  textAlign: 'left',
  font: {
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: 400,
    fontColor: '#666666',
    textTransform: 'none',
  },
  decoration: [],
  spacing: {
    margin: {
      top: { value: 0, unit: 'px' },
      right: { value: 0, unit: 'px' },
      bottom: { value: 0, unit: 'px' },
      left: { value: 0, unit: 'px' },
    },
    padding: {
      top: { value: 5, unit: 'px' },
      right: { value: 5, unit: 'px' },
      bottom: { value: 5, unit: 'px' },
      left: { value: 5, unit: 'px' },
    },
  },
};
