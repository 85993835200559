import { css } from '@emotion/react';

export const useStyles = () => ({
  textarea: css`
    max-width: 100% !important;
    max-height: 246px;
    width: 100%;
    font-family: 'Lato';
  `,
  fontStylesBlock: css`
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 8px;
  `,
  fontStylesBlockCell: css`
    display: flex;
    align-items: center;
    width: 100%;
  `,
});
