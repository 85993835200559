/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { IVideoProps } from '@brame/components/src/components/Video';
import {
  AssetSourceType,
  IAssetData,
  IVideoStyleProps,
  IVideoStylePropsChange,
} from '../../types';
import { ToolbarCategory } from '../ToolbarCategory';
import { SizingWidget } from '../SizingWidget';
import { SpacingWidget } from '../SpacingWidget';
import { AssetsModal, useAssetModal } from '../AssetsModal';
import AssetPlaceholder from '../AssetPlaceholder/AssetPlaceholder';
import { BuilderComponent } from '../../reducers/builder';
import { useAppSelector } from '../../reducers/app/hooks';
import { RootState } from '../../reducers/store';
import { SelectInput } from '../SelectInput';
import { getCurrentAssetFromCollection } from '../ThemeMediaSelector/utils';
import { ThemeMediaSelector } from '../ThemeMediaSelector';
import { useStyles } from './styles';

interface IProps {
  styleProps: IVideoStyleProps;
  elementProps: IVideoProps;
  onChange: (props: IVideoStylePropsChange | IVideoProps) => void;
  onReset: () => void;
}

const VideoEditor: FC<IProps> = ({
  styleProps,
  elementProps,
  onChange,
  onReset,
}) => {
  const {
    size,
    spacing: { margin },
  } = styleProps;
  const { src, asset, controls, autoplay, themedVideo } = elementProps;
  const { assetsModalIsOpen, openAssetsModal, closeAssetsModal } =
    useAssetModal();
  const styles = useStyles();
  const isDisabled = !asset && !src && !themedVideo;

  const applyAsset = (asset: IAssetData) => {
    onChange({ ...elementProps, asset: asset, src: '', themedVideo: '' });
    closeAssetsModal();
  };

  const collection = useAppSelector(
    (state: RootState) =>
      state.theme.currentTheme.assets.defaultCreatives.videos
  );

  const choseAssetSource = () => {
    if (
      themedVideo &&
      // @ts-ignore
      getCurrentAssetFromCollection(themedVideo, collection)
    ) {
      return AssetSourceType.THEME_VIDEO;
    }

    return AssetSourceType.LIBRARY;
  };

  const [videoSource, setVideoSource] = useState<AssetSourceType>(
    choseAssetSource()
  );

  const removeVideo = () => {
    onChange({
      ...elementProps,
      asset: null,
      src: '',
      themedVideo: '',
    });
  };

  useEffect(() => {
    setVideoSource(choseAssetSource());
  }, [themedVideo, collection]);

  const sourceOptions: AssetSourceType[] = [
    AssetSourceType.LIBRARY,
    AssetSourceType.URL,
    AssetSourceType.THEME_VIDEO,
  ];

  return (
    <div data-testid="video-editor">
      <ToolbarCategory>
        <SelectInput
          label="Image Source"
          value={videoSource}
          onChange={(value) => setVideoSource(value as AssetSourceType)}
          options={sourceOptions}
        />

        {videoSource === AssetSourceType.LIBRARY && (
          <AssetPlaceholder
            type={BuilderComponent.VIDEO}
            src={src}
            asset={asset}
            onClick={openAssetsModal}
          />
        )}

        {videoSource === AssetSourceType.URL && (
          <TextField
            label="Source url"
            value={src}
            InputLabelProps={{ shrink: true }}
            hiddenLabel
            onChange={(e) =>
              onChange({ ...elementProps, src: e.target.value, asset: null })
            }
          />
        )}

        {videoSource === AssetSourceType.THEME_VIDEO && (
          <ThemeMediaSelector
            src={themedVideo}
            video
            onChange={(src) =>
              onChange({
                ...elementProps,
                asset: null,
                src: '',
                themedVideo: src,
              })
            }
          />
        )}

        <Button
          variant="text"
          size="small"
          css={styles.removeButton}
          disabled={isDisabled}
          onClick={removeVideo}
        >
          Remove Video
        </Button>
      </ToolbarCategory>

      <ToolbarCategory title="Size">
        <SizingWidget
          size={size}
          onChange={(values) => onChange({ size: values })}
        />
      </ToolbarCategory>
      <ToolbarCategory>
        <FormControlLabel
          checked={controls}
          control={
            <Checkbox
              onChange={(event) => {
                onChange({ ...elementProps, controls: event.target.checked });
              }}
            />
          }
          label="Controls"
        />
        <FormControlLabel
          checked={autoplay}
          control={
            <Checkbox
              onChange={(event) => {
                onChange({ ...elementProps, autoplay: event.target.checked });
              }}
            />
          }
          label="Autoplay"
        />
      </ToolbarCategory>
      <ToolbarCategory title="Margin">
        <SpacingWidget
          spacing={margin}
          onChange={(values) => onChange({ spacing: { margin: values } })}
        />
      </ToolbarCategory>

      <ToolbarCategory>
        <Button
          data-testid="reset-styles-button"
          onClick={() => {
            removeVideo();
            onReset();
          }}
          size="small"
        >
          Reset
        </Button>
      </ToolbarCategory>

      <AssetsModal
        isOpen={assetsModalIsOpen}
        onApplyAsset={applyAsset}
        onCancel={closeAssetsModal}
        elementType={BuilderComponent.VIDEO}
      />
    </div>
  );
};

export default VideoEditor;
