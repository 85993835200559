/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { Box, IconButton } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import GameStringEditor from '../../GameStringEditor';
import GameEnumEditor from '../../GameEnumEditor';
import { useAppSelector } from '../../../../reducers/app/hooks';
import { PageElement } from '../../../../reducers/builder';
import { selectPages } from '../../../../reducers/builder/builderSlice';
import { selectGameSettings } from '../../../../reducers/game/gameSlice';
import GameDefaultOutcomeEditor from '../../GameDefaultOutcomeEditor';
import { ISlotMachineOutcomeEditorProps } from './IProps';
import { useStyles } from './styles';

const SlotMachineOutcomeEditor: FC<ISlotMachineOutcomeEditorProps> = (
  props
) => {
  const styles = useStyles();
  const pages = useAppSelector(selectPages);
  const gameState = useAppSelector(selectGameSettings);
  const pageNameArray: (string | undefined)[] = [];
  const pageIDArray: (string | undefined)[] = [];
  pages.ids.map((pageId) => {
    const page = pages.entities[pageId] as PageElement;
    pageNameArray.push(page.name);
    pageIDArray.push(page.id as string);
  });

  const handleRemove = () => {
    props.removeFunction(props.index);
  };

  if (gameState[props.name][props.index]['outcomeName'] !== 'Default') {
    return (
      <Box sx={{ padding: 1 }}>
        <GameStringEditor
          name="outcomeName"
          styles={styles.container}
          index={props.index}
          arrayCategory={props.name}
          label="Name of the Outcome"
        />
        <GameEnumEditor
          name="page"
          styles={styles.container}
          index={props.index}
          arrayCategory={props.name}
          label="Page"
          enums={pageIDArray}
          descs={pageNameArray}
        />
        <IconButton onClick={handleRemove}>
          <DeleteOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  } else {
    return (
      <GameDefaultOutcomeEditor
        index={props.index}
        name={props.name}
        removeFunction={props.removeFunction}
      />
    );
  }
};
export default SlotMachineOutcomeEditor;
