import { css } from '@emotion/react';
import { IBorderCss } from '@brame/builder/src/types';

export interface IStylesProps extends IBorderCss {
  textAlign: string;
}

export const useStyles = (props: IStylesProps) => ({
  label: css`
    transform: none;
    width: 100%;
    text-align: ${props.textAlign};
  `,
  input: css`
    & .MuiOutlinedInput-root {
      & > fieldset {
        border-color: ${props.borderColor};
        border-style: ${props.borderStyle};
        border-radius: ${props.borderRadius};
        border-width: ${props.borderWidth};
      }

      &:hover:not(.Mui-focused) > fieldset {
        border-color: ${props.borderColorHover};
      }

      &.Mui-focused > fieldset {
        border-width: ${props.borderWidth};
      }
    }

    & .MuiFilledInput-underline,
    & .MuiInput-underline {
      &:before {
        border-bottom-color: ${props.borderColor};
        border-bottom-style: ${props.borderStyle};
        border-bottom-width: ${props.borderWidth};
        transition: border-bottom-color;
      }

      &:hover:not(.Mui-disabled):before {
        border-bottom-color: ${props.borderColorHover};
        border-bottom-style: ${props.borderStyle};
        border-bottom-width: ${props.borderWidth};
      }

      &.Mui-focused:not(.Mui-disabled):after {
        border-bottom-style: ${props.borderStyle};
        border-bottom-width: ${props.borderWidth};
        transition: color;
      }
    }
  `,
});
