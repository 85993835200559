import { css } from '@emotion/react';

export const useStyles = () => ({
  container: css`
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  menuItem: css`
    text-transform: capitalize;
  `,
  title: css`
    display: block;
    text-transform: capitalize;
  `,
  select: css`
    text-transform: capitalize;
  `,
});
