import { useState } from 'react';
import { camelCase } from 'lodash';
import { WizzardType } from '../CampaignType/tabsData';
import { initialWizzardState, IWizzard } from '../initialWizzardState';

export const useWizzardState = () => {
  const [wizzardState, setWizzardState] = useState(initialWizzardState);

  const handleWizardState = (stepName: string, payload: IWizzard) => {
    switch (stepName) {
      case WizzardType.CAMPAIGN_TYPE:
        return setWizzardState({
          ...wizzardState,
          [camelCase(WizzardType.CAMPAIGN_TYPE)]: {
            gameId: payload.campaignType.gameId,
            isDisable: payload.campaignType.isDisable,
          },
        });
      case WizzardType.CAMPAIGN_DETAILS:
        return setWizzardState({
          ...wizzardState,
          [camelCase(WizzardType.CAMPAIGN_DETAILS)]: {
            name: payload.campaignDetails.name,
            description: payload.campaignDetails.description,
            isDisable: !payload.campaignDetails.name,
          },
        });
      case WizzardType.CAMPAIGN_THEME:
        return setWizzardState({
          ...wizzardState,
          [camelCase(WizzardType.CAMPAIGN_THEME)]: {
            themeId: payload.campaignTheme.themeId,
            isDisable: !payload.campaignTheme.isDisable,
          },
        });
      case WizzardType.CAMPAIGN_TEMPLATE:
        return setWizzardState({
          ...wizzardState,
          [camelCase(WizzardType.CAMPAIGN_TEMPLATE)]: {
            templateId: payload.campaignTemplate.templateId,
            isDisable: !payload.campaignTemplate.isDisable,
          },
        });
      default:
        return;
    }
  };

  return {
    wizzardState,
    handleWizardState,
  };
};
