// @ts-nocheck

export const getDOMInfo = (el: HTMLElement) => {
  const { x, y, top, left, bottom, right, width, height } =
    el.getBoundingClientRect();

  const style = window.getComputedStyle(el);

  const margin = {
    left: parseInt(style.marginLeft),
    right: parseInt(style.marginRight),
    bottom: parseInt(style.marginBottom),
    top: parseInt(style.marginTop),
  };

  const padding = {
    left: parseInt(style.paddingLeft),
    right: parseInt(style.paddingRight),
    bottom: parseInt(style.paddingBottom),
    top: parseInt(style.paddingTop),
  };

  const styleInFlow = (parent: HTMLElement) => {
    const parentStyle: any = getComputedStyle(parent);

    if (style.overflow && style.overflow !== 'visible') {
      return;
    }

    if (parentStyle.float !== 'none') {
      return;
    }

    if (parentStyle.display === 'grid') {
      return;
    }

    if (
      parentStyle.display === 'flex' &&
      parentStyle['flex-direction'] !== 'column'
    ) {
      return;
    }

    switch (style.position) {
      case 'static':
      case 'relative':
        break;
      default:
        return;
    }

    switch (el.tagName) {
      case 'TR':
      case 'TBODY':
      case 'THEAD':
      case 'TFOOT':
        return true;
    }

    switch (style.display) {
      case 'block':
      case 'list-item':
      case 'table':
      case 'flex':
      case 'grid':
        return true;
    }
  };

  return {
    x,
    y,
    top,
    left,
    bottom,
    right,
    width,
    height,
    outerWidth: Math.round(width + margin.left + margin.right),
    outerHeight: Math.round(height + margin.top + margin.bottom),
    margin,
    padding,
    inFlow: el.parentElement && !!styleInFlow(el.parentElement),
  };
};

const thickness = 3;
// @ts-ignore
export const getRenderIndicatorPosition = (
  canvasDOMInfo,
  bestTargetDomInfo,
  upwards,
  leftwards
) => {
  let t = 0;
  let l = 0;
  let w = 0;
  let h = 0;

  const elDim = bestTargetDomInfo;

  if (elDim) {
    // If it's not in flow (like 'float' element)
    if (!elDim.inFlow) {
      w = thickness;
      h = elDim.height;
      t = elDim.top;
      l = leftwards ? elDim.left : elDim.left + elDim.outerWidth;
    } else {
      w = elDim.width;
      h = thickness;
      t = upwards ? elDim.top : elDim.bottom;
      l = elDim.left;
    }
  } else if (canvasDOMInfo) {
    t = canvasDOMInfo.top + canvasDOMInfo.padding.top;
    l = canvasDOMInfo.left + canvasDOMInfo.padding.left;
    w =
      canvasDOMInfo.outerWidth -
      canvasDOMInfo.padding.right -
      canvasDOMInfo.padding.left -
      canvasDOMInfo.margin.left -
      canvasDOMInfo.margin.right;
    h = thickness;
  }

  return {
    top: `${t}px`,
    left: `${l}px`,
    width: `${w}px`,
    height: `${h}px`,
  };
};

export default function findPosition(
  parent: Node,
  dims: NodeInfo[],
  posX: number,
  posY: number
) {
  const result: DropPosition = {
    parent,
    index: 0,
    where: 'before',
  };

  let leftLimit = 0,
    xLimit = 0,
    dimRight = 0,
    yLimit = 0,
    xCenter = 0,
    yCenter = 0,
    dimDown = 0;

  // Each dim is: Top, Left, Height, Width
  for (let i = 0, len = dims.length; i < len; i++) {
    const dim = dims[i];

    // Right position of the element. Left + Width
    dimRight = dim.left + dim.outerWidth;
    // Bottom position of the element. Top + Height
    dimDown = dim.top + dim.outerHeight;
    // X center position of the element. Left + (Width / 2)
    xCenter = dim.left + dim.outerWidth / 2;
    // Y center position of the element. Top + (Height / 2)
    yCenter = dim.top + dim.outerHeight / 2;
    // Skip if over the limits
    if (
      (xLimit && dim.left > xLimit) ||
      (yLimit && yCenter >= yLimit) || // >= avoid issue with clearfixes
      (leftLimit && dimRight < leftLimit)
    )
      continue;

    result.index = i;
    // If it's not in flow (like 'float' element)
    if (!dim.inFlow) {
      if (posY < dimDown) yLimit = dimDown;
      //If x lefter than center
      if (posX < xCenter) {
        xLimit = xCenter;
        result.where = 'before';
      } else {
        leftLimit = xCenter;
        result.where = 'after';
      }
    } else {
      // If y upper than center
      if (posY < yCenter) {
        result.where = 'before';
        break;
      } else result.where = 'after'; // After last element
    }
  }

  return result;
}
