import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../reducers/app/hooks';
import { selectAuth, setAuth } from '../reducers/auth/authSlice';
import { isLocalHost } from './utils';
import { testUser } from './authConfig';
import AuthService from './auth.service';

/**
 * Returns the auth state.
 *
 * If you pass no status it will look for given cookies.
 *
 * If you pass status: "loggedIn" it simulates a loggedIn user.
 *
 * If you pass status: "loggedOut" it simulates a loggedOut user.
 *
 * If you pass status: "loading" it simulates a loading user.
 */
export function useAuth(status?: string) {
  const authState = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const signIn = async () => {
      await AuthService.signInWithEmailAndPassword(
        testUser.email,
        testUser.password
      );
      dispatch(setAuth(AuthService.getToken()));
    };
    const signOut = async () => {
      AuthService.removeTokens();
      dispatch(setAuth('loggedOut'));
    };
    const refreshToken = async () => {
      const res = await AuthService.refreshToken(AuthService.getRefreshToken());
      if (res.status !== '200') {
        signOut();
      } else {
        dispatch(setAuth(AuthService.getToken()));
      }
    };
    switch (status) {
      case 'loading':
        return;
      case 'loggedIn':
        signIn();
        return;
      case 'loggedOut':
        signOut();
        refreshToken();
        return;
      default:
        if (isLocalHost(window.location.href)) {
          signIn();
        } else {
          refreshToken();
          // dispatch(setAuth(AuthService.getToken()));
        }
        return;
    }
  }, [dispatch, status]);
  return authState;
}
