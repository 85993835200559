/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useEffect } from 'react';
import { Button, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../reducers/app/hooks';
import {
  changeGameType,
  selectGameSettings,
} from '../../../reducers/game/gameSlice';

import GameBooleanEditor from '../GameBooleanEditor';
import DropGameToolbar from '../DropGame/DropGameToolbar';
import SlotMachineToolbar from '../SlotMachine/SlotMachineToolbar';
import ToolbarCategory from '../../ToolbarCategory/ToolbarCategory';
import { useStyles } from './styles';
import { IGameToolbarProps } from './IProps';
import { PHASER_GAME_TYPE } from './GameTypes';

const GameToolbar: FC<IGameToolbarProps> = (props) => {
  const { onChange, styleProps, onReset } = props;
  const dispatch = useAppDispatch();
  const gameState = useAppSelector(selectGameSettings);
  const styles = useStyles();

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(
      changeGameType({
        gameType: event.target.value,
      })
    );
  };

  return (
    <div>
      <ToolbarCategory title="Chose your game">
        <Select value={gameState['gameType']} onChange={handleChange}>
          <MenuItem value={PHASER_GAME_TYPE.FruitDrop}>
            Fruit Drop Game
          </MenuItem>
          <MenuItem value={PHASER_GAME_TYPE.SlotMachine}>Slot Machine</MenuItem>
        </Select>
        <GameBooleanEditor
          name="game.preview"
          label="Preview game in builder?"
        />
      </ToolbarCategory>
      {props.gameType === PHASER_GAME_TYPE.FruitDrop && (
        <DropGameToolbar
          onChange={props.onChange}
          styleProps={props.styleProps}
        />
      )}
      {props.gameType === PHASER_GAME_TYPE.SlotMachine && (
        <SlotMachineToolbar
          onChange={props.onChange}
          styleProps={props.styleProps}
        />
      )}

      {props.gameType && (
        <ToolbarCategory>
          <Button
            data-testid="reset-styles-button"
            onClick={onReset}
            size="small"
          >
            Reset
          </Button>
        </ToolbarCategory>
      )}
    </div>
  );
};

export default GameToolbar;
