import { ITheme } from '@brame/theme';
import { BuilderComponent, CanvasElement } from '../../../reducers/builder';
import { IElementStyleProps } from '../../../types';

export const buildDefaultStylesFromTheme = (
  selectedElement: CanvasElement | null,
  currentTheme: ITheme
) => {
  if (!selectedElement) return;

  if (
    selectedElement.type === BuilderComponent.BUTTON ||
    selectedElement.type === BuilderComponent.PHASER_BUTTON ||
    selectedElement.type === BuilderComponent.TEXT ||
    selectedElement.type === BuilderComponent.PHASER_TEXT ||
    selectedElement.type === BuilderComponent.CONTAINER
  ) {
    let { variant } = selectedElement;
    variant === 'body1' || BuilderComponent.CONTAINER
      ? (variant = 'root')
      : variant;

    let defaultStyles: IElementStyleProps = {};
    const elements = {
      button: 'MuiButton',
      text: 'MuiTypography',
      container: 'MuiContainer',
    };

    const element: string | null =
      selectedElement?.type === BuilderComponent.BUTTON
        ? elements.button
        : selectedElement?.type === BuilderComponent.PHASER_BUTTON
        ? elements.button
        : selectedElement?.type === BuilderComponent.TEXT
        ? elements.text
        : selectedElement?.type === BuilderComponent.PHASER_TEXT
        ? elements.text
        : selectedElement?.type === BuilderComponent.CONTAINER
        ? elements.container
        : null;

    const elementsThemeStyles =
      // @ts-ignore
      currentTheme?.theme?.components[element]?.styleOverrides[variant];

    if (selectedElement?.type === BuilderComponent.CONTAINER) {
      defaultStyles = {
        ...defaultStyles,
        background: {
          ...defaultStyles.background,
          color: elementsThemeStyles?.background,
        },
      };
    }

    // Common values
    // FONT
    defaultStyles = {
      ...defaultStyles,
      font: {
        ...defaultStyles.font,
        fontFamily: elementsThemeStyles?.fontFamily,
      },
    };

    defaultStyles = {
      ...defaultStyles,
      font: {
        ...defaultStyles.font,
        fontWeight: elementsThemeStyles.fontWeight,
      },
    };

    defaultStyles = {
      ...defaultStyles,
      font: {
        ...defaultStyles.font,
        fontSize: elementsThemeStyles.fontSize,
      },
    };

    defaultStyles = {
      ...defaultStyles,
      font: {
        ...defaultStyles.font,
        fontColor: elementsThemeStyles.color,
      },
    };

    defaultStyles = {
      ...defaultStyles,
      font: {
        ...defaultStyles.font,
        textTransform: elementsThemeStyles.textTransform,
      },
    };

    // BACKGROUND COLOR
    defaultStyles.backgroundColor = elementsThemeStyles.background;

    if (
      selectedElement.type === BuilderComponent.BUTTON ||
      selectedElement.type === BuilderComponent.PHASER_BUTTON
    ) {
      // Button editor's props model except common values and spacing and size

      //BORDER
      defaultStyles = {
        ...defaultStyles,
        border: {
          ...defaultStyles.border,
          borderRadius: {
            value: elementsThemeStyles.borderRadius,
            unit: 'px',
          },
        },
      };

      defaultStyles = {
        ...defaultStyles,
        border: {
          ...defaultStyles.border,
          borderWidth: {
            value: elementsThemeStyles.borderWidth,
            unit: 'px',
          },
        },
      };

      defaultStyles = {
        ...defaultStyles,
        border: {
          ...defaultStyles.border,
          borderStyle: elementsThemeStyles.borderStyle,
        },
      };

      defaultStyles = {
        ...defaultStyles,
        border: {
          ...defaultStyles.border,
          borderColor: elementsThemeStyles.borderColor,
        },
      };
    }

    if (selectedElement.type === BuilderComponent.TEXT) {
      // Text editor's props model except common values and spacing

      //TEXT ALIGN
      defaultStyles.textAlign = elementsThemeStyles.textAlign;
    }

    return defaultStyles;
  }
};
