import { ButtonVariants, IButtonStyleProps } from '../../types';
import { ButtonActionType, ButtonElement } from '../../reducers/builder';

export const buttonStylePropsMock: IButtonStyleProps = {
  size: {
    width: { value: 100, unit: '%' },
    height: { value: 110, unit: 'px' },
  },
  backgroundColor: '#ffffff',
  variant: ButtonVariants.Contained,
  font: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: 16,
    textTransform: 'none',
    fontColor: '#fff',
  },
  border: {
    borderStyle: 'solid',
    borderWidth: {
      value: 1,
      unit: 'px',
    },
    borderRadius: {
      value: 5,
      unit: 'px',
    },
    borderColor: '#2d3b13',
  },
  spacing: {
    margin: {
      top: { value: 1, unit: 'px' },
      right: { value: 1, unit: 'px' },
      bottom: { value: 1, unit: 'px' },
      left: { value: 1, unit: 'px' },
    },
    padding: {
      top: { value: 1, unit: 'px' },
      right: { value: 1, unit: 'px' },
      bottom: { value: 1, unit: 'px' },
      left: { value: 1, unit: 'px' },
    },
  },
  hover: {},
};

export const buttonElementPropsMock: Partial<ButtonElement> = {
  value: 'Test button',
  action: {
    type: ButtonActionType.CHANGE_PAGE,
    target: 'NEXT',
  },
};

export const fallbackProps = {
  value: 'Button',
  size: {
    width: { value: 150, unit: 'px' },
    height: { value: 50, unit: 'px' },
  },
  backgroundColor: '#2DB2A6',
  color: 'primary',
  font: {
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: 400,
    fontColor: '#000000',
    textTransform: 'none',
  },
  border: {
    borderWidth: { value: 0, unit: 'px' },
    borderRadius: { value: 0, unit: 'px' },
    borderColor: '#2DB2A6',
    borderStyle: 'solid',
  },
  spacing: {
    margin: {
      top: { value: 10, unit: 'px' },
      right: { value: 10, unit: 'px' },
      bottom: { value: 10, unit: 'px' },
      left: { value: 10, unit: 'px' },
    },
    padding: {
      top: { value: 6, unit: 'px' },
      right: { value: 16, unit: 'px' },
      bottom: { value: 6, unit: 'px' },
      left: { value: 16, unit: 'px' },
    },
  },
};
