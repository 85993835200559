import { defaultThemes, ITheme } from '@brame/theme';
import {
  FormFieldType,
  IFormField,
} from '@brame/components/src/components/Form';
import { FieldShape } from '../../types';
import {
  BuilderComponent,
  ButtonActionType,
  IThemesState,
  PageType,
} from './types';
import { ElementsState } from './builderSlice';

export const defaultElementStyles = {
  rootContainer: {
    size: {
      width: { value: 100, unit: '%' },
    },
    internalAlignment: {
      textAlign: 'left',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
    },
    background: {
      color: '#ffffff',
      image: '',
      videoUrl: '',
    },
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    overFlowY: 'scroll',
  },
  container: {
    size: {
      width: { value: 100, unit: '%' },
      height: { value: 150, unit: 'px' },
    },
    spacing: {
      margin: {
        top: { value: 0, unit: 'px' },
        right: { value: 0, unit: 'px' },
        bottom: { value: 0, unit: 'px' },
        left: { value: 0, unit: 'px' },
      },
      padding: {
        top: { value: 10, unit: 'px' },
        right: { value: 10, unit: 'px' },
        bottom: { value: 10, unit: 'px' },
        left: { value: 10, unit: 'px' },
      },
    },
    background: {
      image: '',
      videoUrl: '',
    },
    internalAlignment: {
      textAlign: 'left',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
    },
    display: 'flex',
    flexWrap: 'nowrap',
    position: 'relative',
  },
  form: {
    size: {
      width: { value: 300, unit: 'px' },
      height: { value: 300, unit: 'auto' },
    },
    grid: {
      gridTemplateColumns: '1fr',
    },
    input: {
      size: {
        width: { value: 100, unit: '%' },
        height: { value: 100, unit: '%' },
      },
      background: '#FFFFFF00',
      variant: FieldShape.Outlined,
      border: {
        borderRadius: { value: 0, unit: 'px' },
      },
      boxShadow: null,
    },
    label: {
      input: 'left',
      checkbox: 'end',
    },
  },
  image: {
    size: {
      width: { value: 80, unit: '%' },
      height: { value: 146, unit: 'auto' },
    },
    alignSelf: 'center',
    objectFit: 'contain',
    border: {
      borderRadius: { value: 0, unit: 'px' },
    },
    opacity: 100,
    rotate: 0,
    flip: {
      horizontal: false,
      vertical: false,
    },
  },
  phasergame: {
    size: {
      width: { value: 50, unit: '%' },
      height: { value: 50, unit: '%' },
    },
  },
  video: {
    size: {
      width: { value: 80, unit: '%' },
      height: { value: 300, unit: 'auto' },
    },
    spacing: {
      margin: {
        top: { value: 0, unit: 'px' },
        right: { value: 0, unit: 'px' },
        bottom: { value: 0, unit: 'px' },
        left: { value: 0, unit: 'px' },
      },
    },
  },
  button: {
    spacing: {
      margin: {
        top: { value: 10, unit: 'px' },
        right: { value: 10, unit: 'px' },
        bottom: { value: 10, unit: 'px' },
        left: { value: 10, unit: 'px' },
      },
      padding: {
        top: { value: 6, unit: 'px' },
        right: { value: 16, unit: 'px' },
        bottom: { value: 6, unit: 'px' },
        left: { value: 16, unit: 'px' },
      },
    },
    size: {
      width: { value: 20, unit: 'auto' },
      height: { value: 20, unit: 'auto' },
    },
  },
  phaserbutton: {
    spacing: {
      margin: {
        top: { value: 10, unit: 'px' },
        right: { value: 10, unit: 'px' },
        bottom: { value: 10, unit: 'px' },
        left: { value: 10, unit: 'px' },
      },
      padding: {
        top: { value: 6, unit: 'px' },
        right: { value: 16, unit: 'px' },
        bottom: { value: 6, unit: 'px' },
        left: { value: 16, unit: 'px' },
      },
    },
    size: {
      width: { value: 20, unit: 'auto' },
      height: { value: 20, unit: 'auto' },
    },
  },
  text: {
    spacing: {
      margin: {
        top: { value: 0, unit: 'px' },
        right: { value: 0, unit: 'px' },
        bottom: { value: 0, unit: 'px' },
        left: { value: 0, unit: 'px' },
      },
      padding: {
        top: { value: 5, unit: 'px' },
        right: { value: 5, unit: 'px' },
        bottom: { value: 5, unit: 'px' },
        left: { value: 5, unit: 'px' },
      },
    },
    size: {
      width: { value: 80, unit: 'auto' },
      height: { value: 80, unit: 'auto' },
    },
    decoration: [],
    position: 'relative',
    display: 'inline-block',
  },
  phasertext: {
    spacing: {
      margin: {
        top: { value: 0, unit: 'px' },
        right: { value: 0, unit: 'px' },
        bottom: { value: 0, unit: 'px' },
        left: { value: 0, unit: 'px' },
      },
      padding: {
        top: { value: 5, unit: 'px' },
        right: { value: 5, unit: 'px' },
        bottom: { value: 5, unit: 'px' },
        left: { value: 5, unit: 'px' },
      },
    },
    size: {
      width: { value: 80, unit: 'auto' },
      height: { value: 80, unit: 'auto' },
    },
    decoration: [],
    position: 'relative',
  },
  h1: {
    spacing: {
      margin: {
        top: { value: 0, unit: 'px' },
        right: { value: 0, unit: 'px' },
        bottom: { value: 33, unit: 'px' },
        left: { value: 0, unit: 'px' },
      },
      padding: {
        top: { value: 5, unit: 'px' },
        right: { value: 5, unit: 'px' },
        bottom: { value: 5, unit: 'px' },
        left: { value: 5, unit: 'px' },
      },
    },
    size: {
      width: { value: 80, unit: 'auto' },
      height: { value: 80, unit: 'auto' },
    },
    decoration: [],
    position: 'relative',
  },
  h2: {
    spacing: {
      margin: {
        top: { value: 0, unit: 'px' },
        right: { value: 0, unit: 'px' },
        bottom: { value: 21, unit: 'px' },
        left: { value: 0, unit: 'px' },
      },
      padding: {
        top: { value: 5, unit: 'px' },
        right: { value: 5, unit: 'px' },
        bottom: { value: 5, unit: 'px' },
        left: { value: 5, unit: 'px' },
      },
    },
    size: {
      width: { value: 80, unit: 'auto' },
      height: { value: 80, unit: 'auto' },
    },
    decoration: [],
    position: 'relative',
  },
  h3: {
    spacing: {
      margin: {
        top: { value: 0, unit: 'px' },
        right: { value: 0, unit: 'px' },
        bottom: { value: 16, unit: 'px' },
        left: { value: 0, unit: 'px' },
      },
      padding: {
        top: { value: 5, unit: 'px' },
        right: { value: 5, unit: 'px' },
        bottom: { value: 5, unit: 'px' },
        left: { value: 5, unit: 'px' },
      },
    },
    size: {
      width: { value: 80, unit: 'auto' },
      height: { value: 80, unit: 'auto' },
    },
    decoration: [],
    position: 'relative',
  },
};

export const initialPage = {
  id: 'page-1',
  name: 'some page',
  elementRoot: '1',
  pageType: PageType.Basic,
};

export const initialState: ElementsState = {
  elements: {
    ids: ['1', '2', '3'],
    entities: {
      '1': {
        id: '1',
        type: BuilderComponent.ROOT_CONTAINER,
        styles: { defaults: defaultElementStyles.container, overrides: {} },
        childrenIds: ['2', '3'],
      },
      '2': {
        id: '2',
        parentId: '1',
        type: BuilderComponent.CONTAINER,
        childrenIds: [],
        styles: { defaults: defaultElementStyles.container, overrides: {} },
      },
      '3': {
        id: '3',
        parentId: '1',
        type: BuilderComponent.BUTTON,
        styles: { defaults: defaultElementStyles.button, overrides: {} },
        action: {
          type: ButtonActionType.CHANGE_PAGE,
          target: 'NEXT',
        },
        value: 'Submit',
        variant: 'contained',
        color: 'primary',
      },
    },
  },
  additionalPages: {
    ids: [],
    entities: {},
  },
  pages: {
    ids: [initialPage.id],
    entities: {
      [initialPage.id]: initialPage,
    },
  },
  selectedPageId: initialPage.id,
  selectedElementId: null,
  previewMode: false,
  readiness: 'loading',
  selectedTheme: 'theme1',
};

const initialTheme: ITheme = defaultThemes.theme1;

export const initialThemeState: IThemesState = {
  currentTheme: initialTheme,
  chosenTheme: initialTheme,
  themeCollection: defaultThemes,
};

export const editedTheme: ITheme = defaultThemes.theme1;

export const defaultTermsAndConditionsFields: IFormField[] = [
  {
    label: 'Terms & conditions',
    name: 'termsAndConditions',
    value: false,
    action: {
      type: ButtonActionType.CHANGE_PAGE,
      target: '',
    },
    validation: {
      required: true,
    },
    type: 'checkbox' as FormFieldType,
    isTermsAndConditions: true,
  },
  {
    label: 'Privacy policy',
    name: 'privacyPolicy',
    value: false,
    action: {
      type: ButtonActionType.LINK,
      target: '',
      openNewTab: true,
    },
    validation: {
      required: true,
    },
    type: 'checkbox' as FormFieldType,
    isTermsAndConditions: true,
  },
];

export const initialTemplatesState = {
  currentTemplate: null,
  templateCollection: {},
};

export const defaultHeightOverride = {
  styles: { overrides: { size: { height: { value: 150, unit: 'px' } } } },
};
export const autoHeightOverride = {
  styles: { overrides: { size: { height: { value: 'auto', unit: 'auto' } } } },
};
