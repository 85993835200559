/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { TextareaAutosize, Typography } from '@mui/material';
import { fallbackProps } from '../TextEditor/mock';
import { useStyles } from './styles';

interface IProps {
  value: string;
  label?: string;
  onChange: (value: string) => void;
}

const TextArea: FC<IProps> = ({ value, onChange, label }) => {
  const styles = useStyles();
  return (
    <div data-testid="text-area">
      {label && <Typography css={styles.label}>{label}</Typography>}
      <TextareaAutosize
        value={value}
        css={styles.textarea}
        minRows={5}
        maxRows={15}
        data-testid="text-area-input"
        onChange={(e) => onChange(e.target.value)}
        onBlur={(e) => onChange(e.target.value || fallbackProps.value)}
      />
    </div>
  );
};

export default TextArea;
