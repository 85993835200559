/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { Plus, TrashSimple } from 'phosphor-react';
import {
  Box,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { useFormEditorStyles } from '../../styles';

export interface IProps {
  props: any;
}

const EditSelect: FC<IProps> = ({ props }) => {
  const formStyles = useFormEditorStyles();

  const onAddOption = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    props.setFieldValue('options', [
      ...props.values.options,
      props.values.newOption,
    ]);
    props.setFieldValue('newOption', '');
  };

  const onRemoveOption = (index: number): void => {
    const newOptions = [...props.values.options];
    newOptions.splice(index, 1);
    props.setFieldValue('options', newOptions);
  };

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          paddingTop: '8px',
        }}
      >
        <FormControl fullWidth>
          <Typography css={formStyles.label}>Options to choose from</Typography>
          <TextField
            id="newOption"
            name="newOption"
            value={props.values.newOption}
            onChange={props.handleChange}
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>
        <button css={formStyles.actionButton} onClick={onAddOption}>
          <Plus size={24} weight="light" />
        </button>
      </Box>
      {props.values?.options.length > 0 ? (
        <List dense={false}>
          {(props.values.options as string[]).map((option, index) => (
            <ListItem
              disablePadding
              key={index}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete option"
                  onClick={() => onRemoveOption(index)}
                >
                  <TrashSimple size={16} />
                </IconButton>
              }
            >
              <ListItemButton css={formStyles.orderButton}>
                {index + 1}
              </ListItemButton>
              <ListItemText primary={option} secondary={null} />
            </ListItem>
          ))}
        </List>
      ) : null}
    </div>
  );
};

export default EditSelect;
