/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { FadersHorizontal } from 'phosphor-react';
import { EntityId } from '@reduxjs/toolkit';
import { Drawer, Tab, Tabs, useTheme } from '@mui/material';
import { Breadcrumbs } from '../Breadcrumbs';
import {
  getSelectedElement,
  resetStyles,
  updateElement,
  updateStyles,
} from '../../reducers/builder/builderSlice';
import { useAppDispatch, useAppSelector } from '../../reducers/app/hooks';
import { IStyleProps } from '../../reducers/builder';
import { IElementProps } from '../../types';
import { TabPanel, useTabs } from '../TabPanel';
import { getSelectedBreakpoint } from '../../reducers/shared/sharedSlice';
import { useStyles } from './styles';
import { Editors } from './Editors';

const isElementProps = (
  props: IStyleProps | IElementProps
): props is IElementProps => {
  return (
    'valueID' in props ||
    'suffix' in props ||
    'prefix' in props ||
    'functionID' in props ||
    ('value' in props &&
      'variant' in props &&
      'src' in props &&
      'asset' in props &&
      'controls' in props &&
      'autoplay' in props &&
      'color' in props &&
      'themedImage' in props &&
      'themedVideo' in props &&
      'fields' in props)
  );
};

const removeEmptyProps = (
  elementProps: IElementProps
): { [key: string]: any } => {
  const props = elementProps as { [key: string]: any };

  Object.keys(props).forEach((key: string) => {
    if (props[key] === undefined) {
      delete props[key];
    }
  });

  return props;
};

const RightPanel: FC = () => {
  const { tabIndex, handleTabChange } = useTabs(0);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const selectedElement = useSelector(getSelectedElement);
  const theme = useTheme();
  const activeBreakpoint = useAppSelector(getSelectedBreakpoint);

  const handleElementChange = (data: IStyleProps | IElementProps) => {
    if (isElementProps(data)) {
      dispatch(
        updateElement({
          id: selectedElement?.id,
          changes: removeEmptyProps(data),
        })
      );
    } else {
      const updateObject: {
        id?: EntityId;
        styles: IStyleProps;
        activeBreakpoint?: string;
      } = { id: selectedElement?.id, styles: data };
      if (!activeBreakpoint.default) {
        updateObject.activeBreakpoint = activeBreakpoint.breakpoint.toString();
      }
      dispatch(updateStyles(updateObject));
    }
  };

  const handleStylesReset = () => {
    dispatch(resetStyles({ id: selectedElement?.id }));
  };

  return (
    <aside css={styles.rightPanel} data-testid="right-panel">
      <div css={styles.switch} onClick={() => setIsOpen((prev) => !prev)}>
        <FadersHorizontal size={25} />
      </div>
      <Drawer
        anchor="right"
        open={isOpen}
        hideBackdrop
        variant="persistent"
        transitionDuration={0}
        PaperProps={{ sx: { border: 'none' } }}
        sx={{
          width: isOpen ? 344 : 0,
          flexShrink: 0,
          height: 'calc(100vh - 120px)',

          '& .MuiDrawer-paper': {
            width: isOpen ? 344 : 0,
            paddingTop: '120px',
          },
        }}
      >
        <div>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            indicatorColor="secondary"
            sx={{
              minHeight: '35px',
              height: '35px',
              '& .MuiTabs-indicator': {
                height: '1px',
              },
            }}
            centered
          >
            <Tab
              disableRipple
              label="Properties"
              sx={{
                color: theme.palette.text.primary,
                padding: '6px 0px',
                '&.Mui-selected': {
                  color: theme.palette.text.primary,
                },
              }}
            />
            <Tab
              disableRipple
              label="Layers"
              sx={{
                color: theme.palette.text.primary,
                padding: '6px 0px',
                '&.Mui-selected': {
                  color: theme.palette.text.primary,
                },
              }}
            />
          </Tabs>
        </div>
        <TabPanel value={tabIndex} index={0}>
          <Editors
            selectedElement={selectedElement}
            onChange={handleElementChange}
            onReset={handleStylesReset}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <div css={styles.breadcrumbsBox}>
            <Breadcrumbs />
          </div>
        </TabPanel>
      </Drawer>
    </aside>
  );
};

export default RightPanel;
