import { css } from '@emotion/react';
import { Breakpoint } from '../../reducers/shared/types';

export const useStyles = () => ({
  layoutWrapper: css`
    margin: 0 auto;
    height: 100%;
    overflow: hidden;
    width: 100%;
  `,
  previewPage: css`
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  `,
  resizableContainer: css`
    display: flex;
    justify-content: center;
    align-items: start;
    padding: 24px;
    flex-grow: 1;
    box-sizing: border-box;
  `,
  resizableWrapper: css`
    position: relative;
    background-color: #4d4d4d;
    padding: 20px 0;
    height: 100%;
    box-sizing: border-box;
  `,
  resizable: css`
    position: relative;
    align-self: center;
    transition: width 0.1s;
    box-sizing: content-box;
  `,
  resizableHandle: css`
    width: 20px;
    background-color: #4d4d4d;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #898989 !important;
    }
  `,
});
