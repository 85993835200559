import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';

export const useStyles = () => ({
  widget: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  buttonGroup: css`
    gap: 10px;
    flex-wrap: wrap;
  `,
  button: css`
    border: none;
    border-radius: ${brameTheme.shape.borderRadius}px !important;
    width: 36px;
    height: 36px;
    padding: 5px;

    &.Mui-selected {
      background: ${brameTheme.palette.primary.main};
      * {
        border-color: #ffffff;
      }

      &:hover {
        background: ${brameTheme.palette.primary.main};
        * {
          border-color: #ffffff;
        }
      }
    }
  `,
  preview: css`
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: ${brameTheme.palette.text.primary};
  `,
});
