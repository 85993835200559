import { css } from '@emotion/react';
import { Theme } from '@mui/material';
import { brameTheme } from '@brame/theme';

export const useStyles = (theme: Theme) => ({
  previewFooter: css`
    display: flex;
    justify-content: center;
    background-color: ${theme.palette.grey['100']};
  `,

  tabsContainer: css`
    width: 100%;
    height: 100px;
    background: #ffffff;
    border-top: 3px solid ${brameTheme.palette.primary.main};
    box-shadow: 0px -10px 20px rgba(174, 174, 174, 0.3);
    gap: 20px;
    outline: none;
    position: relative;
  `,

  tabs: css`
    & .MuiTabs-flexContainer {
      min-height: 31px;
      height: 31px;
      gap: 4px;
    }
    & .MuiTabs-indicator {
      height: 0;
    }

    position: absolute;
    top: -34px;
    left: 20px;
  `,

  tab: css`
    &.MuiTab-root {
      height: 31px;
      min-height: 31px;
    }

    font-size: 16px;
    text-transform: none;
    border: none;
    background: #ffffff;
    color: ${brameTheme.palette.text.primary};
    border-radius: ${brameTheme.shape.borderRadius}px
      ${brameTheme.shape.borderRadius}px 0 0;
    padding: 0 9px;
    cursor: pointer;

    &.Mui-selected {
      background: ${brameTheme.palette.primary.main};
      color: ${brameTheme.palette.primary.contrastText};
    }
  `,
});
