import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';

export const useStyles = () => ({
  toolbar: css`
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    background: #ffffff;
    box-shadow: 0px 10px 20px #aeaeae30;
    padding: 0 15px;
  `,
  centered: css`
    display: flex;
    align-content: center;
  `,
  buttonsBlock: css`
    gap: 20px;
  `,
  toolbarButton: css`
    color: ${brameTheme.palette.text.primary};
    text-transform: capitalize;
  `,
  publishButton: css`
    min-width: 130px;
  `,
});
