import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { useState } from 'react';
import { uniqueId } from 'lodash';
import {
  Button,
  Box,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Typography,
  TextField,
} from '@mui/material';
import CampaignService from '../../api-client/campaigns.service';
import { campaignMetaDataIProps } from '../../reducers/auth/types';
import { selectAuth } from '../../reducers/auth/authSlice';
import { useAppSelector } from '../../reducers/app/hooks';
import threePageTemplate from '../../templates/threePageTemplate';
import { CanvasDimensions } from '../CanvasDimensions';
import { ICanvasDimensions } from '../../reducers/shared/types';
import { defaultCanvasFormats } from '../CanvasFormats/fixtures';
import useTemplate from '../../templates/useTemplate';
import { useTemplatesData } from '../../hooks/useTemplatesData';
import { normaliseTemplateData } from '../../reducers/builder/utils';
import { ElementsState } from '../../reducers/builder/builderSlice';
import wizFailure from './wiz-failure.svg';

interface ICanvasType {
  name: string;
  responsiveness: boolean;
}

const ErrorCampaignNotFound: React.FC = () => {
  const [newCampaignName, setNewCampaignName] = React.useState('');
  const [availableCampaigns, setAvailableCampaigns] = React.useState([]);
  const [selectedCampaign, setSelectedCampaign] = React.useState('');
  const { addTemplate, getTemplatesData } = useTemplatesData();
  const [availableTemplates, setAvailableTemplates] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState(
    // @ts-ignore
    availableTemplates['1'] || ''
  );

  const handleAddTemplate = async () => {
    await addTemplate({
      name: `some new template number ${uniqueId()}`,
      template: threePageTemplate as ElementsState,
    });
    await loadTemplates();
  };

  const canvasTypes: ICanvasType[] = [
    { name: 'responsive', responsiveness: true },
    { name: 'fixed', responsiveness: false },
  ];
  const [canvasDimensions, setCanvasDimensions] = useState<ICanvasDimensions>({
    width: 600,
    height: 300,
  });
  const [canvasType, setCanvasType] = useState<string>('responsive');
  const authState = useAppSelector(selectAuth);
  const navigate = useNavigate();

  const loadTemplates = async () => {
    const templateData = await getTemplatesData();
    const templates = normaliseTemplateData(templateData);
    setAvailableTemplates(templates);
  };

  const loadCampaigns = async () => {
    const campaigns = await CampaignService.getAllCampaignsByCompany(
      authState.parsedToken.company_id
    );
    let filterCampaigns = campaigns.data.filter(
      (campaign: campaignMetaDataIProps) => campaign.status !== 'DELETED'
    );
    filterCampaigns = filterCampaigns.sort((a: any, b: any) =>
      a.createdDate > b.createdDate ? -1 : b.createdDate > a.createdDate ? 1 : 0
    );
    campaigns.message === 'Campaigns successfully fetched'
      ? setAvailableCampaigns(filterCampaigns)
      : null;
  };

  const responsiveness = canvasTypes.find(
    (item) => item.name === canvasType
  )?.responsiveness;

  const { prepareTemplate } = useTemplate({
    fixed: !responsiveness,
    dimensions: canvasDimensions,
  });

  React.useEffect(() => {
    loadCampaigns();
    loadTemplates();
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedCampaign(event.target.value);
  };

  const handleTemplateChange = (event: SelectChangeEvent) => {
    // @ts-ignore
    setSelectedTemplate(availableTemplates[event.target.value]);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        flexDirection: 'column',
      }}
    >
      <img src={wizFailure} alt="wizzard failure" />
      <Typography sx={{ margin: 3 }}>Campaign not found</Typography>
      <Typography variant="h2" style={{ paddingBottom: 10 }}>
        Create a new campaign
      </Typography>
      <Typography variant="h4">Select a template</Typography>
      {availableTemplates && (
        <FormControl sx={{ width: '20%', margin: 1 }}>
          <Select
            labelId="simple-select-label"
            id="simple-select-id"
            // @ts-ignore
            value={selectedTemplate?.id || ''}
            onChange={handleTemplateChange}
          >
            {Object.values(availableTemplates).map((template: any) => (
              <MenuItem
                key={template.id}
                value={`${template.id}`}
              >{`${template.name}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <Typography variant="h4">Select canvas type</Typography>
      <FormControl sx={{ width: '20%', margin: 1 }}>
        <Select
          labelId="simple-select-label"
          id="simple-select-id"
          value={canvasType}
          onChange={(e) => setCanvasType(e.target.value)}
        >
          {canvasTypes.map((type: ICanvasType) => (
            <MenuItem key={type.name} value={type.name}>
              {type.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {canvasType === 'fixed' && (
        <FormControl sx={{ width: '20%', margin: 1 }}>
          <CanvasDimensions
            dimensions={canvasDimensions}
            onChange={setCanvasDimensions}
          />
        </FormControl>
      )}

      <Typography variant="h4">Choose a name</Typography>
      <TextField
        id="text-field-new-campaign"
        value={newCampaignName}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setNewCampaignName(event.target.value);
        }}
      />
      <Button
        variant="outlined"
        sx={{ margin: 2 }}
        onClick={async () => {
          const res = await CampaignService.createCampaignByCompany(
            authState.parsedToken.company_id,
            newCampaignName
          );
          if (res.message === 'Successfully saved campaign details') {
            await CampaignService.postInitialCampaignDataByCompanyAndCampaign(
              authState.parsedToken.company_id,
              res.data.id,
              {
                ...prepareTemplate(selectedTemplate).template,
                canvasResponsiveness: responsiveness,
                canvasDimensions,
                dimensions: defaultCanvasFormats,
              }
            );
          }
          await loadCampaigns();
        }}
      >
        Create campaign
      </Button>
      <Typography variant="h2" style={{ paddingBottom: 10, marginTop: 30 }}>
        Open existing campaign
      </Typography>
      <>
        <FormControl sx={{ width: '20%', margin: 1 }}>
          <Select
            labelId="simple-select-label"
            id="simple-select-id"
            value={selectedCampaign}
            onChange={handleChange}
          >
            {availableCampaigns.map((campaign: campaignMetaDataIProps) => (
              <MenuItem
                key={campaign.id}
                value={`${campaign.name} / ${campaign.id}`}
              >{`${campaign.name} / ${campaign.id}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
      <Button
        variant="outlined"
        sx={{ margin: 2 }}
        onClick={() => {
          navigate(`/${selectedCampaign.split('/ ')[1]}`);
        }}
      >
        Open selected campaign
      </Button>
      <Button
        variant="outlined"
        sx={{ margin: 2 }}
        onClick={() => {
          navigate(`/wizzard`);
        }}
      >
        Go to Wizzard
      </Button>
      {/*For testing purpose*/}
      <Button onClick={() => handleAddTemplate()}>Add template</Button>
    </Box>
  );
};

export default ErrorCampaignNotFound;
