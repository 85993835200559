import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../reducers/app/hooks';
import { selectAuth } from '../../../reducers/auth/authSlice';
import AssetsService from '../../../api-client/assets.service';
import { fetchPersonalAssets } from '../../../reducers/builder/asyncThunks';

const useFileUploader = () => {
  const dispatch = useAppDispatch();
  const companyId = useAppSelector(selectAuth).parsedToken.company_id;
  const [fileIsLoading, setFileIsLoading] = useState<boolean>(false);

  const uploadFile = async (file: File, base64: any) => {
    if (file && companyId) {
      setFileIsLoading(true);
      await AssetsService.addPersonalAsset(file, base64, companyId).finally(
        () => {
          setFileIsLoading(false);
        }
      );
      dispatch(fetchPersonalAssets(companyId));
    }
  };

  return { uploadFile, fileIsLoading };
};

export default useFileUploader;
