import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';

export const useStyles = () => ({
  dialog: css`
    width: 760px;
    max-width: 100%;
  `,
  dialogTitle: css`
    height: 40px;
    background: ${brameTheme.palette.primary.main};
    font-size: 16px;
    font-weight: 500;
    color: ${brameTheme.palette.primary.contrastText};
    padding: 8px 20px;
    text-transform: uppercase;
  `,
  dialogTitleIcon: css`
    color: ${brameTheme.palette.primary.contrastText};
    position: absolute;
    right: 10px;
    top: 0;
  `,
  dialogContent: css`
    font-size: 15px;
  `,
  dialogButtons: css`
    padding: 0 24px 24px;

    & > :not(:first-of-type) {
      margin-left: 16px;
    }
  `,
});
