/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { Menu, MenuItem, TextField, Typography } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { sizeProps, sizePropsNoAuto, UnitValueType } from '../../types';
import { useStyles } from './styles';
import useSizeProps from './useSizeProps';

const SizeInput: FC<
  {
    title?: string;
    useAuto?: boolean;
    sizeValue: UnitValueType;
    onChange: (value: UnitValueType) => void;
  } & Omit<TextFieldProps, 'onChange'>
> = ({ onChange, sizeValue, title, useAuto, ...props }) => {
  const {
    unit,
    value,
    anchorEl,
    isAuto,
    open,
    openPropMenu,
    selectPropType,
    handleChange,
  } = useSizeProps(sizeValue, onChange);

  const styles = useStyles();
  const selectedSizeProps = useAuto ? sizeProps : sizePropsNoAuto;

  return (
    <div>
      {title && <Typography css={styles.title}>{title}</Typography>}
      <TextField
        type={isAuto ? 'text' : 'number'}
        size="small"
        value={isAuto ? value : Math.round(value as number)}
        fullWidth
        data-testid="size-input"
        disabled={isAuto}
        InputProps={{
          inputProps: { min: 0 },
          sx: { paddingRight: '10px' },
          endAdornment: (
            <div>
              <div
                onClick={openPropMenu}
                data-testid="adornment"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                id={`menu-${title}`}
                aria-controls={open ? 'basic-menu' : undefined}
                css={styles.unit}
              >
                {unit}
              </div>

              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={selectPropType}
                MenuListProps={{
                  'aria-labelledby': `menu-${title}`,
                }}
              >
                {selectedSizeProps.map((i) => (
                  <MenuItem key={i} onClick={() => selectPropType(i)}>
                    {i}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          ),
        }}
        onChange={(e) => handleChange(e.target.value)}
        {...props}
      />
    </div>
  );
};

export default SizeInput;
