import { css } from '@emotion/react';

export const useStyles = () => ({
  wrapperButton: css`
    cursor: grab;
    width: 92px;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 12px;
    background-color: #c4e2ac;
    color: #00000060;
    padding: 12px;

    & .MuiButton-startIcon {
      margin: 0;
      padding: 0;
    }

    &:hover {
      background-color: #88c559;
      color: #ffffff;
    }
    &:active {
      cursor: grabbing;
    }
  `,
  details: css`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
  `,
  accordion: css`
    &.Mui-expanded {
      margin: 25px 0;
    }
  `,
  library: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  `,
  disclaimer: css`
    padding: 15px 15px 45px 15px;
  `,
});
