import { ThemeOptions } from '@mui/material/styles';
import { IAsset } from '@brame/builder/src/types';

export interface ITheme {
  name: string;
  id: string;
  type?: IThemeType;
  assets: IThemeAssets;
  theme: ThemeOptions;
}

export interface IThemeCollection {
  [name: string]: ITheme;
}

export interface IThemeAssets {
  defaultCreatives: IThemeCreatives;
}

interface IThemeCreatives {
  videos: IThemeMediaCollection;
  images: IThemeMediaCollection;
}

export interface IThemeMediaCollection {
  [key: string]: IThemeMedia;
}

export interface IThemeMedia {
  name: string;
  type: IThemeMediaType;
  url: string;
}

export interface IAssetChange {
  name: string;
  type: IThemeMediaType;
  asset: IAsset;
}

export const ThemeMediaType = [
  'image',
  'video',
  'logo',
  'background',
  'game',
] as const;
export type IThemeMediaType = typeof ThemeMediaType[number];

export const ThemeDialogType = ['new', 'update', 'delete'] as const;
export type IThemeDialog = typeof ThemeDialogType[number];
const ThemeType = ['default', 'custom'] as const;
export type IThemeType = typeof ThemeType[number];

export enum AssetCollectionType {
  VIDEOS = 'videos',
  IMAGES = 'images',
  GAME = 'assets',
}

export enum AssetCategoryType {
  DEFAULT_CREATIVES = 'defaultCreatives',
  GAME_ASSETS = 'gameAssets',
}

const assetCategories = ['media', 'game'];
export type IAssetCategory = typeof assetCategories[number];
