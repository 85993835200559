import { css } from '@emotion/react';

export const useStyles = () => ({
  title: css`
    text-transform: capitalize;
    margin-bottom: 8px;
  `,
  button: css`
    font-size: 10px;
  `,
});
