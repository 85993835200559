/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC } from 'react';
import {
  GameController,
  TextAa,
  Camera,
  ClipboardText,
  TextT,
  Rectangle,
  FilmStrip,
  Layout,
  Image,
  IconWeight,
  CheckSquareOffset,
  TextHOne,
  TextHTwo,
  TextHThree,
} from 'phosphor-react';
import { Typography } from '@mui/material';
import {
  BuilderComponent,
  IElementsLibrary,
  TextVariant,
  ButtonVariant,
} from '../../reducers/builder';
import { ReactComponent as Capsule } from '../../assets/capsuleIcon.svg';
import { LibrarySection } from './LibrarySection';
import { useStyles } from './styles';

const tree: IElementsLibrary = [
  {
    title: 'Containers',
    icon: (weight: IconWeight) => <Layout weight={weight} size={35} />,
    elements: [
      {
        title: 'Container',
        icon: <Rectangle size={32} />,
        type: BuilderComponent.CONTAINER,
      },
    ],
  },
  {
    title: 'Text',
    icon: (weight: IconWeight) => <TextAa weight={weight} size={35} />,
    elements: [
      {
        title: 'Heading 1',
        icon: <TextHOne size={32} />,
        type: BuilderComponent.TEXT,
        option: {
          variant: TextVariant.H1,
        },
      },
      {
        title: 'Heading 2',
        icon: <TextHTwo size={32} />,
        type: BuilderComponent.TEXT,
        option: {
          variant: TextVariant.H2,
        },
      },
      {
        title: 'Heading 3',
        icon: <TextHThree size={32} />,
        type: BuilderComponent.TEXT,
        option: {
          variant: TextVariant.H3,
        },
      },
      {
        title: 'Body',
        icon: <TextAa size={32} />,
        type: BuilderComponent.TEXT,
        option: {
          variant: TextVariant.BODY,
        },
      },
    ],
  },
  {
    title: 'Buttons',
    icon: (weight: IconWeight) => <Layout weight={weight} size={35} />,
    elements: [
      {
        title: 'Rectangle',
        icon: <Rectangle size={32} />,
        type: BuilderComponent.BUTTON,
        option: {
          variant: ButtonVariant.Contained,
        },
      },
      {
        title: 'Capsule',
        icon: <Capsule height="32" />,
        type: BuilderComponent.BUTTON,
        option: {
          variant: ButtonVariant.Outlined,
        },
      },
      {
        title: 'Textual',
        icon: <TextT size={32} />,
        type: BuilderComponent.BUTTON,
        option: {
          variant: ButtonVariant.Text,
        },
      },
    ],
  },
  {
    title: 'Media',
    icon: (weight: IconWeight) => <Camera weight={weight} size={35} />,
    elements: [
      {
        title: 'Image',
        icon: <Image size={32} />,
        type: BuilderComponent.IMAGE,
      },
      {
        title: 'Video',
        icon: <FilmStrip size={32} />,
        type: BuilderComponent.VIDEO,
      },
    ],
  },
  {
    title: 'Forms',
    icon: (weight: IconWeight) => <ClipboardText weight={weight} size={35} />,
    elements: [
      {
        title: 'Registration form',
        icon: <ClipboardText size={32} />,
        type: BuilderComponent.FORM,
      },
      {
        title: 'Terms & Conditions',
        icon: <CheckSquareOffset size={32} />,
        type: BuilderComponent.TERMS_AND_CONDITIONS,
      },
    ],
  },
  {
    title: 'Games',
    icon: (weight: IconWeight) => <GameController weight={weight} size={35} />,
    elements: [
      {
        title: 'Phaser Game',
        icon: <GameController size={32} />,
        type: BuilderComponent.PHASER_GAME,
      },
      {
        title: 'Phaser Text',
        icon: <GameController size={32} />,
        type: BuilderComponent.PHASER_TEXT,
      },
      {
        title: 'Phaser Button',
        icon: <GameController size={32} />,
        type: BuilderComponent.PHASER_BUTTON,
      },
    ],
  },
];

const ComponentsLibrary: FC = () => {
  const styles = useStyles();

  return (
    <div data-testid="components-library" css={styles.library}>
      <div>
        {tree.map((section) => (
          <LibrarySection section={section} key={section.title} />
        ))}
      </div>

      <div css={styles.disclaimer}>
        <Typography>DRAG AND DROP ELEMENT</Typography>
        <Typography sx={{ margin: '10px 0' }}>
          When you add an item, the setting options will appear on the right in
          the
        </Typography>
        <Typography>PROPERTIES MENU</Typography>
      </div>
    </div>
  );
};

export default ComponentsLibrary;
