//@ts-nocheck
import { css } from '@emotion/react';

export const useStyles = (active: boolean) => {
  return {
    card: css`
      border: ${active ? `1px solid #00000099` : `1px solid transparent`};
      box-shadow: ${
        active
          ? `7px 7px 10px rgba(0 0 0 / 25%);`
          : `7px 7px 10px rgba(255, 255, 255, 1);`
      }
      padding: 5px 15px 15px;
      & div {
        border-radius: 5px;
      }
    `,
    titleContainer: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 6px 0 10px;
      transform: ${active ? `scale(1.05)` : `scale(1)`};
      transform-origin: top center;
      transition: all 0.3s ease;
    `,
    titleWrapper: css`
      display: flex;
      justify-content: start;
      align-items: center;
    `,
    title: css`
      color: #00000099;
      padding: 2px 0;
      max-width: 160px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `,
    infoButton: css`
      min-width: 20px;
      color: #00000099;
      padding: 0;
      margin-left: 8px;
      &:hover {
        background-color: transparent;
      }
      span {
        margin: 0;
      }
    `,
    previewButton: css`
      color: #00000099;
      padding: 0;
      min-width: 35px;
      &:hover {
        background-color: transparent;
      }
      &:active {
      }
      span {
        margin: 0;
      }
      a {
        padding: 0;
      }
    `,
    template: css`
      display: flex;
      height: 460px;
      zoom: 0.3;
      box-shadow: ${
        active
          ? `20px 20px 25px rgba(255, 255, 255, 1);`
          : `20px 20px 25px rgba(0 0 0 / 25%);`
      }
      transform: ${active ? `scale(1.05)` : `scale(1)`};
      transform-origin: top center;
      transition: all 0.3s ease;
      cursor: pointer;
    `,
  };
};
