import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../reducers/app/hooks';
import {
  getSelectedElement,
  removeElement,
} from '../../reducers/builder/builderSlice';
import { BuilderComponent } from '../../reducers/builder';

const useDeleteKey = () => {
  const selectedComponent = useAppSelector(getSelectedElement);
  const dispatch = useAppDispatch();
  const onDeletePress = useCallback(
    (event: KeyboardEvent) => {
      if (
        !selectedComponent ||
        selectedComponent.type === BuilderComponent.ROOT_CONTAINER ||
        // @ts-ignore
        document.activeElement?.contentEditable === 'true'
      )
        return;

      if (
        (event.key === 'Delete' || event.key === 'Backspace') &&
        document.activeElement?.tagName !== 'INPUT'
      ) {
        dispatch(removeElement(selectedComponent.id));
      }
    },
    [dispatch, selectedComponent]
  );

  useEffect(() => {
    document.addEventListener('keydown', onDeletePress, false);

    return () => {
      document.removeEventListener('keydown', onDeletePress, false);
    };
  }, [onDeletePress]);
};

export default useDeleteKey;
