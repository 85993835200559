import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';

export const useStyles = (active: boolean) => ({
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    height: 100%;
    width: 96px;
    cursor: pointer;
    padding: 10px;
    background: ${active && brameTheme.palette.primary.main};
    * {
      color: ${active ? '#ffffff' : brameTheme.palette.primary.main};
    }
    &:hover {
      background: ${brameTheme.palette.primary.main};
      * {
        color: #ffffff !important;
      }
    }
  `,
  addButton: css`
    width: 28px;
    height: 28px;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    margin: 17px;
  `,
  addButtonLabel: css`
    font-size: 15px;
    text-align: center;
    color: ${active && '#ffffff'};
    margin: 0 11px;
    white-space: nowrap;
  `,
});
