import { css } from '@emotion/react';

export const useStyles = () => ({
  formWrapper: css`
    width: 100%;
    padding: 20px;

    .MuiGrid-container {
      background: none;
    }
  `,
  formItemWrapper: css`
    text-align: center;
    margin: 20px 0;
  `,
});
