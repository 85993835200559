import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { fetchTemplatesData } from './asyncThunks';
import { normaliseTemplateData } from './utils';
import { initialTemplatesState } from './fixtures';

const templateSlice = createSlice({
  name: 'templates',
  initialState: initialTemplatesState,
  reducers: {
    setCurrentTemplate: (state, action) => {
      state.currentTemplate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTemplatesData.fulfilled, (state, action) => {
      const data = action.payload;
      state.templateCollection = normaliseTemplateData(data);
    });
  },
});

export const selectTemplateCollection = (state: RootState) =>
  state.templates.templateCollection;

export const { setCurrentTemplate } = templateSlice.actions;

export default templateSlice.reducer;
