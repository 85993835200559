/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import {
  FileText,
  GameController,
  Trophy,
  Envelope,
  Files,
} from 'phosphor-react';
import React, { FC, Fragment } from 'react';
import { Box, Popper, Typography } from '@mui/material';
import { PageType } from '../../../reducers/builder';
import CustomIcon from '../Page/CustomIcon';
import { useStyles } from './styles';

interface IProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onPageAdd: (type: PageType) => void;
  isAdditional?: boolean;
}

const AddPagePopper: FC<IProps> = ({
  open,
  anchorEl,
  onPageAdd,
  isAdditional,
}) => {
  const styles = useStyles();
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      sx={{ zIndex: 3 }}
      placement="top"
      data-testid="add-page-popper"
    >
      <Box css={styles.container}>
        {!isAdditional ? (
          <Fragment>
            <div
              css={styles.item}
              onClick={() => onPageAdd(PageType.Basic)}
              data-testid="add-page-regular"
            >
              <CustomIcon Icon={FileText} />
              <Typography sx={{ textAlign: 'center' }}>Regular</Typography>
            </div>

            <div
              css={styles.item}
              onClick={() => onPageAdd(PageType.Game)}
              data-testid="add-page-game"
            >
              <CustomIcon Icon={GameController} />
              <Typography sx={{ textAlign: 'center' }}>Game</Typography>
            </div>

            <div
              css={styles.item}
              onClick={() => onPageAdd(PageType.Outcome)}
              data-testid="add-page-results"
            >
              <CustomIcon Icon={Trophy} />
              <Typography sx={{ textAlign: 'center' }}>Results</Typography>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div
              css={styles.item}
              onClick={() => onPageAdd(PageType.Email)}
              data-testid="add-page-email"
            >
              <CustomIcon Icon={Envelope} />
              <Typography sx={{ textAlign: 'center' }}>Email</Typography>
            </div>
            <div
              css={styles.item}
              onClick={() => onPageAdd(PageType.Terms)}
              data-testid="add-page-terms"
            >
              <CustomIcon Icon={Files} />
              <Typography sx={{ textAlign: 'center' }}>
                Terms and Conditions
              </Typography>
            </div>
          </Fragment>
        )}
      </Box>
    </Popper>
  );
};

export default AddPagePopper;
