/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { Box, Tab, Tabs, useTheme } from '@mui/material';
import { TabPanel, useTabs, useTabsStyles } from '../../TabPanel';
import AssetsLayout from '../AssetsLayout/AssetsLayout';
import {
  AssetElements,
  AssetScope,
  AssetType,
  AssetTypeTab,
  IAssetData,
} from '../../../types';
import { BuilderComponent } from '../../../reducers/builder';
import { FileUploader } from '../FileUploader';
import useGlobalSearch from './useGlobalSearch';

export interface IProps {
  library: AssetScope;
  selectedAsset: IAssetData | null;
  elementType: AssetElements;
  query: string;
}

const availableTabs: AssetTypeTab[] = [
  {
    label: 'Image assets',
    assetType: AssetType.PHOTO,
    elementTypes: [BuilderComponent.IMAGE, BuilderComponent.CONTAINER],
    scopes: [AssetScope.BRAME, AssetScope.GLOBAL, AssetScope.PERSONAL],
  },
  {
    label: 'Game assets',
    assetType: AssetType.GAME,
    elementTypes: [BuilderComponent.IMAGE, BuilderComponent.CONTAINER],
    scopes: [AssetScope.BRAME],
  },
  {
    label: 'Video assets',
    assetType: AssetType.VIDEO,
    elementTypes: [BuilderComponent.VIDEO, BuilderComponent.CONTAINER],
    scopes: [AssetScope.BRAME, AssetScope.GLOBAL],
  },
  {
    label: 'Logos',
    assetType: AssetType.LOGO,
    elementTypes: [BuilderComponent.IMAGE, BuilderComponent.CONTAINER],
    scopes: [AssetScope.BRAME],
  },
];

const AssetsTabPanel: FC<IProps> = ({
  library,
  selectedAsset,
  elementType,
  query,
}) => {
  const { tabIndex, handleTabChange } = useTabs(0);
  const theme = useTheme();
  const tabsStyles = useTabsStyles(theme);
  const tabs = availableTabs
    .filter((tab) => tab.scopes.includes(library))
    .filter((tab) => tab.elementTypes.includes(elementType));

  useGlobalSearch(query, library, tabIndex);

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="asset types tabs"
          indicatorColor="secondary"
          css={tabsStyles.tabs}
          variant="scrollable"
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} css={tabsStyles.tab} />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel noPadding value={tabIndex} index={index} key={index}>
          <React.Fragment>
            {library === AssetScope.PERSONAL && <FileUploader />}
            <AssetsLayout
              assetType={tab.assetType}
              library={library}
              query={query}
              selectedAsset={selectedAsset}
            />
          </React.Fragment>
        </TabPanel>
      ))}
    </div>
  );
};

export default AssetsTabPanel;
