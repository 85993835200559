import { css } from '@emotion/react';

export const useStyles = () => ({
  container: css`
    display: flex;
    align-items: flex-end;
    gap: 3px;
    margin-top: 8px;
  `,
  label: css`
    text-transform: capitalize;
    margin-bottom: 8px;
  `,
});
