/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Dictionary, EntityId } from '@reduxjs/toolkit';
import findIndex from 'lodash/findIndex';
import {
  BuilderComponent,
  ButtonAction,
  ButtonActionMapping,
  ButtonActionType,
  CanvasElement,
  ElementProps,
  IStyleProps,
  ParsedButtonAction,
} from '@brame/builder/src/reducers/builder';
import parseStyleProps from '@brame/builder/src/components/Canvas/utils';
import { Breakpoint } from '@brame/builder/src/reducers/shared/types';
import { DraggableElementsType } from '@brame/builder/src/components/DragNDropWrapper/types';
import { ITheme } from '@brame/theme';
import { buildDefaultStylesFromTheme } from '@brame/builder/src/components/RightPanel/Editors/utils';
import { Text } from '../components/Text';
import { PhaserText } from '../components/PhaserText';
import { Button } from '../components/Button';
import { Image } from '../components/Image';
import { Video } from '../components/Video';
import { FormComponent } from '../components/Form';
import { Container } from '../components/Container';
import { PhaserPlaceHolder } from '../phaser/components/PhaserPlaceholder';
import { PhaserComponent } from '../phaser/components/PhaserComponent';
import PhaserButton from '../components/PhaserButton/PhaserButton';
import findThemedAsset from './themedAssetsUtils';

const components = {
  [BuilderComponent.TEXT]: Text,
  [BuilderComponent.BUTTON]: Button,
  [BuilderComponent.IMAGE]: Image,
  [BuilderComponent.PHASER_GAME]: PhaserPlaceHolder,
  [BuilderComponent.PHASER_PUBLISH]: PhaserComponent,
  [BuilderComponent.PHASER_TEXT]: PhaserText,
  [BuilderComponent.PHASER_BUTTON]: PhaserButton,
  [BuilderComponent.VIDEO]: Video,
  [BuilderComponent.FORM]: FormComponent,
  [BuilderComponent.TERMS_AND_CONDITIONS]: FormComponent,
  [BuilderComponent.CONTAINER]: Container,
  [BuilderComponent.ROOT_CONTAINER]: Container,
};

const haveNoValueElements = [
  BuilderComponent.IMAGE,
  BuilderComponent.VIDEO,
  BuilderComponent.FORM,
];

export const getElementProps = (element: CanvasElement): ElementProps => {
  const props: any = { ...element };
  delete props?.childrenIds;
  delete props?.parentId;
  return props as ElementProps;
};

export const parseMediaQueryStyles = (
  responsiveStyles: {
    [key: string]: IStyleProps;
  },
  breakpoints?: Breakpoint[]
) => {
  const mediaQueries = Object.entries(responsiveStyles).map(
    ([breakpoint, styles]) => {
      return css({
        [`@media (min-width: ${breakpoint}px)`]: parseStyleProps(styles),
      });
    }
  );

  return css(mediaQueries);
};

export const getMediaQueryStyles = ({
  breakpoints,
  activeBreakpoint,
  responsive,
}: {
  breakpoints: Breakpoint[];
  activeBreakpoint: Breakpoint;
  responsive?: { [key: string]: IStyleProps };
}) => {
  if (!responsive) return {};
  const breakpointIndex = findIndex(
    breakpoints,
    (b) => b.id === activeBreakpoint?.id
  );

  return responsive && activeBreakpoint && breakpoints
    ? breakpoints.slice(0, breakpointIndex + 1).reduce((prev, curr): any => {
        let result = {};
        if (responsive?.[curr.breakpoint.toString()]) {
          result = { ...responsive[curr.breakpoint.toString()] };
        }
        return { ...prev, ...result };
      }, {})
    : {};
};

const parseAction = (action: ButtonAction, actions: ButtonActionMapping) => {
  const result: ParsedButtonAction = {};

  if (
    action.type === ButtonActionType.CHANGE_PAGE ||
    action.type === ButtonActionType.ADDITIONAL_PAGE
  ) {
    result.onClick = () => actions[action?.type](action);
  } else if (action.type === ButtonActionType.LINK) {
    result.href = action.target as string;
    result.target = action.openNewTab ? '_blank' : '_self';
  }

  return result;
};

const getAction = (element: CanvasElement, actions?: ButtonActionMapping) => {
  if (!actions) return null;

  if (element.type === BuilderComponent.BUTTON) {
    return parseAction(element.action, actions);
  }

  if (element.type === BuilderComponent.FORM) {
    const { fields } = element;

    return {
      fields: fields?.map((field) => ({
        ...field,
        ...(field.action ? parseAction(field.action, actions) : {}),
      })),
    };
  }

  return null;
};

export const renderTree = ({
  rootElementId,
  elements,
  wrapper,
  actions,
  gameData,
  breakpoints,
  activeBreakpoint,
  builderMode,
  theme,
}: {
  rootElementId: EntityId;
  elements: Dictionary<CanvasElement>;
  wrapper?: (element: DraggableElementsType) => any;
  actions?: ButtonActionMapping;
  breakpoints?: Breakpoint[];
  activeBreakpoint?: Breakpoint;
  builderMode?: boolean;
  gameData?: any;
  theme?: ITheme;
}) => {
  const renderPage = (id: EntityId, index = 0) => {
    const element = elements[id];
    if (!element) return null;
    if (!builderMode && element.type === BuilderComponent.PHASER_GAME) {
      //@ts-ignore
      element.type = BuilderComponent.PHASER_PUBLISH;
    }
    const Component = wrapper
      ? // @ts-ignore
        wrapper(components[element.type])
      : components[element.type];
    const children =
      element.type === BuilderComponent.ROOT_CONTAINER ||
      element.type === BuilderComponent.CONTAINER
        ? element.childrenIds.map(renderPage)
        : element.value;
    const props = getElementProps(element);

    const mediaQueries =
      (!!element.styles?.responsive &&
        parseMediaQueryStyles(element.styles.responsive, breakpoints)) ||
      {};

    const mediaQueryStyles =
      breakpoints && activeBreakpoint
        ? getMediaQueryStyles({
            breakpoints,
            responsive: element.styles?.responsive,
            activeBreakpoint,
          })
        : {};

    const themeStyleProps =
      theme && buildDefaultStylesFromTheme(element, theme);

    const styleProps = {
      // @ts-ignore
      ...themeStyleProps,
      ...element?.styles?.defaults,
      ...element?.styles?.overrides,
      ...mediaQueryStyles,
    };

    //@ts-ignore
    if (element.type === BuilderComponent.PHASER_PUBLISH) {
      gameData['game.preview'] = false;
      return (
        //@ts-ignore
        <PhaserComponent
          key={id}
          styles={
            //TODO: @Vlad from Marc: Check how the styles object is working and how can we fix the typeerrors
            //@ts-ignore
            !!element.styles &&
            css([
              parseStyleProps({
                //@ts-ignore
                ...element.styles.defaults,
                //@ts-ignore
                ...element.styles.overrides,
                ...mediaQueryStyles,
              }),
              !wrapper && mediaQueries,
              { position: 'relative', zIndex: '3' },
            ])
          }
          id={id}
          gametype={gameData['gameType']}
          json={gameData}
          {...props}
        />
      );
    } else {
      return (
        //@ts-ignore
        <Component
          key={id}
          css={
            !!element.styles &&
            css([
              !wrapper && mediaQueries,
              parseStyleProps({
                ...element.styles.defaults,
                ...element.styles.overrides,
                ...mediaQueryStyles,
              }),
            ])
          }
          data-testid={`test-${element.type}`}
          //@ts-ignore
          element={{
            ...element,
            // @ts-ignore
            themedMedia: findThemedAsset(theme, element) || null,
            builderMode,
            index,
          }}
          elementStyles={styleProps}
          currentTheme={theme}
          {...props}
          {...(element.type === BuilderComponent.PHASER_TEXT && !wrapper
            ? //@ts-ignore
              { gameType: gameData['gameType'] }
            : {})}
          {...(element.type === BuilderComponent.PHASER_BUTTON && !wrapper
            ? //@ts-ignore
              { gameType: gameData['gameType'] }
            : {})}
          {...((element.type === BuilderComponent.BUTTON ||
            element.type === BuilderComponent.FORM) &&
          !wrapper
            ? { ...getAction(element, actions) }
            : {})}
        >
          {haveNoValueElements.includes(element.type) ? null : children}
        </Component>
      );
    }
  };

  return renderPage(rootElementId);
};
