import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';

export const useStyles = (active?: boolean) => ({
  container: css`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    border-bottom: 2px solid
      ${active ? brameTheme.palette.primary.main : 'transparent'};

    &:hover {
      border-bottom: 2px solid ${brameTheme.palette.primary.main};
    }
  `,
  box: css`
    //flex-basis: 50%;
  `,
});
