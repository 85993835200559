import { css } from '@emotion/react';

export const useStyles = () => ({
  asset: css`
    margin-bottom: 10px;
    width: 80%;
    align-items: center;
    display: flex;
  `,
  margin10: css`
    margin-bottom: 10px;
    width: 80%;
  `,
  box1: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 30px;
    width: 100%;
  `,
  box2: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    width: 20%;
  `,
  box3: css`
    width: 40%;
    display: flex;
    align-items: start;
    justify-content: start;
    text-align: start;
  `,
  box4: css`
    width: 40%;
  `,
  box5: css`
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 200px;
  `,
  listItem: css`
    padding: 0 0 1px 0;
  `,
});
