import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { Provider } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { runnerStore } from '@brame/components/src/slices/store';
import { brameTheme } from '@brame/theme';
import Main from './pages/main/main-page';
import ProtectedRoute from './ProtectedRoute';
import { Header } from './components/Header';
import { useAuth } from './auth/authHooks';
import ErrorCampaignNotFound from './components/ErrorCampaignNotFound';
import { Preview } from './pages/preview';
import { checkAllNeededEnvVars } from './utils/utils';
import { ErrorFallback } from './components/ErrorFallback';
import { Wizzard } from './pages/wizzard';
checkAllNeededEnvVars();

const App: React.FC = () => {
  const authState = useAuth();
  const theme = createTheme(brameTheme);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserRouter basename="/advanced-builder">
        <Routes>
          <Route
            path="/:campaignId"
            element={
              <ProtectedRoute
                authState={authState}
                authenticationPath={process.env.REACT_APP_AUTHENTICATION_URL}
                outlet={
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Header />
                    <Main />
                  </ThemeProvider>
                }
              />
            }
          />
          <Route
            path="/:campaignId/:companyId/preview"
            element={
              <ProtectedRoute
                authState={authState}
                authenticationPath={process.env.REACT_APP_AUTHENTICATION_URL}
                outlet={
                  <Provider store={runnerStore}>
                    <Preview />
                  </Provider>
                }
              />
            }
          />
          <Route
            path="*"
            element={
              <ProtectedRoute
                authState={authState}
                authenticationPath={process.env.REACT_APP_AUTHENTICATION_URL}
                outlet={
                  <ThemeProvider theme={theme}>
                    <Wizzard />
                  </ThemeProvider>
                }
              />
            }
          />
          <Route
            path="/error-page"
            element={
              <ProtectedRoute
                authState={authState}
                authenticationPath={process.env.REACT_APP_AUTHENTICATION_URL}
                outlet={
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <ErrorCampaignNotFound />
                  </ThemeProvider>
                }
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
