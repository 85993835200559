import React, { useState } from 'react';
import { AlertColor } from '@mui/material/Alert';
import Alert from './Alert';

const useAlert = () => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState<string>('');
  const [type, setType] = useState<AlertColor>('info');

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    event?.preventDefault();
    event?.stopPropagation();
    setOpen(false);
  };

  const openAlert = (text: string, type: AlertColor) => {
    setText(text);
    setType(type);
    setOpen(true);
  };

  return {
    toast: (
      <Alert text={text} type={type} open={open} handleClose={handleClose} />
    ),
    openAlert,
  };
};
export default useAlert;
