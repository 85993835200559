import { css } from '@emotion/react';

export const useStyles = () => ({
  pageNavigator: css`
    display: flex;
    justify-content: center;
  `,

  pageScroller: css`
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0 10px;

    &::-webkit-scrollbar {
      display: none;
    },
  `,

  pageScrollerContent: css`
    display: flex;
    width: max-content;
    margin: 0 auto;
  `,
});
