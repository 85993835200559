import { css } from '@emotion/react';

export const useStyles = () => ({
  label: css`
    color: white;
    font-size: 12px;
    border-radius: 4px 4px 0 0;
    background-color: #0085ff;
    padding: 5px;
    margin-left: -2px;
    display: flex;
    align-items: center;
  `,
});
