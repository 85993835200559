import React, { FC, useEffect, useState } from 'react';
import SelectInput from '../SelectInput/SelectInput';
import { ColorPickerWidget } from '../ColorPickerWidget';
import { ColorVariantType } from '../../types';

interface IProps {
  color: string | ColorVariantType;
  onChange: (values: IChangeType | string) => void;
  hex?: string;
}

interface IChangeType {
  elementProps: { color: ColorVariantType };
}

const ButtonColorPicker: FC<IProps> = ({ color, onChange, hex }) => {
  const [currentColor, setCurrentColor] = useState<typeof color>();

  useEffect(() => {
    setCurrentColor(hex ? 'custom' : color);
  }, [color, hex]);

  const colors: ColorVariantType[] = ['primary', 'secondary', 'custom'];

  const handleChange = (value: string) => {
    if (value !== 'custom') {
      onChange({
        elementProps: { color: value as ColorVariantType },
      });
    }
    setCurrentColor(value as ColorVariantType);
  };

  return (
    <div>
      {currentColor && (
        <div>
          <SelectInput
            value={currentColor}
            options={colors}
            onChange={handleChange}
          />
          {currentColor === 'custom' && (
            <ColorPickerWidget
              value={hex || '#ffffff'}
              alpha
              onChange={onChange}
              title="Pick a color"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ButtonColorPicker;
