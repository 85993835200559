/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC, useState } from 'react';
import { CaretDown } from 'phosphor-react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Tooltip,
} from '@mui/material';
import {
  BuilderComponent,
  ILibrarySection,
  PageType,
} from '../../../reducers/builder';
import { useStyles } from '../styles';
import { libraryElementsWrapper } from '../../DragNDropWrapper';
import { LibrarySectionTitle } from '../LibrarySectionTitle';
import { useAppSelector } from '../../../reducers/app/hooks';
import { getSelectedPage } from '../../../reducers/builder/builderSlice';

interface IProps {
  section: ILibrarySection;
}

const DraggableComponent = libraryElementsWrapper(Button);

const LibrarySection: FC<IProps> = ({ section }) => {
  const styles = useStyles();
  const [expanded, setExpanded] = useState(false);
  const currentPage = useAppSelector(getSelectedPage);

  const isGamePage = currentPage?.pageType === PageType.Game;

  return (
    <Accordion
      css={styles.accordion}
      elevation={0}
      onChange={(e, expanded) => setExpanded(expanded)}
      data-testid="library-section"
    >
      <AccordionSummary data-testid={section.title} expandIcon={<CaretDown />}>
        <LibrarySectionTitle section={section} expanded={expanded} />
      </AccordionSummary>
      <AccordionDetails css={styles.details}>
        {section.elements.map((el) => {
          return (
            <Tooltip
              key={el.title}
              title={
                el.type === BuilderComponent.PHASER_GAME && !isGamePage
                  ? 'Game component can be used only on a game page'
                  : ''
              }
            >
              <div key={el.title}>
                {/*@ts-ignore*/}
                <DraggableComponent
                  elementType={el.type}
                  fullWidth
                  size="small"
                  css={styles.wrapperButton}
                  variant="outlined"
                  startIcon={el.icon}
                  disabled={
                    el.type === BuilderComponent.PHASER_GAME && !isGamePage
                  }
                  option={el.option}
                >
                  {el.title}
                </DraggableComponent>
              </div>
            </Tooltip>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default LibrarySection;
