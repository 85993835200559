import { css } from '@emotion/react';

export const useStyles = () => {
  return {
    formContainer: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100vh - (67px + 85px));
      overflow-y: auto;
    `,
    form: css`
      padding: 20px;
      max-width: 560px;
      width: 100%;
      box-sizing: border-box;
    `,
    title: css`
      text-transform: uppercase;
      color: #00000099;
      margin: 10px 0;
    `,
  };
};
