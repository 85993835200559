import { PHASER_GAME_TYPE } from '@brame/builder/src/components/GameEditors/GameToolbar/GameTypes';
import { runnerStore } from '@brame/components/src/slices/store';
import {
  resetState,
  setValue,
} from '../../slices/gameStateSlice/gameStateSlice';

const stateFunctions: Object = {};

export interface stateParams {
  storeKey: string;
  value: any;
}

export interface functionParams {
  id: string;
  args: any[];
  function: any;
}

// Register values to store in redux
export const initState = (params: stateParams[]) => {
  const state = params.reduce((a, v) => ({ ...a, [v.storeKey]: v.value }), {});
  //@ts-ignore
  runnerStore.dispatch(resetState({ storeState: state }));
};

// Register callback functions to modify game state

export const updateStoreValue = (valueID: string, value: any) => {
  runnerStore.dispatch(setValue({ param: valueID, value: value }));
};

export const getValue = (valueID: string) => {
  //@ts-ignore
  if (runnerStore['gameState']) {
    //@ts-ignore
    return runnerStore['gameState'][valueID];
  }
};
// Fetch list of values to read from

export const usePhaserValueList = (gameType: PHASER_GAME_TYPE) => {
  switch (gameType) {
    case PHASER_GAME_TYPE.FruitDrop:
      return [
        { value: 'score', label: 'Score' },
        { value: 'lives', label: 'Lives' },
        { value: 'time', label: 'Time' },
      ];
      break;
    case PHASER_GAME_TYPE.SlotMachine:
      return [{ value: 'spinning', label: 'Is the machine spinning?' }];
      break;
  }
};

// Functions to call on scene

export const usePhaserFunctionsList = (gameType: PHASER_GAME_TYPE) => {
  switch (gameType) {
    case PHASER_GAME_TYPE.FruitDrop:
      return [{ value: 'playagain', label: 'Play again' }];
      break;
    case PHASER_GAME_TYPE.SlotMachine:
      return [{ value: 'spin', label: 'Start spin' }];
      break;
  }
};

export const registerPhaserFunction = (
  params: functionParams | functionParams[]
) => {
  if (Array.isArray(params)) {
    for (const entry in params) {
      //@ts-ignore
      stateFunctions[params[entry].id] = {};
      //@ts-ignore
      stateFunctions[params[entry].id].function = params[entry].function;
      //@ts-ignore
      stateFunctions[params[entry].id].args = params[entry].args;
    }
  } else {
    //@ts-ignore
    stateFunctions[params.id] = {};
    //@ts-ignore
    stateFunctions[params.id].function = params.function;
    //@ts-ignore
    stateFunctions[params.id].args = params.args;
  }
};

export const callPhaserFunction = (functionID: string | undefined) => {
  //@ts-ignore
  stateFunctions[functionID].function(...stateFunctions[functionID].args);
};
