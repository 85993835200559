import { css } from '@emotion/react';

import { brameTheme } from '@brame/theme';

export const useStyles = () => ({
  tabTitle: css`
    position: sticky;
    z-index: 2;
    top: 0;
    padding: 13px;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    background: ${brameTheme.palette.primary.main};
    color: ${brameTheme.palette.primary.contrastText};
    width: 100%;
    box-shadow: 0 10px 20px #23470720;
    border-radius: 0 0 5px 5px;
  `,
});
