import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';

export const useStyles = (selected?: boolean) => ({
  assetContainer: css`
    position: relative;
  `,
  asset: css`
    display: block;
    width: 100%;
    cursor: pointer;
  `,
  selectedIcon: css`
    position: absolute;
    top: 8px;
    right: 8px;
    border-radius: 50%;
    background-color: ${brameTheme.palette.primary.contrastText};
    color: ${brameTheme.palette.primary.main};
  `,
  removeIcon: css`
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 0;
    cursor: pointer;
    color: #dedede;

    &:hover {
      color: ${selected ? '#dedede' : 'red'};
    }
  `,
});
