import { css } from '@emotion/react';

export const useStyles = () => ({
  input: css`
    margin: 8px 0;
  `,
  container: css`
    display: flex;
    gap: 3px;
  `,
});
