import { ResizeCallback } from 're-resizable';
import { EntityId } from '@reduxjs/toolkit';
import {
  useAppDispatch,
  useAppSelector,
} from '@brame/builder/src/reducers/app/hooks';
import {
  getSelectedElement,
  updateStyles,
} from '@brame/builder/src/reducers/builder/builderSlice';
import { getSelectedBreakpoint } from '@brame/builder/src/reducers/shared/sharedSlice';
import {
  BuilderComponent,
  CanvasElement,
  IStyleProps,
} from '@brame/builder/src/reducers/builder';
import { IElementStyleProps } from '@brame/builder/src/types';
import { setGameParam } from '@brame/builder/src/reducers/game/gameSlice';
import useResize from '@brame/builder/src/components/SizingWidget/useResize';

const useResizableElement = (
  element: CanvasElement,
  styleProps: IElementStyleProps
) => {
  const selectedElement = useAppSelector(getSelectedElement);
  const isSelected = element?.id === selectedElement?.id;
  const dispatch = useAppDispatch();
  const activeBreakpoint = useAppSelector(getSelectedBreakpoint);
  const { toPercent, parentRect } = useResize();

  const widthValue = styleProps?.size?.width?.value;
  const widthUnit = styleProps?.size?.width?.unit;
  const heightValue = styleProps?.size?.height?.value;
  const heightUnit = styleProps?.size?.height?.unit;

  const prohibitWidth =
    !widthValue || widthValue === 'auto' || widthUnit === 'auto';
  const prohibitHeight =
    !heightValue || heightValue === 'auto' || heightUnit === 'auto';

  const handleResize: ResizeCallback = (e, direction, ref, d): void => {
    const deltaWidth =
      widthUnit === '%'
        ? toPercent(parentRect?.width as number, d.width)
        : d.width;
    const deltaHeight =
      heightUnit === '%'
        ? toPercent(parentRect?.height as number, d.height)
        : d.height;

    const updateObject: {
      id?: EntityId;
      styles: IStyleProps;
      activeBreakpoint?: string;
    } = {
      id: element?.id,
      styles: {
        size: {
          width: { value: widthValue + deltaWidth, unit: widthUnit },
          height: { value: heightValue + deltaHeight, unit: heightUnit },
        },
      },
    };
    if (!activeBreakpoint.default) {
      updateObject.activeBreakpoint = activeBreakpoint.breakpoint.toString();
    }

    if (element.type == BuilderComponent.PHASER_GAME) {
      dispatch(
        setGameParam({
          param: 'game.size',
          value: updateObject.styles.size,
        })
      );
    }
    dispatch(updateStyles(updateObject));
  };

  return {
    isSelected,
    prohibitWidth,
    prohibitHeight,
    widthValue,
    widthUnit,
    heightValue,
    heightUnit,
    handleResize,
  };
};

export default useResizableElement;
