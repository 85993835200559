import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AssetScope, AssetType, IAsset, IAssetData } from '../../types';
import AssetsService from '../../api-client/assets.service';
import {
  fetchGlobalAssets,
  fetchPersonalAssets,
  searchGlobalAssets,
} from './asyncThunks';
import { IAssetsState } from './types';

export const initialAssetsState: IAssetsState = {
  global: {
    images: [],
    videos: [],
  },
  brame: {
    images: AssetsService.getBrameAssetCollection(AssetType.PHOTO),
    videos: AssetsService.getBrameAssetCollection(AssetType.VIDEO),
    icons: AssetsService.getBrameAssetCollection(AssetType.LOGO),
    games: AssetsService.getBrameAssetCollection(AssetType.GAME),
  },
  personal: {
    images: [],
  },
  selectedAsset: null,
};

const assetSlice = createSlice({
  name: 'assets',
  initialState: initialAssetsState,
  reducers: {
    selectAsset: (state, action) => {
      state.selectedAsset = action.payload;
    },
    removePersonalAsset: (state, action) => {
      const { id } = action.payload;
      const index = state.personal.images.findIndex(
        (image) => image.uuid === id
      );
      state.personal.images.splice(index, 1);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGlobalAssets.fulfilled, (state, action) => {
      if (!action.payload) return state;
      const data = action.payload as IAsset[];
      if (action.meta.arg === AssetType.PHOTO) {
        state.global.images = data;
      } else {
        state.global.videos = data;
      }
    });
    builder.addCase(fetchPersonalAssets.fulfilled, (state, action) => {
      if (!action.payload) return state;
      state.personal.images = action.payload as IAsset[];
    });
    builder.addCase(fetchPersonalAssets.rejected, (state, action) => {
      state.personal.images = [];
      // todo: add some status for showing failure on load
    });
    builder.addCase(searchGlobalAssets.fulfilled, (state, action) => {
      if (!action.payload) return state;
      const data = action.payload as IAsset[];
      if (action.meta.arg.assetType === AssetType.PHOTO) {
        state.global.images = data;
      } else {
        state.global.videos = data;
      }
    });
  },
});

export const { selectAsset, removePersonalAsset } = assetSlice.actions;

export const globalImagesSelector = (state: RootState): IAsset[] =>
  state.assets.global.images;

export const globalVideosSelector = (state: RootState): IAsset[] =>
  state.assets.global.videos;

export const brameImagesSelector = (state: RootState): IAsset[] =>
  state.assets.brame.images;

export const brameVideosSelector = (state: RootState): IAsset[] =>
  state.assets.brame.videos;

export const brameIconsSelector = (state: RootState): IAsset[] =>
  state.assets.brame.icons;

export const brameGamesSelector = (state: RootState): IAsset[] =>
  state.assets.brame.games;

export const personalImagesSelector = (state: RootState): IAsset[] =>
  state.assets.personal.images;

export const selectedAssetSelector = (state: RootState): IAssetData | null =>
  state.assets.selectedAsset;

export default assetSlice.reducer;
