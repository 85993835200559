import axios, { AxiosError } from 'axios';
import axiosI from '@brame/builder/src/api-client/axios.service';
import { ITheme } from '@brame/theme/src/types';
import { generateId } from '@brame/builder/src/reducers/builder/utils';
import tokenService from '@brame/builder/src/auth/token.service';

const themesUrl = `${process.env.REACT_APP_API_URL}/campaign-configurations/api/themes`;
const COMPANY_ID = '0e52b89d-e635-11ec-994a-65fdbf41848e';

export const fetchThemesData = async (companyId: string | undefined) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenService.getCookieAccessToken()}`,
    },
  };
  const url = `${themesUrl}/all/${companyId}`;

  try {
    const res = await axios.get(url, config);

    if (res.data === 'undefined') {
      return res.data;
    }
    return res.data.data;
  } catch (error) {
    const err = error as AxiosError;
    return err.response;
  }
};

export const saveNewTheme = async (theme: ITheme) => {
  const newId = `theme-${generateId()}`;
  const newTheme: ITheme = { ...theme, id: newId, type: 'custom' };

  const options = {
    companyId: COMPANY_ID,
    jsonData: newTheme,
    themeId: newId,
  };
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenService.getCookieAccessToken()}`,
    },
  };

  try {
    const res = await axios.post(themesUrl, options, config);

    if (res.data === 'undefined') {
      return res;
    }
    return res.data;
  } catch (error) {
    const err = error as AxiosError;
    return err.response;
  }
};

export const deleteTheme = async (companyId: string, theme: ITheme) => {
  const url = `${themesUrl}/${companyId}/${theme.id}`;

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenService.getCookieAccessToken()}`,
    },
  };

  try {
    const res = await axiosI.get(url, config);

    if (res.data === 'undefined') {
      return res.data;
    }
    return res.data.data;
  } catch (error) {
    const err = error as AxiosError;
    return err.response;
  }
};

export const saveExistingTheme = async (theme: ITheme) => {
  const options = {
    companyId: COMPANY_ID,
    jsonData: theme,
    themeId: theme.id,
  };

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenService.getCookieAccessToken()}`,
    },
  };

  try {
    const res = await axiosI.put(themesUrl, options, config);

    if (res.data === 'undefined') {
      return res;
    }
    return res.data;
  } catch (error) {
    const err = error as AxiosError;
    return err.response;
  }
};
