import { css } from '@emotion/react';

export const useStyles = () => ({
  container: css`
    gap: 10px;
    display: grid;
    grid-template-columns: 1fr 30px;
    align-items: end;
  `,
  title: css`
    text-transform: capitalize;
    margin-bottom: 8px;
  `,
  parent: css`
    display: flex;
    flex-direction: column;
    gap: 10px;
  `,
  toggleButton: css`
    padding: 3px;
    transform: rotate(90deg);
    border: none;

    &.Mui-selected {
      background: none;
    }
  `,
  inputs: css`
    display: flex;
    gap: 10px;
  `,
});
