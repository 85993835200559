import { ReactComponent as Memory } from './assets/memory.svg';
import { ReactComponent as Spin } from './assets/spin.svg';
import { ReactComponent as Survey } from './assets/survey.svg';
import { ReactComponent as Picture } from './assets/picture.svg';
import { ReactComponent as Test } from './assets/test.svg';
import { ReactComponent as Quiz } from './assets/quiz.svg';
import { ReactComponent as Swipe } from './assets/swipe.svg';
import { ReactComponent as QuizSwipe } from './assets/quizSwipe.svg';
import { ReactComponent as Scratch } from './assets/scratch.svg';
import { ReactComponent as Easter } from './assets/easter.svg';
import { ReactComponent as Advent } from './assets/advent.svg';
import { ReactComponent as Weekly } from './assets/weekly.svg';
import { ReactComponent as Drop } from './assets/drop.svg';
import { ReactComponent as Fly } from './assets/fly.svg';
import { ReactComponent as Drive } from './assets/drive.svg';
import { ReactComponent as Swim } from './assets/swim.svg';
import { ReactComponent as Machine } from './assets/machine.svg';

export enum WizzardGameType {
  MEMORY_GAME = 'Memory game',
  SPIN_THE_WHEEl = 'Spin the wheel',
  SURVEY = 'Survey',
  GUESS_THE_PICTURE = 'Guess the picture',
  PERSONALITY_TEST = 'Personality test',
  QUIZ = 'Quiz',
  PERSONALITY_TEST_SWIPE = 'Personality test swipe',
  QUIZ_SWIPE = 'Quiz swipe',
  SCRATCH_CARD = 'Scratch card',
  EASTER_CALENDAR = 'Easter calendar',
  ADVENT_CALENDAR = 'Advent calendar',
  WEEKLY_CALENDAR = 'Weekly calendar',
  DROP = 'Drop',
  FLY = 'Fly',
  DRIVE = 'Drive',
  SWIM = 'Swim',
  SLOT_MACHINE = 'Slot Machine',
}

export enum WizzardType {
  CAMPAIGN_TYPE = 'Campaign Type',
  CAMPAIGN_DETAILS = 'Campaign Details',
  CAMPAIGN_THEME = 'Campaign Theme',
  CAMPAIGN_TEMPLATE = 'Campaign Template',
}

export const gameCardsTabOne = [
  {
    id: 1,
    name: WizzardGameType.MEMORY_GAME,
    icon: <Memory />,
    color: '#8686CB',
  },
  {
    id: 2,
    name: WizzardGameType.SPIN_THE_WHEEl,
    icon: <Spin />,
    color: '#67B3DC',
  },
  { id: 3, name: WizzardGameType.SURVEY, icon: <Survey />, color: '#71C7C7' },
  {
    id: 4,
    name: WizzardGameType.GUESS_THE_PICTURE,
    icon: <Picture />,
    color: '#EEAA8E',
  },
  {
    id: 5,
    name: WizzardGameType.PERSONALITY_TEST,
    icon: <Test />,
    color: '#7ACBAA',
  },
  { id: 6, name: WizzardGameType.QUIZ, icon: <Quiz />, color: '#DDD26A' },
  {
    id: 7,
    name: WizzardGameType.PERSONALITY_TEST_SWIPE,
    icon: <Swipe />,
    color: '#8CCB7A',
  },
  {
    id: 8,
    name: WizzardGameType.QUIZ_SWIPE,
    icon: <QuizSwipe />,
    color: '#DDBB6A',
  },
  {
    id: 9,
    name: WizzardGameType.SCRATCH_CARD,
    icon: <Scratch />,
    color: '#CBAF86',
  },
  {
    id: 10,
    name: WizzardGameType.EASTER_CALENDAR,
    icon: <Easter />,
    color: '#E46B6E',
  },
  {
    id: 11,
    name: WizzardGameType.ADVENT_CALENDAR,
    icon: <Advent />,
    color: '#C685CC',
  },
  {
    id: 12,
    name: WizzardGameType.WEEKLY_CALENDAR,
    icon: <Weekly />,
    color: '#859FCC',
  },
];

export const gameCardsTabTwo = [
  { id: 13, name: WizzardGameType.DROP, icon: <Drop />, color: '#EC768B' },
  { id: 14, name: WizzardGameType.FLY, icon: <Fly />, color: '#82A3E2' },
  { id: 15, name: WizzardGameType.DRIVE, icon: <Drive />, color: '#8F82E2' },
  { id: 16, name: WizzardGameType.SWIM, icon: <Swim />, color: '#D87AE0' },
  {
    id: 17,
    name: WizzardGameType.SLOT_MACHINE,
    icon: <Machine />,
    color: '#9367DC',
  },
];
