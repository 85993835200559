/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { FC } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Grid, Tab } from '@mui/material';
import useThemeData from '@brame/components/src/hooks/useThemeData';
import { ITheme } from '@brame/theme';
import { IWizzard } from '../initialWizzardState';
import { useStyles } from './styles';
import { ThemeCard } from './ThemeCard';
import { useCustomThemesData } from './hooks/useCustomThemesData';

export interface ICampaignTheme {
  wizzardState: IWizzard;
  handleWizzardState: (stepName: string, payload: IWizzard) => void;
  handleProgressBar: (activeType: string) => void;
  handleCustomThemes: (customThemes: ITheme[]) => void;
}

const CampaignTheme: FC<ICampaignTheme> = ({
  wizzardState,
  handleWizzardState,
  handleProgressBar,
  handleCustomThemes,
}) => {
  const styles = useStyles();
  const [activeTab, setActiveTab] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const { customThemesData } = useCustomThemesData(handleCustomThemes);
  const { themeCollection } = useThemeData();
  const themeIds = Object.keys(themeCollection);
  const currentThemeId = wizzardState.campaignTheme.themeId;

  return (
    <div data-testid="campaign-theme-content">
      <TabContext value={activeTab.toString()}>
        <Box css={styles.tabsContainer}>
          <TabList
            variant="fullWidth"
            onChange={handleTabChange}
            aria-label="theme-tabs"
            css={styles.tabsList}
            sx={{
              '.MuiTabs-indicator': {
                display: 'none',
              },
            }}
          >
            <Tab label="Default" value="0" css={styles.tabsItem} />
            <Tab label="Your Themes" value="1" css={styles.tabsItem} />
          </TabList>
        </Box>
        <div css={styles.panelContainer}>
          <TabPanel css={styles.panelItem} value="0">
            <Grid container spacing={2}>
              {!!themeIds.length &&
                themeIds.map((id) => (
                  <ThemeCard
                    wizzardState={wizzardState}
                    handleProgressBar={handleProgressBar}
                    theme={themeCollection[id]}
                    onSelect={handleWizzardState}
                    active={currentThemeId === id}
                    key={id}
                  />
                ))}
            </Grid>
          </TabPanel>
          <TabPanel css={styles.panelItem} value="1">
            <Grid container spacing={2}>
              {!!customThemesData &&
                customThemesData.map((theme) => (
                  <ThemeCard
                    wizzardState={wizzardState}
                    handleProgressBar={handleProgressBar}
                    theme={theme}
                    onSelect={handleWizzardState}
                    active={currentThemeId === theme.id}
                    key={theme.id}
                  />
                ))}
            </Grid>
          </TabPanel>
        </div>
      </TabContext>
    </div>
  );
};

export default CampaignTheme;
