import { css } from '@emotion/react';

export const useStyles = () => ({
  title: css`
    text-transform: capitalize;
    margin-bottom: 8px;
  `,
  styleControl: css`
    display: inline-flex;
    align-items: center;
  `,
  colorHexInput: css`
    width: 90px;
    text-transform: uppercase;
    padding: 6px 8px;
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid #8080807f;
    color: #808080;

    &:focus {
      outline-color: #88c559;
    }
    &:hover {
      border-color: #808080;
    }
  `,
  toolbarArea: css`
    margin-bottom: 10px;
  `,
});
