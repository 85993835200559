// @ts-nocheck
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useState } from 'react';
import { CaretDown } from 'phosphor-react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from '@mui/material';
import useThemeData from '@brame/components/src/hooks/useThemeData';
import { IThemeDialog } from '@brame/theme';
import { ThemeSwitcher } from '../ThemeSwitcher';
import { ColorPickerWidget } from '../ColorPickerWidget';
import { FontSelectorWidget } from '../FontSelectorWidget';
import { ButtonPropsWidget } from '../ButtonPropsWidget';
import { ToolbarCategory } from '../ToolbarCategory';
import { AssetsViewer } from '../AssetsViewer';
import { TextTransformWidget } from '../TextTransformWidget';
import { TextAlignSwitcher } from '../TextAlignSwitcher';
import { useStyles } from './styles';
import { ThemeDialog } from './ThemeDialog';
import { IConfirmationData } from './ThemeDialog/ThemeDialog';

const ThemeEditor: FC = () => {
  const {
    setCurrentThemeById,
    createNewTheme,
    saveCurrentTheme,
    themeCollection,
    currentTheme,
    chosenTheme,
    applyTheme,
    resetThemePreview,
    fontFamilies,
    fontSizes,
    updateCurrentTheme,
  } = useThemeData();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<string | null>(null);

  const openDialog = (dialogType: IThemeDialog) => {
    setDialogType(dialogType);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setDialogType(null);
  };

  const confirmSave = (data: IConfirmationData) => {
    if (data.dialogType === 'new') {
      createNewTheme(data.themeName);
    }
    if (data.dialogType === 'update') {
      saveCurrentTheme();
    }
    closeDialog();
  };

  const {
    assets,
    theme: {
      components: {
        MuiTypography: {
          styleOverrides: { root: typographyRoot, h2 },
        },
        MuiButton: {
          styleOverrides: { root: buttonRoot },
        },
        MuiContainer: {
          styleOverrides: {
            root: { background },
          },
        },
      },
    },
  } = currentTheme;

  const styles = useStyles();

  return (
    <div data-testid="theme-editor">
      <Accordion elevation={0} css={styles.accordion}>
        <AccordionSummary expandIcon={<CaretDown />}>
          <div css={styles.titleBlock}>
            <Typography css={styles.title}>Themes</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <ThemeSwitcher
            themeCollection={themeCollection}
            currentThemeId={currentTheme.id}
            onApplyTheme={(themeId) => {
              setCurrentThemeById(themeId);
              applyTheme(themeId);
            }}
            onResetPreviewTheme={() => resetThemePreview(chosenTheme.id)}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} css={styles.accordion}>
        <AccordionSummary expandIcon={<CaretDown />}>
          <div css={styles.titleBlock}>
            <Typography css={styles.title}>Media</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <AssetsViewer
            assets={assets}
            type="media"
            onChange={(value) => updateCurrentTheme({ assets: value })}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} css={styles.accordion}>
        <AccordionSummary expandIcon={<CaretDown />}>
          <div css={styles.titleBlock}>
            <Typography css={styles.title}>Fonts</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="category-item">
            <div css={styles.fontBlock}>
              <FontSelectorWidget
                title="headings"
                props={{
                  font: {
                    fontList: fontFamilies,
                    currentFont: h2.fontFamily,
                  },
                  size: {
                    sizeList: fontSizes,
                    currentSize: h2.fontSize,
                  },
                  color: h2.color,
                }}
                onChange={(fontProps) =>
                  updateCurrentTheme({
                    h2: {
                      fontFamily: fontProps.font.currentFont,
                      fontSize: fontProps.size.currentSize,
                      color: fontProps.color,
                    },
                  })
                }
              />
              <div css={styles.fontProps}>
                <TextTransformWidget
                  textTransform={h2.textTransform}
                  label="Letters"
                  onChange={(value) =>
                    updateCurrentTheme({ h2: { textTransform: value } })
                  }
                />
                <TextAlignSwitcher
                  align={h2.textAlign}
                  label="Alignment"
                  onChange={(value) =>
                    updateCurrentTheme({ h2: { textAlign: value } })
                  }
                />
              </div>
            </div>
            <div css={styles.fontBlock}>
              <FontSelectorWidget
                title="body"
                props={{
                  font: {
                    fontList: fontFamilies,
                    currentFont: typographyRoot.fontFamily,
                  },
                  size: {
                    sizeList: fontSizes,
                    currentSize: typographyRoot.fontSize,
                  },
                  color: typographyRoot.color,
                }}
                onChange={(fontProps) =>
                  updateCurrentTheme({
                    typographyRoot: {
                      fontFamily: fontProps.font.currentFont,
                      fontSize: fontProps.size.currentSize,
                      color: fontProps.color,
                    },
                  })
                }
              />
              <div css={styles.fontProps}>
                <TextTransformWidget
                  textTransform={typographyRoot.textTransform}
                  label="Letters"
                  onChange={(value) =>
                    updateCurrentTheme({
                      typographyRoot: { textTransform: value },
                    })
                  }
                />
                <TextAlignSwitcher
                  align={typographyRoot.textAlign}
                  label="Alignment"
                  onChange={(value) =>
                    updateCurrentTheme({ typographyRoot: { textAlign: value } })
                  }
                />
              </div>
            </div>
            <div css={styles.fontBlock}>
              <FontSelectorWidget
                title="Buttons"
                props={{
                  font: {
                    fontList: fontFamilies,
                    currentFont: buttonRoot.fontFamily,
                  },
                  size: {
                    sizeList: fontSizes,
                    currentSize: buttonRoot.fontSize,
                  },
                  color: buttonRoot.color,
                }}
                onChange={(fontProps) =>
                  updateCurrentTheme({
                    buttonRoot: {
                      fontFamily: fontProps.font.currentFont,
                      fontSize: fontProps.size.currentSize,
                      color: fontProps.color,
                    },
                  })
                }
              />
              <div css={styles.fontProps}>
                <TextTransformWidget
                  textTransform={buttonRoot.textTransform}
                  label="Letters"
                  onChange={(value) =>
                    updateCurrentTheme({
                      buttonRoot: { textTransform: value },
                    })
                  }
                />
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} css={styles.accordion}>
        <AccordionSummary expandIcon={<CaretDown />}>
          <div css={styles.titleBlock}>
            <Typography css={styles.title}>Buttons</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="category-item">
            <ButtonPropsWidget
              props={{
                borderRadius: buttonRoot.borderRadius,
                borderWidth: buttonRoot.borderWidth,
                borderColor: buttonRoot.borderColor,
                borderStyle: buttonRoot.borderStyle,
                background: buttonRoot.background,
              }}
              onChange={(props) => {
                updateCurrentTheme({
                  buttonProps: props,
                });
              }}
            />
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion elevation={0} css={styles.accordion}>
        <AccordionSummary expandIcon={<CaretDown />}>
          <div css={styles.titleBlock}>
            <Typography css={styles.title}>Containers</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <ColorPickerWidget
            title="Background color"
            alpha
            value={background}
            onChange={(value) =>
              updateCurrentTheme({ container: { background: value } })
            }
          />
        </AccordionDetails>
      </Accordion>

      <ToolbarCategory>
        <div css={styles.buttonBlock}>
          <Button
            data-testid="theme-apply-button"
            variant="contained"
            disabled={currentTheme.type === 'default'}
            css={styles.button}
            onClick={() => openDialog('update')}
            size="small"
          >
            Save
          </Button>
          <Button
            data-testid="theme-save-button"
            css={styles.button}
            variant="contained"
            onClick={() => openDialog('new')}
            size="small"
          >
            Save as
          </Button>
          <Button
            data-testid="theme-reset-button"
            css={styles.button}
            onClick={() => resetThemePreview(currentTheme.id)}
            size="small"
          >
            Reset
          </Button>
        </div>
      </ToolbarCategory>

      <ThemeDialog
        isOpen={isDialogOpen}
        onConfirm={confirmSave}
        onCancel={closeDialog}
        themeName={currentTheme.name}
        dialogType={dialogType}
      />
    </div>
  );
};

export default ThemeEditor;
