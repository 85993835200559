/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, Fragment, useRef, useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { DragPreviewImage } from 'react-dnd';
import {
  Envelope,
  Files,
  FileText,
  GameController,
  Trophy,
  XCircle,
} from 'phosphor-react';
import {
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  IPageRenameModel,
  PageElement,
  PageType,
} from '@brame/builder/src/reducers/builder';
import ConfirmationModal from '@brame/components/src/components/ConfirmationModal/ConfirmationModal';
import { usePageDrag } from '../hooks/dndHooks';
import image from '../../../assets/file.png';
import usePageRemoveModal from '../../../hooks/usePageRemoveModal';
import { useStyles } from './styles';
import CustomIcon from './CustomIcon';

export interface IProperties {
  page: PageElement;
  selected: boolean;
  hidden?: boolean;
  onSelectPage: (id: EntityId) => void;
  onRemovePage: (id: EntityId) => void;
  onRenamePage: (data: IPageRenameModel) => void;
  index: number;
}

const Page: FC<IProperties> = ({
  page,
  selected,
  hidden,
  onSelectPage,
  onRemovePage,
  onRenamePage,
  index,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme, selected);
  const [isEditingName, setIsEditingName] = useState(false);
  const [nameValue, setNameValue] = useState(page.name);
  const { isModalOpen, openConfirmationModal, closeConfirmationModal } =
    usePageRemoveModal();

  const pageTypeIcons = {
    [PageType.Basic]: (
      <CustomIcon
        Icon={FileText}
        data-testid="page-basic-icon"
        color={selected ? '#ffffff' : theme.palette.text.primary}
      />
    ),
    [PageType.Game]: (
      <CustomIcon
        Icon={GameController}
        data-testid="page-game-icon"
        color={selected ? '#ffffff' : theme.palette.text.primary}
      />
    ),
    [PageType.Outcome]: (
      <CustomIcon
        Icon={Trophy}
        data-testid="page-result-icon"
        color={selected ? '#ffffff' : theme.palette.text.primary}
      />
    ),
    [PageType.Terms]: (
      <CustomIcon
        Icon={Files}
        data-testid="page-terms-icon"
        color={selected ? '#ffffff' : theme.palette.text.primary}
      />
    ),
    [PageType.Email]: (
      <CustomIcon
        Icon={Envelope}
        data-testid="page-email-icon"
        color={selected ? '#ffffff' : theme.palette.text.primary}
      />
    ),
  };

  const ref = useRef<HTMLDivElement>(null);
  const { drag, dragPreview } = usePageDrag(page);

  drag(ref);

  const handleStartEditing = () => {
    if (selected) {
      setIsEditingName(true);
    }
  };

  const handleRemovePage = () => {
    closeConfirmationModal();
    onRemovePage(page.id);
  };

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      // @ts-ignore
      e.target.blur();
    }
    if (e.key === 'Enter') {
      if (nameValue) {
        await onRenamePage({ id: page.id, name: nameValue });
        // @ts-ignore
        e.target.blur();
      }
    }
  };

  const handleCancelEditing = () => {
    setIsEditingName(false);
    setNameValue(page.name);
  };

  return (
    <Fragment>
      <DragPreviewImage connect={dragPreview} src={image} />
      <div
        css={styles.container}
        data-testid="page-container"
        ref={ref}
        style={{
          display: hidden ? 'none' : 'flex',
        }}
      >
        <div
          css={styles.page}
          data-testid="page"
          onClick={() => onSelectPage(page.id)}
        >
          {pageTypeIcons[page.pageType]}
          <IconButton
            css={styles.removeIcon}
            size="small"
            aria-label="remove page"
            data-testid="remove-page-button"
            disabled={selected}
            onClick={openConfirmationModal}
          >
            <XCircle weight="fill" />
          </IconButton>
        </div>
        {page.name && (
          <Tooltip
            title={page.name}
            data-testid="page-tooltip"
            disableInteractive
          >
            {!isEditingName ? (
              <Typography
                variant="h4"
                css={styles.title}
                onClick={handleStartEditing}
              >
                {index} {page.name}
              </Typography>
            ) : (
              <div css={styles.inputWrapper}>
                <TextField
                  autoFocus
                  value={nameValue}
                  variant="standard"
                  size="small"
                  inputProps={{
                    fontSize: '15px',
                    sx: {
                      color: '#ffffff',
                      fontWeight: 700,
                      height: 20,
                      textAlign: 'center',
                    },
                  }}
                  onBlur={handleCancelEditing}
                  onKeyDown={handleKeyPress}
                  onChange={(e) => setNameValue(e.target.value)}
                />
              </div>
            )}
          </Tooltip>
        )}
      </div>

      <ConfirmationModal
        isOpen={isModalOpen}
        title="Removal confirmation"
        description="Do you really want to remove this page?"
        onConfirm={handleRemovePage}
        onCancel={closeConfirmationModal}
      />
    </Fragment>
  );
};

export default Page;
