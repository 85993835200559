import { css } from '@emotion/react';

export const useStyles = () => ({
  toggleButton: css`
    padding: 3px;
    transform: rotate(90deg);
    border: none;

    &.Mui-selected {
      background: none;
    }
  `,
  inputs: css`
    display: flex;
    gap: 10px;
  `,
});
