import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';

export const useStyles = () => ({
  subtitle: css`
    text-transform: capitalize;
    font-size: 14px;
    color: ${brameTheme.palette.text.primary};
  `,
  wrapper: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  `,
  galleryItem: css`
    border-radius: 5px;
    cursor: pointer;
  `,
  logo: css`
    object-fit: contain;
  `,
  video: css`
    width: 100%;
  `,
  image: css`
    object-fit: cover;
    position: absolute;
    inset: 0 0 0 0;
    width: 100%;
    height: 100%;
  `,
  block: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 8px 0;
    border-radius: 4px;
  `,
  imageFallback: css`
    width: 100%;
    height: 75px;
    position: relative;
    background: #dadada;
    background-size: cover;
    border-radius: ${brameTheme.shape.borderRadius}px;
  `,
  mediaPreviewGroup: css`
    padding: 10px 0;
  `,
  iconButtonContainer: css`
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  mediaContainer: css`
    display: flex;
    gap: 8px;
  `,
  mediaName: css`
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 100%;
    text-align: center;
    background: ${brameTheme.palette.primary.main};
    color: ${brameTheme.palette.primary.contrastText};
    font-size: 12px;
    z-index: 1;
    pointer-events: none;
  `,
  deleteIcon: css`
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 1;
  `,
  assetTiles: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  `,
  gameAssetTile: css`
    object-fit: contain;
  `,
});
