import { useAppSelector } from '../../reducers/app/hooks';

const useSelectedImageSize = () => {
  const selectedElementId = useAppSelector(
    ({ elements }) => elements.selectedElementId
  );

  const getRealImageSize = () => {
    if (selectedElementId) {
      const image = document.getElementById(
        selectedElementId?.toString()
      ) as HTMLImageElement;
      return {
        width: image?.naturalWidth,
        height: image?.naturalHeight,
      };
    }
  };

  return getRealImageSize();
};

export default useSelectedImageSize;
