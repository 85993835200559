import { css } from '@emotion/react';

export const useStyles = () => ({
  wrapper: css`
    display: flex;
    width: 100%;
    flex-direction: column;
  `,
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  checkbox: css`
    margin-right: 0;
  `,
});
