import { css } from '@emotion/react';

export const useStyles = () => ({
  container: css`
    display: flex;
    align-items: flex-end;
    padding-top: 8px;
  `,
  select: css`
    text-transform: capitalize;
  `,
});
