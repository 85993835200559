/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import { Container as MuiContainer } from '@mui/material';
import { AssetType } from '@brame/builder/src/types';
import { IContainerProps } from './IProps';
import { useStyles } from './styles';

const Container = React.forwardRef<HTMLDivElement, IContainerProps>(
  ({ children, element, currentTheme, elementStyles, ...props }, ref) => {
    const asset = elementStyles?.background?.asset;
    const imageUrl =
      elementStyles?.background?.image ||
      (asset &&
        (asset.type === AssetType.PHOTO || asset.type === AssetType.LOGO) &&
        asset.url);
    const videoUrl =
      elementStyles?.background?.videoUrl ||
      (asset && asset.type === AssetType.VIDEO && asset.url);

    const isThemedImage = elementStyles?.background?.themedImage;
    const isThemedVideo = elementStyles?.background?.themedVideo;
    //@ts-ignore
    const style = useStyles(element?.themedMedia?.url || imageUrl);

    return (
      <MuiContainer disableGutters ref={ref} {...props}>
        {(imageUrl || isThemedImage) && <div css={style.image} />}
        {(videoUrl || isThemedVideo) && (
          <video
            //@ts-ignore
            src={videoUrl || element?.themedMedia?.url}
            controls={false}
            autoPlay
            loop
            muted
            css={style.video}
          >
            Your browser does not support this element
          </video>
        )}
        {children}
      </MuiContainer>
    );
  }
);

export default Container;
