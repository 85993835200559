export const brameTheme = {
  palette: {
    primary: {
      main: '#88C559',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#666666',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#666666',
      secondary: '#666666',
    },
    divider: '#666666',
  },
  shape: {
    borderRadius: 5,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#333333',
          fontWeight: 400,
          fontSize: 13,
          fontFamily: ['Roboto', 'sans-serif'].join(','),
        },
        h1: {
          fontWeight: 400,
          fontSize: 24,
        },
        h2: {
          fontWeight: 400,
          fontSize: 22,
        },
        h3: {
          fontWeight: 400,
          fontSize: 18,
        },
        h4: {
          fontWeight: 400,
          fontSize: 16,
        },
        h5: {
          fontWeight: 400,
          fontSize: 14,
        },
        h6: {
          fontWeight: 400,
          fontSize: 13,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: 12,
          color: '#666666',
          borderRadius: 5,
        },
        notchedOutline: {
          borderColor: '#666666',
        },
        input: {
          padding: 8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 400,
        },
        outlined: {
          color: '#666666',
        },
        sizeMedium: {
          padding: '11px 8px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 8,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 12,
          color: '#666666',
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        content: {
          padding: '8px 28px',
          borderRadius: 4,
          marginBottom: 3,
        },
      },
    },
  },
};
