/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { IconButton } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useAppSelector } from '../../../../reducers/app/hooks';
import { PageElement } from '../../../../reducers/builder';
import { selectPages } from '../../../../reducers/builder/builderSlice';
import GameEnumEditor from '../../GameEnumEditor';
import GameNumberEditor from '../../GameNumberEditor';
import GameStringEditor from '../../GameStringEditor';
import { selectGameSettings } from '../../../../reducers/game/gameSlice';
import { dropGame } from '../utils';
import GameDefaultOutcomeEditor from '../../GameDefaultOutcomeEditor';
import { IDropGameOutcomeEditorProps } from './IProps';
import { useStyles } from './styles';

const DropGameOutcomeEditor: FC<IDropGameOutcomeEditorProps> = (props) => {
  const styles = useStyles();
  const pages = useAppSelector(selectPages);
  const gameState = useAppSelector(selectGameSettings);
  const pageNameArray: (string | undefined)[] = [];
  const pageIDArray: (string | undefined)[] = [];

  pages.ids.map((pageId) => {
    const page = pages.entities[pageId] as PageElement;
    pageNameArray.push(page.name);
    pageIDArray.push(page.id as string);
  });

  const handleRemove = () => {
    props.removeFunction(props.index);
  };
  if (gameState[props.name][props.index]['outcomeName'] !== 'Default') {
    return (
      <div css={styles.container}>
        <GameStringEditor
          name="outcomeName"
          styles={styles.container}
          index={props.index}
          arrayCategory={props.name}
          label="Name of the Outcome"
        />
        <GameNumberEditor
          name="condition.value"
          index={props.index}
          arrayCategory={props.name}
          label="Condition value"
        />
        <GameEnumEditor
          name="comparator"
          index={props.index}
          arrayCategory={props.name}
          label="Comparator"
          enums={dropGame.comparators}
          descs={dropGame.comparatorDescriptions}
        />
        <GameEnumEditor
          name="value.name"
          index={props.index}
          arrayCategory={props.name}
          label="Value name"
          enums={dropGame.values}
          descs={dropGame.valueDescriptions}
        />
        <GameEnumEditor
          name="page"
          index={props.index}
          arrayCategory={props.name}
          label="Page"
          enums={pageIDArray}
          descs={pageNameArray}
        />
        <IconButton onClick={handleRemove} css={styles.removeIcon}>
          <DeleteOutlinedIcon fontSize="small" />
        </IconButton>
      </div>
    );
  } else {
    return (
      <GameDefaultOutcomeEditor
        index={props.index}
        name={props.name}
        removeFunction={props.removeFunction}
      />
    );
  }
};
export default DropGameOutcomeEditor;
