/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC } from 'react';
import { Tooltip, Typography, useTheme } from '@mui/material';
import { useAppSelector } from '../../../reducers/app/hooks';
import { useStyles } from './styles';
import { ButtonVariant, getBtnStylePreset } from './utils';

interface IProps {
  title: string;
  onChange: (styles: any) => void;
}

const ButtonVariantSwitcher: FC<IProps> = ({ title, onChange }) => {
  const currentTheme = useAppSelector((state) => state.theme.currentTheme);
  const themeFallback = useAppSelector(
    (state) => state.theme.themeCollection[currentTheme.id]
  );
  const theme = useTheme();
  const styles = useStyles(theme);
  const handleChange = (variant: ButtonVariant) => {
    onChange(getBtnStylePreset(variant, themeFallback));
  };

  return (
    <div css={styles.widget}>
      {title && <Typography>{title}</Typography>}
      <div css={styles.itemsWrapper}>
        <Tooltip title="Text">
          <div
            css={styles.button}
            onClick={() => handleChange(ButtonVariant.TEXT)}
          >
            Text
          </div>
        </Tooltip>

        <Tooltip title="Contained">
          <div
            css={[styles.button, styles.contained]}
            onClick={() => handleChange(ButtonVariant.CONTAINED)}
          >
            Text
          </div>
        </Tooltip>
        <Tooltip title="Outlined">
          <div
            css={[styles.button, styles.outlined]}
            onClick={() => handleChange(ButtonVariant.OUTLINED)}
          >
            Text
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default ButtonVariantSwitcher;
