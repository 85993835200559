import { IContainerStyleProps } from '../../types';

export const fallbackProps: IContainerStyleProps = {
  size: {
    width: { value: 100, unit: '%' },
    height: { value: 150, unit: 'px' },
  },
  spacing: {
    margin: {
      top: { value: 0, unit: 'px' },
      right: { value: 0, unit: 'px' },
      bottom: { value: 0, unit: 'px' },
      left: { value: 0, unit: 'px' },
    },
    padding: {
      top: { value: 5, unit: 'px' },
      right: { value: 5, unit: 'px' },
      bottom: { value: 5, unit: 'px' },
      left: { value: 5, unit: 'px' },
    },
  },
  background: {
    color: '#FFFFFF00',
    image: '',
    videoUrl: '',
    asset: null,
  },
  internalAlignment: {
    textAlign: 'left',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'baseline',
  },
};
