/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { ColorPickerWidget } from '../ColorPickerWidget';
import InputWithSuffix from '../InputWithSuffix/InputWithSuffix';
import { IBoxShadow } from '../../types';
import { useStyles } from './styles';

interface IProps {
  widgetTitle?: string;
  boxShadow: IBoxShadow | null;
  onChange: (shadow: IBoxShadow | null) => void;
}

const defaultShadow = {
  offsetX: 5,
  offsetY: 5,
  blurRadius: 0,
  spreadRadius: 0,
  color: '#BCBCBC',
};

const BoxShadowWidget: FC<IProps> = ({ widgetTitle, boxShadow, onChange }) => {
  const styles = useStyles();
  const [checked, setChecked] = React.useState(!!boxShadow);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      onChange({ ...boxShadow, ...defaultShadow });
    } else {
      onChange(null);
    }
  };

  return (
    <div data-testid="shadow-widget">
      <FormControlLabel
        control={
          <Switch checked={checked} onChange={handleChange} name="shadow" />
        }
        label={widgetTitle || 'Shadow'}
      />

      {checked && boxShadow && (
        <div>
          <div css={styles.container}>
            <InputWithSuffix
              title="X-Axis"
              value={boxShadow?.offsetX}
              suffix="px"
              css={styles.input}
              onChange={(value) => onChange({ ...boxShadow, offsetX: value })}
            />
            <InputWithSuffix
              title="Y-Axis"
              value={boxShadow?.offsetY}
              suffix="px"
              css={styles.input}
              onChange={(value) => onChange({ ...boxShadow, offsetY: value })}
            />
          </div>
          <div css={styles.container}>
            <InputWithSuffix
              title="Blur"
              value={boxShadow?.blurRadius}
              suffix="px"
              min={0}
              css={styles.input}
              onChange={(value) =>
                onChange({ ...boxShadow, blurRadius: value })
              }
            />
            <InputWithSuffix
              title="Spread"
              value={boxShadow?.spreadRadius}
              suffix="px"
              css={styles.input}
              onChange={(value) =>
                onChange({ ...boxShadow, spreadRadius: value })
              }
            />
          </div>

          <ColorPickerWidget
            title="Shadow Color"
            alpha
            value={boxShadow?.color}
            onChange={(value) => onChange({ ...boxShadow, color: value })}
          />
        </div>
      )}
    </div>
  );
};
export default BoxShadowWidget;
