import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';

export const useStyles = () => ({
  popper: css`
    top: 30px !important;

    & > div {
      border-radius: ${brameTheme.shape.borderRadius}px;
      box-shadow: 0 0 20px rgba(174, 174, 174, 0.3);
      background: #ffffff;
      padding: 20px;
      display: flex;
      gap: 15px;
      flex-direction: column;
    }
  `,
  popperBox: css`
    display: flex;
    gap: 8px;
  `,
  flexEnd: css`
    justify-content: flex-end;
  `,
  popperInput: css`
    width: 100px;
  `,
  formatsContainer: css`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 25px;
  `,
  formatsBox: css`
    display: flex;
    gap: 10px;
  `,
});
