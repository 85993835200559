/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { FC, MouseEvent } from 'react';
import { Typography } from '@mui/material';
import { images } from '@brame/components/src/assets/images';
import { AssetElements, AssetType, IAssetData } from '../../types';
import { BuilderComponent } from '../../reducers/builder';
import { useStyles } from './styles';

interface IProps {
  asset: IAssetData | null;
  src?: string;
  type: AssetElements;
  onClick: (e: MouseEvent) => void;
}

const getLabel = (type: AssetElements): string => {
  switch (type) {
    case BuilderComponent.IMAGE:
      return 'Select image';
    case BuilderComponent.VIDEO:
      return 'Select video';
    case BuilderComponent.CONTAINER:
    default:
      return 'Select background';
  }
};

const AssetPlaceholder: FC<IProps> = ({ asset, src, type, onClick }) => {
  const styles = useStyles();
  const label = getLabel(type);
  const url = src || asset?.urlSmall || asset?.url;

  const isImageElement = type === BuilderComponent.IMAGE;
  const isVideoElement = type === BuilderComponent.VIDEO;

  const isContainerEmptyBg = type === BuilderComponent.CONTAINER && !asset;
  const isContainerImageBg =
    type === BuilderComponent.CONTAINER &&
    (asset?.type === AssetType.PHOTO || asset?.type === AssetType.LOGO);
  const isContainerVideoBg =
    type === BuilderComponent.CONTAINER && asset?.type === AssetType.VIDEO;

  return (
    <div onClick={onClick} css={styles.placeholderContainer}>
      <Typography css={styles.placeholderLabel}>{label}</Typography>
      {(isImageElement || isContainerEmptyBg || isContainerImageBg) && (
        <img
          src={url || images.fallback_clear}
          alt={asset?.name}
          css={styles.placeholder}
        />
      )}
      {(isVideoElement || isContainerVideoBg) && (
        <video
          src={url}
          poster={url ? undefined : images.fallback_clear}
          css={styles.placeholder}
          autoPlay={false}
          muted
        />
      )}
    </div>
  );
};

export default AssetPlaceholder;
