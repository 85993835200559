import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';

interface IProps {
  isHovered?: boolean;
  isExpanded?: boolean;
}

export const useStyles = ({ isHovered, isExpanded }: IProps) => ({
  title: css`
    text-transform: capitalize;
    font-size: 16px;
    color: ${brameTheme.palette.text.primary};
    font-weight: ${isHovered || isExpanded ? 700 : 400};
  `,
  titleBlock: css`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  `,
});
