import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';

export const useStyles = () => ({
  category: css`
    margin: 15px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  `,
  title: css`
    text-transform: capitalize;
    margin-bottom: 8px;
    font-size: 16px;
    color: ${brameTheme.palette.text.primary};
    font-weight: 700;
  `,
});
