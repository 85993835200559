import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const useStyles = (theme: Theme) => ({
  widget: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  itemsWrapper: css`
    display: flex;
    gap: 16px;
  `,
  button: css`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 0 10px;
    cursor: pointer;
  `,
  contained: css`
    background: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
  `,
  outlined: css`
    border: 1px solid ${theme.palette.text.primary};
  `,
});
