import React, { FC } from 'react';
import { IconButton, Snackbar } from '@mui/material';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';

export interface IProps {
  text: string;
  type?: AlertColor;
  open: boolean;
  handleClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
}

const CustomAlert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Alert: FC<IProps> = ({ open, text, type, handleClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleClose}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    >
      <CustomAlert
        onClose={handleClose}
        severity={type || 'success'}
        sx={{ width: '100%' }}
      >
        {text}
      </CustomAlert>
    </Snackbar>
  );
};

export default Alert;
