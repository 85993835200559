/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { AppBar, Toolbar } from '@mui/material';
import { Breakpoints } from '../Breakpoints';
import { Breakpoint } from '../../reducers/shared/types';

const PreviewToolbar: FC<{
  breakpoints: Breakpoint[];
  activeBreakpoint: Breakpoint;
  onSelect: (id: string) => void;
}> = ({ breakpoints, activeBreakpoint, onSelect }) => {
  return (
    <AppBar
      position="sticky"
      sx={{
        background: '#fff',
        display: 'flex',
        justifyCcontent: 'center',
        alignItems: 'center',
      }}
      data-testid="toolbar"
    >
      <Toolbar>
        <Breakpoints
          breakpoints={breakpoints}
          activeBreakpoint={activeBreakpoint}
          onSelect={onSelect}
        />
      </Toolbar>
    </AppBar>
  );
};

export default PreviewToolbar;
