import Phaser from 'phaser';
import DropGameScene from './DropGameScene';

export function updateParallax(delta: number, scene: DropGameScene) {
  if (scene.parallaxOn) {
    for (const entry in scene.parallaxBackgrounds) {
      scene.parallaxBackgrounds[entry].tilePositionX -=
        (scene.parallaxSpeeds[entry].x * scene.gameWidth * delta) / 1000;
      scene.parallaxBackgrounds[entry].tilePositionY -=
        (scene.parallaxSpeeds[entry].y * scene.gameHeight * delta) / 1000;
    }
  }
}

export function initParallax(scene: DropGameScene) {
  if (DropGameScene.jsonLink['visual.parallax']) {
    scene.parallaxOn = true;
    for (const entry in DropGameScene.jsonLink['visual.parallaxSettings']) {
      scene.parallaxBackgrounds.push(
        scene.add
          .tileSprite(
            0,
            0,
            scene.gameWidth,
            scene.gameHeight,
            DropGameScene.jsonLink['visual.parallaxSettings'][entry].textureKey
          )
          .setOrigin(0)
          .setDepth(-1)
          .setTileScale(
            scene.gameWidth /
              scene.textures
                .get(
                  DropGameScene.jsonLink['visual.parallaxSettings'][entry]
                    .textureKey
                )
                .get(0).width,
            scene.gameHeight /
              scene.textures
                .get(
                  DropGameScene.jsonLink['visual.parallaxSettings'][entry]
                    .textureKey
                )
                .get(0).height
          )
      );
      scene.parallaxSpeeds.push(
        new Phaser.Math.Vector2(
          DropGameScene.jsonLink['visual.parallaxSettings'][entry].scrollSpeedX,
          DropGameScene.jsonLink['visual.parallaxSettings'][entry].scrollSpeedY
        )
      );
    }
  } else {
    let resultColor: string;
    const backgroundColor = DropGameScene.jsonLink['game.backgroundColor'];
    // Case with alpha
    resultColor =
      'rgba(' +
      (parseInt(backgroundColor.charAt(1), 16) * 16 +
        parseInt(backgroundColor.charAt(2), 16)) +
      ',' +
      (parseInt(backgroundColor.charAt(3), 16) * 16 +
        parseInt(backgroundColor.charAt(4), 16)) +
      ',' +
      (parseInt(backgroundColor.charAt(5), 16) * 16 +
        parseInt(backgroundColor.charAt(6), 16)) +
      ',';

    const value =
      (parseInt(backgroundColor.charAt(7), 16) * 16 +
        parseInt(backgroundColor.charAt(8), 16)) /
      255;
    let alphaEnd = value + ')';
    if (!value) {
      alphaEnd = '0)';
    }
    resultColor += alphaEnd;
    scene.cameras.main.setBackgroundColor(resultColor);
  }
}
