import { useEffect, useState } from 'react';
import { WizzardType } from '../CampaignType/tabsData';

export const useProgressBar = (activeType: string) => {
  const [progressBarValue, setProgressBarValue] = useState<number>(0);

  useEffect(() => {
    defineProgressBarValue(activeType);
  }, [activeType]);

  const defineProgressBarValue = (activeType: string) => {
    switch (activeType) {
      case WizzardType.CAMPAIGN_TYPE:
        return setProgressBarValue(25);
      case WizzardType.CAMPAIGN_DETAILS:
        return setProgressBarValue(50);
      case WizzardType.CAMPAIGN_THEME:
        return setProgressBarValue(75);
      case WizzardType.CAMPAIGN_TEMPLATE:
        return setProgressBarValue(100);
      default:
        return setProgressBarValue(0);
    }
  };

  return { progressBarValue };
};
