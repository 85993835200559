import Phaser from 'phaser';
import PhaserAssetLoader from '../../PhaserAssetLoader';

import SlotSprite from './SlotSprite';

import { SlotMachineSettingsInterface } from '../../../../../../builder/src/components/GameEditors/SlotMachine/StateDefaults/SlotMachineInterface';
import { calculateScale, convertISizingToPX } from '../../ScalingManager';
import {
  initState,
  registerPhaserFunction,
  stateParams,
  updateStoreValue,
} from '../PhaserGameHooks';
import { dispatchPageChange } from '../../../components/GameOutcomeDispatcher/GameOutcomeDispatcher';

interface SlotRNGPOD {
  name: string;
  texName: string;
  slotIndex: number;
  probabilty: number;
  scaling: Phaser.Math.Vector2;
  outcomeUUID: string;
  iconUUID: string;
}

export default class SlotMachineScene extends Phaser.Scene {
  constructor() {
    super('SlotMachineScene');
  }

  public static jsonLink: SlotMachineSettingsInterface;
  private static instance: SlotMachineScene;

  public slotTexture!: Phaser.GameObjects.RenderTexture;
  public tileSprites!: Phaser.GameObjects.TileSprite[];
  public slotSprites!: Phaser.GameObjects.Sprite[];
  public shellSprite!: Phaser.GameObjects.Sprite;
  public slotTexSprite!: Phaser.GameObjects.Sprite;
  public slots: SlotSprite[] = [];
  public resultPos!: number[];
  public gameWidth!: number;
  public gameHeight!: number;
  public iconCount!: number;
  public iconSize!: number;
  public slotCount!: number;
  public slotsProbs = [] as SlotRNGPOD[];
  public probTotal!: number;
  public spinning = false;

  // Particles
  public particlesManager!: Phaser.GameObjects.Particles.ParticleEmitterManager;

  public static getInstance(): SlotMachineScene {
    return SlotMachineScene.instance;
  }

  public static setInstance(scene: SlotMachineScene) {
    SlotMachineScene.instance = scene;
  }

  preload() {
    this.gameWidth = this.game.canvas.width;
    this.gameHeight = this.game.canvas.height;

    PhaserAssetLoader(SlotMachineScene.jsonLink.assets, this);

    const startState: stateParams[] = [{ storeKey: 'spinning', value: false }];

    initState(startState);
    registerPhaserFunction({
      id: 'spin',
      args: [this],
      function: spin,
    });
  }

  create() {
    // Set consts
    this.iconCount = SlotMachineScene.jsonLink['game.icons'].length;
    this.iconSize = SlotMachineScene.jsonLink['game.iconSize'];
    this.slotCount = SlotMachineScene.jsonLink['game.slots'].length;
    // Common Code
    this.tileSprites = [];
    this.slotSprites = [];
    this.resultPos = [];
    this.probTotal = 0.0;
    for (let i = 0; i < this.iconCount; ++i) {
      const texName = SlotMachineScene.jsonLink['game.icons'][i].textureKey;
      const prizeName = SlotMachineScene.jsonLink['game.icons'][i].name;
      const iconUUID = SlotMachineScene.jsonLink['game.icons'][i].uuid;
      const outcomeUUID =
        SlotMachineScene.jsonLink['game.icons'][i].outcomeUUID;
      this.probTotal += SlotMachineScene.jsonLink['game.icons'][i].probability;

      const iconW = this.textures.get(texName).get(0).width;
      const iconH = this.textures.get(texName).get(0).height;
      this.slotSprites.push(
        this.add
          .sprite(0, 0, SlotMachineScene.jsonLink['game.icons'][i].textureKey)
          .setVisible(false)
          .setOrigin(0.5, 0)
          .setScale(this.iconSize / iconW, this.iconSize / iconH)
      );
      this.slotsProbs.push({
        name: prizeName,
        texName: texName,
        probabilty: this.probTotal,
        slotIndex: i,
        scaling: new Phaser.Math.Vector2(
          this.iconSize / iconW,
          this.iconSize / iconH
        ),
        outcomeUUID: outcomeUUID,
        iconUUID: iconUUID,
      });
    }

    // Setup particles
    this.particlesManager = this.add.particles('').setDepth(4);

    // Setup Machine
    const hullW = this.textures
      .get(SlotMachineScene.jsonLink['game.hullTex'])
      .get(0).width;
    const hullH = this.textures
      .get(SlotMachineScene.jsonLink['game.hullTex'])
      .get(0).height;

    const hullPixelValues = convertISizingToPX(
      SlotMachineScene.jsonLink['game.hullSize'],
      this.gameWidth,
      this.gameHeight
    );

    const hullScale = calculateScale(
      hullW,
      hullH,
      hullPixelValues.x,
      hullPixelValues.y
    );

    const hullPixelPosition = convertISizingToPX(
      SlotMachineScene.jsonLink['game.hullPos'],
      this.gameWidth,
      this.gameHeight
    );

    this.shellSprite = this.add
      .sprite(
        hullPixelPosition.x,
        hullPixelPosition.y,
        SlotMachineScene.jsonLink['game.hullTex']
      )
      .setDepth(1)
      .setVisible(true)
      .setScale(hullScale.x, hullScale.y);
    // Slot frame
    const frameW = this.textures
      .get(SlotMachineScene.jsonLink['game.frameTex'])
      .get(0).width;
    const frameH = this.textures
      .get(SlotMachineScene.jsonLink['game.frameTex'])
      .get(0).height;
    const framePixelValues = convertISizingToPX(
      SlotMachineScene.jsonLink['game.frameSize'],
      this.gameWidth,
      this.gameHeight
    );

    const frameScale = calculateScale(
      frameW,
      frameH,
      framePixelValues.x,
      framePixelValues.y
    );

    const framePixelPosition = convertISizingToPX(
      SlotMachineScene.jsonLink['game.framePos'],
      this.gameWidth,
      this.gameHeight
    );

    this.slotTexSprite = this.add
      .sprite(
        framePixelPosition.x,
        framePixelPosition.y,
        SlotMachineScene.jsonLink['game.frameTex']
      )
      .setDepth(3)
      .setVisible(true)
      .setScale(frameScale.x, frameScale.y);
    // Setup mask
    var shape = this.add.graphics().setVisible(false);
    shape.fillStyle(0xffffff);
    shape.fillRect(
      this.slotTexSprite.x - this.slotTexSprite.displayWidth / 2,
      this.slotTexSprite.y - this.slotTexSprite.displayHeight / 2,
      this.slotTexSprite.displayWidth,
      this.slotTexSprite.displayHeight
    );

    var mask = shape.createGeometryMask();
    // Setup icons in slots
    this.slotTexture = this.add
      .renderTexture(0, 0, this.iconSize, this.iconSize * this.iconCount)
      .setOrigin(0.5)
      .setVisible(false);
    // Draw on Texture
    for (let i = 0; i < this.iconCount; ++i) {
      this.slotTexture.draw(
        this.slotSprites[i],
        this.iconSize / 2,
        i * this.iconSize
      );
      this.resultPos.push(this.iconSize * (i - Math.floor(this.iconCount / 2)));
    }
    let tex = this.slotTexture.saveTexture('slot');
    // Setup slots
    for (let entry in SlotMachineScene.jsonLink['game.slots']) {
      let slot = SlotMachineScene.jsonLink['game.slots'][entry];
      this.slots.push(
        new SlotSprite(
          this,
          tex,
          this.iconCount,
          this.iconSize,
          slot.frameAnchor,
          slot.spinCount,
          slot.spinDuration,
          framePixelPosition,
          framePixelValues
        )
      );
      this.slots[parseInt(entry)].setMask(mask);
    }
  }

  public updateValue(valueID: string, value: any) {
    switch (valueID) {
      case 'spinning':
        this.spinning = value;
        break;
    }
    updateStoreValue(valueID, value);
  }

  resetSlots() {
    for (let entry in this.slots) {
      this.slots[entry].reset();
    }
  }

  update() {}
}

export function spinToIndex(iconID: string) {
  let scene = SlotMachineScene.getInstance();
  if (scene.spinning) return;
  scene.resetSlots();
  scene.updateValue('spinning', true);
  const reward = scene.slotsProbs.find(({ iconUUID }) => iconUUID === iconID);
  if (reward) {
    // spin to that index
    for (let slotI in scene.slots) {
      scene.slots[slotI].spin(reward.slotIndex);
    }
    scene.slots[2].setOnCompleteCallback(() => {
      scene.particlesManager.setTexture(reward.texName);
      let particleEmitter = scene.particlesManager.createEmitter({
        speedX: { min: -30, max: 30 },
        speedY: { min: 100, max: 500 },
        angle: { min: 0, max: 180 },
        scaleX: reward.scaling.x,
        scaleY: reward.scaling.y,
        lifespan: { min: 2000, max: 2500 },
        alpha: { start: 0.9, end: 0, ease: 'Quint.easeIn' },
        x: { min: 0, max: scene.gameWidth },
        y: { min: -100, max: 0 },
        maxParticles: 45,
        frequency: 50,
        blendMode: 'NORMAL',
      });
      scene.updateValue('spinning', false);
      setTimeout(() => {
        if (reward.outcomeUUID) {
          dispatchPageChange(
            SlotMachineScene.jsonLink['outcomes'],
            reward.outcomeUUID
          );
        } else {
          dispatchPageChange(
            SlotMachineScene.jsonLink['outcomes'],
            'DefaultOutcome'
          );
        }
      }, 2500);
    });
  } else {
    loseSpin();
  }
}

export function loseSpin() {
  let scene = SlotMachineScene.getInstance();
  if (scene.spinning) return;
  scene.resetSlots();
  scene.slots[2].setOnCompleteCallback(() => {});
  let lastIndex = 0;
  for (let slotI in scene.slots) {
    const randIndex = Math.floor(Math.random() * scene.iconCount);
    if (lastIndex !== randIndex) {
      scene.slots[slotI].spin(randIndex);
    } else {
      scene.slots[slotI].spin(randIndex - 1);
    }
    lastIndex = randIndex;
  }
}

export function spin(scene: SlotMachineScene) {
  const prob = Math.random() * scene.probTotal;
  for (let entry in scene.slotsProbs) {
    if (scene.slotsProbs[entry].probabilty > prob) {
      spinToIndex(scene.slotsProbs[entry].iconUUID);
      return;
    }
  }
  loseSpin();
}
