import { useEffect, useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { Components } from '@mui/material';
import { aggregateThemeOptions } from '@brame/theme';
import { IElementStyleProps } from '@brame/builder/src/types';
import { RootState } from '@brame/builder/src/reducers/store';
import {
  setCurrentTheme,
  resetCurrentTheme,
} from '@brame/builder/src/reducers/builder/themeSlice';
import { fetchInitialThemeState } from '@brame/builder/src/reducers/builder/asyncThunks';
import {
  useAppDispatch,
  useAppSelector,
} from '@brame/builder/src/reducers/app/hooks';
import { selectAuth } from '@brame/builder/src/reducers/auth/authSlice';
import { setSelectedTheme } from '@brame/builder/src/reducers/builder/builderSlice';
import { fontSizes, uniqueFontFamilies } from '../assets/fonts';
import { saveExistingTheme, saveNewTheme } from '../services';

const useThemeData = () => {
  const theme = useAppSelector((state: RootState) => state.theme);
  const authState = useAppSelector(selectAuth);
  const chosenTheme = theme.chosenTheme;
  const currentTheme = theme.currentTheme;
  const themeCollection = theme.themeCollection;
  const [fontFamilies, setFontFamilies] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const currentCompany = authState.parsedToken.company_id!;

  const saveCurrentTheme = async () => {
    await saveExistingTheme(theme.currentTheme);
    dispatch(fetchInitialThemeState(currentCompany));
  };

  const createNewTheme = async (name: string) => {
    await saveNewTheme({ ...currentTheme, name });
    dispatch(fetchInitialThemeState(currentCompany));
  };

  const setCurrentThemeById = (id: string) => {
    dispatch(setCurrentTheme(theme.themeCollection[id]));
  };

  const resetThemePreview = (id: EntityId): void => {
    dispatch(resetCurrentTheme(id));
  };

  const applyTheme = (themeId: string): void => {
    dispatch(setSelectedTheme(themeId));
  };

  const updateCurrentTheme = (elementStyles: IElementStyleProps): void => {
    dispatch(
      setCurrentTheme(aggregateThemeOptions({ currentTheme, elementStyles }))
    );
  };

  const getThemedStylingValues = (
    element: keyof Components,
    subElement?: string
  ) => {
    const root =
      // @ts-ignore
      currentTheme?.theme?.components?.[element]?.styleOverrides?.[
        subElement || 'root'
      ];

    return {
      fontFamily: root?.fontFamily,
      fontWeight: root?.fontWeight,
      fontSize: root?.fontSize,
      borderRadius: { value: root?.borderRadius, unit: 'px' },
      borderWidth: { value: root?.borderWidth, unit: 'px' },
      borderStyle: root?.borderStyle,
      borderColor: root?.borderColor,
      color: root?.color,
      textTransform: root?.textTransform,
      background: root?.background,
      textAlign: root?.textAlign,
    };
  };

  useEffect(() => {
    setFontFamilies(uniqueFontFamilies);
  }, [uniqueFontFamilies]);

  return {
    currentTheme,
    createNewTheme,
    setCurrentThemeById,
    saveCurrentTheme,
    updateCurrentTheme,
    applyTheme,
    resetThemePreview,
    fontFamilies,
    fontSizes,
    themeCollection,
    chosenTheme,
    getThemedStylingValues,
  };
};

export default useThemeData;
