/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC, useState } from 'react';
import { Typography } from '@mui/material';
import { ILibrarySection } from '../../../reducers/builder';
import { useStyles } from './styles';

interface IProps {
  section: ILibrarySection;
  expanded?: boolean;
}

const LibrarySectionTitle: FC<IProps> = ({ section, expanded }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const styles = useStyles({ isHovered, isExpanded: expanded });

  return (
    <div
      css={styles.titleBlock}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {!expanded && section.icon(isHovered ? 'light' : 'thin')}
      <Typography css={styles.title}>{section.title}</Typography>
    </div>
  );
};

export default LibrarySectionTitle;
