import { css } from '@emotion/react';

export const useStyles = () => ({
  error: css`
    padding: 7px;
    color: #721c24;
    background: #f8d7da;
    text-align: center;
    border-radius: 10px;
    max-height: 40px;
    margin: 20px 10px;
  `,
});
