import { createSlice } from '@reduxjs/toolkit';
import { fetchInitialStateAsync } from './asyncThunks';

const initialState = {
  enableAnalytics: false,
  nameSpace: '',
  collectorUrl: '',
  appId: '',
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setAnalyticsTracking: (state, action) => {
      state.enableAnalytics = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInitialStateAsync.fulfilled, (state, action) => {
      const data = action.payload.data.data;
      state.enableAnalytics = data.enableAnalytics;
    });
  },
});

export const { setAnalyticsTracking } = analyticsSlice.actions;

export default analyticsSlice.reducer;
