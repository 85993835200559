/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useEffect } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { AppBar, Tab, Tabs, useTheme } from '@mui/material';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import { PageElement } from '@brame/builder/src/reducers/builder';
import { TabPanel, useTabs } from '../TabPanel';
import { useStyles } from './styles';
import { PageNavigator } from './PageNavigator';

export interface IPages {
  entities: { [key: string]: PageElement };
  ids: EntityId[];
}

export interface IProperties {
  pages: IPages;
  additionalPages: IPages;
  currentPageId: EntityId | null;
  currentPageIsAdditional: boolean;
  onSelectPage: (id: EntityId) => void;
}

const PreviewFooter: FC<IProperties> = ({
  pages,
  additionalPages,
  currentPageId,
  currentPageIsAdditional,
  onSelectPage,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const { tabIndex, setTabIndex, handleTabChange } = useTabs(0);
  const regularPages = pages.ids.map((id) => pages.entities[id]);
  const otherPages = additionalPages.ids.map(
    (id) => additionalPages.entities[id]
  );

  useEffect(() => {
    setTabIndex(currentPageIsAdditional ? 1 : 0);
  }, [currentPageIsAdditional]);

  return (
    <AppBar position="sticky" color="primary" sx={{ top: 'auto', bottom: 0 }}>
      <footer css={styles.previewFooter} data-testid="previewFooter">
        <TabsUnstyled css={styles.tabsContainer} defaultValue={0}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            css={styles.tabs}
            component="div"
          >
            <Tab css={styles.tab} label="Regular pages" disableRipple />
            <Tab css={styles.tab} label="Additional pages" disableRipple />
          </Tabs>
          <TabPanel value={tabIndex} noPadding index={0}>
            <PageNavigator
              pages={regularPages}
              currentPageId={currentPageId}
              onSelectPage={onSelectPage}
            />
          </TabPanel>
          <TabPanel value={tabIndex} noPadding index={1}>
            <PageNavigator
              pages={otherPages}
              currentPageId={currentPageId}
              onSelectPage={onSelectPage}
            />
          </TabPanel>
        </TabsUnstyled>
      </footer>
    </AppBar>
  );
};

export default PreviewFooter;
