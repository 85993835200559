/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { AppBar, Toolbar } from '@mui/material';
import { Toolbar as ControlBar } from '../Toolbar';
import { ReactComponent as Logo } from '../../assets/logo/logo_green.svg';
import { useStyles } from './styles';

const Header: FC = () => {
  const styles = useStyles();

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      data-testid="header"
    >
      <Toolbar css={styles.toolBar}>
        <Logo style={{ marginRight: '1em' }} data-testid="brame-logo" />
      </Toolbar>
      <ControlBar />
    </AppBar>
  );
};

export default Header;
