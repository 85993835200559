/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { Form, Formik, FormikValues } from 'formik';
import { Check, X } from 'phosphor-react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  FormFieldType,
  IFormField,
} from '@brame/components/src/components/Form';
import { useFormEditorStyles } from '../styles';
import { ButtonActionType } from '../../../reducers/builder';
import { RequiredCheckbox } from './RequiredCheckbox';
import { useStyles } from './styles';
import { EditNumber } from './EditNumber';
import { EditTextarea } from './EditTextarea';
import { EditSelect } from './EditSelect';
import { NumberValidationType } from './EditNumber/EditNumber';
import { getMask } from './helpers';
import { EditTerms } from './EditTerms';

interface IProps {
  field: IFormField;
  onClose: () => void;
  onChange: (field: IFormField) => void;
}

const EditField: FC<IProps> = ({ field, onClose, onChange }) => {
  const styles = useStyles();
  const formStyles = useFormEditorStyles();
  const formValues: FormikValues = {
    ...field,
    ...field.validation,
    actionType: field.action?.type,
    actionTarget: field.action?.target,
    required: field.validation.required || false,
    options: field.validation.options || [],
    newOption: '',
    numberValidationType:
      field.validation.minCharacters !== undefined ||
      field.validation.maxCharacters !== undefined
        ? NumberValidationType.Mask
        : NumberValidationType.Range,
  };
  const fieldTypes = Object.values(FormFieldType);

  const submitValues = (values: FormikValues): void => {
    const newField = {
      ...field,
      value: values.type === FormFieldType.MultiSelect ? [] : field.value,
      type: values.type,
      label: values.label,
      validation: {
        ...field.validation,
        errorMessage: values.errorMessage,
        required: values.required,
        min: values.min,
        max: values.max,
        minCharacters: values.minCharacters,
        maxCharacters: values.maxCharacters,
        options: values.options,
        rows: values.rows,
      },
      action: {
        type: values.actionType,
        target: values.actionTarget,
        openNewTab: values.actionType === ButtonActionType.LINK || null,
      },
    } as IFormField;

    newField.validation.mask = getMask(newField);

    onChange(newField);
    onClose();
  };

  return (
    <div data-testid="edit-field" key={field.name}>
      <Formik
        initialValues={formValues}
        onSubmit={submitValues}
        enableReinitialize
      >
        {(props) => (
          <Form>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                paddingTop: '8px',
              }}
            >
              <FormControl fullWidth>
                <Typography css={formStyles.label}>Field type</Typography>
                <Select
                  css={styles.select}
                  id="type"
                  name="type"
                  value={props.values.type}
                  onChange={props.handleChange}
                  disabled={!field?.isCustom}
                >
                  {fieldTypes.map((option) => (
                    <MenuItem value={option} key={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton
                color="secondary"
                edge="end"
                aria-label="edit"
                style={{
                  marginRight: 0,
                  marginTop: '-8px',
                  marginLeft: '12px',
                }}
                onClick={onClose}
              >
                <X size={16} />
              </IconButton>
            </Box>
            <Box css={styles.container}>
              <FormControl fullWidth>
                <Typography css={formStyles.label}>Label</Typography>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="label"
                  name="label"
                  value={props.values.label}
                  onChange={props.handleChange}
                />
              </FormControl>
              <FormGroup>
                <FormControlLabel
                  sx={{ margin: '0 -9px -9px 0' }}
                  control={
                    <RequiredCheckbox
                      checked={props.values.required}
                      onChange={(value) =>
                        props.setFieldValue('required', value.target.checked)
                      }
                      name="required"
                      id="required"
                    />
                  }
                  label=""
                />
              </FormGroup>
            </Box>
            {props.values.type === FormFieldType.Number && (
              <EditNumber props={props} />
            )}
            {props.values.type === FormFieldType.Textarea && (
              <EditTextarea props={props} />
            )}
            {props.values.type === FormFieldType.Checkbox &&
              props.values.isTermsAndConditions && <EditTerms props={props} />}
            {(props.values.type === FormFieldType.SingleSelect ||
              props.values.type === FormFieldType.MultiSelect) && (
              <EditSelect props={props} />
            )}
            <Box css={styles.container}>
              <FormControl fullWidth>
                <Typography css={formStyles.label}>Error message</Typography>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="errorMessage"
                  name="errorMessage"
                  size="small"
                  value={props.values.errorMessage}
                  onChange={props.handleChange}
                />
              </FormControl>
              <button type="submit" css={formStyles.actionButton}>
                <Check size={24} weight="light" />
              </button>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditField;
