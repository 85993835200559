/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, Global } from '@emotion/react';
import React, { FC, Fragment } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { Resizable } from 're-resizable';
import { ThemeProvider, createTheme } from '@mui/material';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import { StructureLayout } from '@brame/components';
import { useRunnerNavigation } from '@brame/components/src/hooks/useRunnerNavigation';
import { brameTheme, expandThemeWithFonts } from '@brame/theme';
import { goToPage } from '@brame/components/src/slices/pageSlice';
import { PreviewToolbar } from '../../components/PreviewToolbar';
import { PreviewFooter } from '../../components/PreviewFooter';
import { useAppDispatch } from '../../reducers/app/hooks';
import { useCampaignData } from './hooks/useCampaignData';
import { useThemeData } from './hooks/useThemeData';
import { breakpoints, defaultBreakpoint } from './constants';
import { useStyles } from './style';
import { useBreakpoints } from './hooks/useBreakpoints';
import { IFrame } from './IFrame';
import ResizeHandle from './ResizeHandle';

const Preview: FC = () => {
  const dispatch = useAppDispatch();
  const { chosenTheme } = useThemeData();
  const { pages, additionalPages, elements, gameData } = useCampaignData();
  const { currentPage, currentPageIsAdditional, actionsMap } =
    useRunnerNavigation(pages?.ids, additionalPages?.ids);
  const { activeBreakpoint, onSelectHandler, handleResize } =
    useBreakpoints(defaultBreakpoint);

  const styles = useStyles();
  const rootEntityId: EntityId | null = currentPage
    ? (currentPageIsAdditional ? additionalPages : pages).entities[currentPage]
        .elementRoot
    : null;

  if (!rootEntityId || !elements || !chosenTheme) return null;

  const handleSelectPage = (id: EntityId) => {
    dispatch(goToPage(id));
  };

  return (
    <div css={styles.previewPage}>
      <ThemeProvider theme={createTheme(brameTheme)}>
        <PreviewToolbar
          breakpoints={breakpoints}
          activeBreakpoint={activeBreakpoint || defaultBreakpoint}
          onSelect={onSelectHandler}
        />
      </ThemeProvider>
      <div css={styles.resizableContainer}>
        <Resizable
          maxWidth="100%"
          enable={{
            top: false,
            right: true,
            bottom: false,
            left: true,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          size={{
            //+ sum of the padding width
            width: activeBreakpoint.breakpoint + 'px',
            height: '920px',
          }}
          css={styles.resizable}
          onResize={handleResize}
          handleComponent={{
            right: <ResizeHandle side="right" />,
            left: <ResizeHandle side="left" />,
          }}
        >
          <div css={styles.resizableWrapper}>
            <div css={styles.layoutWrapper}>
              <ThemeProvider
                theme={
                  // @ts-ignore
                  createTheme(expandThemeWithFonts(chosenTheme))
                }
              >
                <IFrame name="preview-iframe" id="preview-iframe">
                  <Fragment>
                    <Global
                      styles={css`
                        body {
                          margin: 0;
                          background-color: #fff;
                        }

                        body > div {
                          height: 100%;
                        }
                      `}
                    />
                    <ScopedCssBaseline hidden />
                    <StructureLayout
                      rootEntityId={rootEntityId}
                      elements={elements}
                      actions={actionsMap}
                      gameData={gameData}
                      theme={chosenTheme}
                    />
                  </Fragment>
                </IFrame>
              </ThemeProvider>
            </div>
          </div>
        </Resizable>
      </div>
      <ThemeProvider theme={createTheme(brameTheme)}>
        <PreviewFooter
          pages={pages}
          additionalPages={additionalPages}
          currentPageId={currentPage}
          currentPageIsAdditional={currentPageIsAdditional}
          onSelectPage={handleSelectPage}
        />
      </ThemeProvider>
    </div>
  );
};

export default Preview;
