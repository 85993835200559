import axios, { AxiosError, AxiosResponse } from 'axios';
import { PhotosWithTotalResults, Videos } from 'pexels';
import { AssetType, CustomError, IAsset, IPersonalAsset } from '../types';
import { generateId } from '../reducers/builder/utils';
import { getCustomError } from '../utils/utils';
import axiosI from './axios.service';

type GetAssetsResponse = PhotosWithTotalResults | Videos;

class AssetsService {
  baseUrl = 'https://api.pexels.com';
  brameBaseUrl = 'https://brame-global-assets.s3.eu-central-1.amazonaws.com';
  personalBaseUrl = `${process.env.REACT_APP_API_URL}/asset-library/assets/company`;
  apiKey = '563492ad6f91700001000001dfbaca34e3cc41c0998cd621e48f645e';
  config = {
    headers: {
      Authorization: this.apiKey,
    },
  };

  getUrl = (type: AssetType): string => {
    if (type === AssetType.VIDEO) {
      return `${this.baseUrl}/videos/popular`;
    } else {
      return `${this.baseUrl}/v1/curated`;
    }
  };

  getSearchUrl = (type: AssetType): string => {
    if (type === AssetType.VIDEO) {
      return `${this.baseUrl}/videos/search`;
    } else {
      return `${this.baseUrl}/v1/search`;
    }
  };

  serializeData = (data: PhotosWithTotalResults | Videos): IAsset[] => {
    if ('photos' in data) {
      return data.photos.map((asset) => ({
        uuid: asset.id,
        url: asset.src.original,
        urlSmall: asset.src.medium,
        name: `pexel-image-${asset.id}`,
      }));
    } else {
      return data.videos.map((asset) => {
        const originalVideo = asset.video_files.find(
          (file) => file.quality === 'hd'
        );
        const smallVideo = asset.video_files.find(
          (file) => file.quality === 'sd'
        );

        return {
          uuid: asset.id,
          url: originalVideo?.link || '',
          urlSmall: smallVideo?.link || '',
          name: `pexel-video-${asset.id}`,
        };
      });
    }
  };

  serializePersonalAssets = (data: IPersonalAsset[]): IAsset[] => {
    return data.map((asset) => ({
      uuid: asset.assetId,
      url: asset.url,
      urlSmall: asset.url,
      name: asset.name,
      labels: asset.labels,
    }));
  };

  async getAssets(
    type: AssetType
  ): Promise<IAsset[] | AxiosResponse<unknown, any> | undefined> {
    try {
      const res = await axios.get<GetAssetsResponse>(
        this.getUrl(type),
        this.config
      );
      if (!res.data) {
        return undefined;
      }
      return this.serializeData(res.data);
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  }

  async searchAssets(type: AssetType, query: string) {
    try {
      const res = await axios.get<GetAssetsResponse>(
        `${this.getSearchUrl(type)}`,
        {
          ...this.config,
          params: {
            query,
          },
        }
      );
      if (!res.data) {
        return res;
      }
      return this.serializeData(res.data);
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  }

  getBrameAssetsUrl = (assetType: AssetType, index: number): string => {
    switch (assetType) {
      case AssetType.PHOTO:
        return `${this.brameBaseUrl}/images/image-${index}.jpg`;
      case AssetType.VIDEO:
        return `${this.brameBaseUrl}/videos/video-${index}.mp4`;
      case AssetType.GAME:
        return `${this.brameBaseUrl}/gameAssets/swim/asset-${index}.png`;
      case AssetType.LOGO:
      default:
        return `${this.brameBaseUrl}/logos/logo-${index}.png`;
    }
  };

  getBrameAssets = (assetType: AssetType): IAsset[] => {
    const library = [];
    const to = assetType === AssetType.PHOTO ? 40 : 40;

    for (let i = 1; i < to; i++) {
      library.push({
        url: this.getBrameAssetsUrl(assetType, i),
        uuid: `asset-${assetType}-${generateId()}`,
        name: `asset-${i}`,
      });
    }
    return library;
  };

  getBrameAssetCollection = (assetType: AssetType): IAsset[] => {
    return this.getBrameAssets(assetType);
  };

  async getPersonalAssets(
    companyId: string
  ): Promise<IAsset[] | AxiosResponse<unknown, any> | undefined> {
    try {
      const res = await axiosI.get(`${this.personalBaseUrl}/${companyId}`, {
        params: { type: 'image' },
      });
      if (!res.data) {
        return res;
      }
      return this.serializePersonalAssets(res.data);
    } catch (error) {
      const err = error as AxiosError;
      return Promise.reject(err.response?.data);
    }
  }

  async addPersonalAsset(
    file: File,
    base64: any,
    companyId: string
  ): Promise<IPersonalAsset | AxiosResponse<unknown, any> | CustomError> {
    try {
      const res = await axiosI.post(this.personalBaseUrl, {
        companyId,
        labels: [],
        assetType: 'image',
        data: base64,
        mimeType: file.type,
        name: file.name,
      });
      if (!res.data) {
        return res;
      }
      return res.data;
    } catch (error) {
      return Promise.reject(getCustomError(error as AxiosError));
    }
  }

  async removePersonalAsset(
    companyId: string,
    assetId: string | number
  ): Promise<IPersonalAsset | AxiosResponse<unknown, any> | CustomError> {
    try {
      const res = await axiosI.delete(
        `${this.personalBaseUrl}/${companyId}/${assetId}`
      );
      if (!res.data) {
        return res;
      }
      return res.data;
    } catch (error) {
      return Promise.reject(getCustomError(error as AxiosError));
    }
  }
}

export default new AssetsService();
