/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import { IImageProps } from '@brame/components/src/components/Image';
import {
  AssetSourceType,
  IAssetData,
  IImageStyleProps,
  IImageStylePropsChange,
  objectFits,
} from '../../types';
import { ToolbarCategory } from '../ToolbarCategory';
import { SizingWidget } from '../SizingWidget';
import { AlignSelfWidget } from '../AlignSelfWidget';
import { SizeInput } from '../SizeInput';
import InputWithSuffix from '../InputWithSuffix/InputWithSuffix';
import FlipWidget from '../FlipWidget/FlipWidget';
import BorderWidget from '../BorderWidget/BorderWidget';
import BoxShadowWidget from '../BoxShadowWidget/BoxShadowWidget';
import { SelectInput } from '../SelectInput';
import { AssetsModal, useAssetModal } from '../AssetsModal';
import AssetPlaceholder from '../AssetPlaceholder/AssetPlaceholder';
import { BuilderComponent } from '../../reducers/builder';
import { ThemeMediaSelector } from '../ThemeMediaSelector';
import { getCurrentAssetFromCollection } from '../ThemeMediaSelector/utils';
import { RootState } from '../../reducers/store';
import { useAppSelector } from '../../reducers/app/hooks';
import { useStyles } from './styles';
import { useSelectedImageSize } from './index';

interface IProps {
  styleProps: IImageStyleProps;
  elementProps: IImageProps;
  onChange: (props: IImageStylePropsChange | IImageProps) => void;
  onReset: () => void;
}

const ImageEditor: FC<IProps> = ({
  styleProps,
  elementProps,
  onChange,
  onReset,
}) => {
  const {
    size,
    alignSelf,
    objectFit,
    border,
    border: { borderRadius },
    boxShadow,
    opacity,
    rotate,
    flip,
  } = styleProps;
  const { src, asset, themedImage } = elementProps;
  const styles = useStyles();
  const imageSize = useSelectedImageSize();
  const isDisabled = !src && !asset && !themedImage;

  const collection = useAppSelector(
    (state: RootState) =>
      state.theme.currentTheme.assets.defaultCreatives.images
  );

  const choseAssetSource = () => {
    if (themedImage && getCurrentAssetFromCollection(themedImage, collection)) {
      return AssetSourceType.THEME_IMAGE;
    }

    return AssetSourceType.LIBRARY;
  };

  const [imageSource, setImageSource] = useState<AssetSourceType>(
    choseAssetSource()
  );

  useEffect(() => {
    setImageSource(choseAssetSource());
  }, [themedImage, collection]);

  const sourceOptions: AssetSourceType[] = [
    AssetSourceType.LIBRARY,
    AssetSourceType.URL,
    AssetSourceType.THEME_IMAGE,
  ];

  const { assetsModalIsOpen, openAssetsModal, closeAssetsModal } =
    useAssetModal();

  const applyAsset = (asset: IAssetData) => {
    onChange({ ...elementProps, asset: asset, src: '', themedImage: '' });
    closeAssetsModal();
  };

  const changeUrl = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = e.target.value;
    const urlWithoutParams = value.split('?')[0];
    const restrictedType = '.svg';

    if (urlWithoutParams.endsWith(restrictedType)) {
      return;
    }

    onChange({
      ...elementProps,
      src: value,
      asset: null,
      themedImage: '',
    });
  };

  const removeImage = () => {
    onChange({
      ...elementProps,
      src: '',
      asset: null,
      themedImage: '',
    });
  };

  return (
    <div data-testid="image-editor">
      <ToolbarCategory>
        <SelectInput
          label="Image Source"
          value={imageSource}
          onChange={(value) => setImageSource(value as AssetSourceType)}
          options={sourceOptions}
        />

        {imageSource === AssetSourceType.LIBRARY && (
          <AssetPlaceholder
            type={BuilderComponent.IMAGE}
            src={src}
            asset={asset}
            onClick={openAssetsModal}
          />
        )}

        {imageSource === AssetSourceType.URL && (
          <React.Fragment>
            <Typography>
              Only raster images are allowed (.jpg, .png etc)
            </Typography>
            <TextField
              label="Image url"
              value={src}
              type="url"
              InputLabelProps={{ shrink: true }}
              css={styles.input}
              hiddenLabel
              onChange={changeUrl}
            />
          </React.Fragment>
        )}

        {imageSource === AssetSourceType.THEME_IMAGE && (
          <ThemeMediaSelector
            src={themedImage}
            onChange={(src) =>
              onChange({
                ...elementProps,
                src: '',
                asset: null,
                themedImage: src,
              })
            }
          />
        )}

        <Button
          variant="text"
          size="small"
          css={styles.input}
          disabled={isDisabled}
          onClick={removeImage}
        >
          Remove Image
        </Button>
      </ToolbarCategory>

      <ToolbarCategory>
        <AlignSelfWidget
          align={alignSelf}
          onChange={(value) => onChange({ alignSelf: value })}
        />
      </ToolbarCategory>
      <ToolbarCategory>
        <SizingWidget
          size={size}
          actualSize={imageSize}
          showAspectRatioToggle={true}
          onChange={(values) => onChange({ size: values })}
        />
      </ToolbarCategory>
      <ToolbarCategory>
        <SelectInput
          label="Fit image"
          value={objectFit}
          options={objectFits}
          onChange={(value) => onChange({ objectFit: value })}
        />
      </ToolbarCategory>
      <ToolbarCategory>
        <InputWithSuffix
          title="Opacity"
          value={opacity}
          min={0}
          max={100}
          suffix="%"
          css={styles.input}
          onChange={(value) => onChange({ opacity: value })}
        />
        <InputWithSuffix
          title="Rotation"
          value={rotate}
          suffix="°"
          css={styles.input}
          onChange={(value) => onChange({ rotate: value })}
        />
        <FlipWidget
          flip={flip}
          onChange={(value) => onChange({ flip: value })}
        />
        <SizeInput
          title="Roundness"
          sizeValue={borderRadius}
          fullWidth
          css={styles.input}
          onChange={(values) =>
            onChange({
              border: { ...border, borderRadius: values },
            })
          }
        />
      </ToolbarCategory>
      <ToolbarCategory>
        <BorderWidget
          border={border}
          onChange={(value) =>
            onChange({
              border: value,
            })
          }
        />
        <BoxShadowWidget
          boxShadow={boxShadow}
          onChange={(value) =>
            onChange({
              boxShadow: value,
            })
          }
        />
      </ToolbarCategory>

      <ToolbarCategory>
        <Button
          data-testid="reset-styles-button"
          onClick={() => {
            removeImage();
            onReset();
          }}
          size="small"
        >
          Reset
        </Button>
      </ToolbarCategory>

      <AssetsModal
        isOpen={assetsModalIsOpen}
        onApplyAsset={applyAsset}
        onCancel={closeAssetsModal}
        elementType={BuilderComponent.IMAGE}
      />
    </div>
  );
};

export default ImageEditor;
