/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import {
  TextAlignLeft,
  TextAlignCenter,
  TextAlignRight,
  TextAlignJustify,
} from 'phosphor-react';
import {
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { IconProps, TextAlign } from '../../types';
import { useStyles } from './styles';

type TextAlignType = {
  name: string;
  icon: (props: IconProps) => React.ReactElement;
};

const alignVariants: TextAlignType[] = [
  {
    name: 'left',
    icon: (props) => <TextAlignLeft color={props.color} size={20} />,
  },
  {
    name: 'center',
    icon: (props) => <TextAlignCenter color={props.color} size={20} />,
  },
  {
    name: 'right',
    icon: (props) => <TextAlignRight color={props.color} size={20} />,
  },
  {
    name: 'justify',
    icon: (props) => <TextAlignJustify color={props.color} size={20} />,
  },
];

interface IProps {
  align: TextAlign;
  onChange: (align: TextAlign) => void;
  label?: string;
}

const TextAlignSwitcher: FC<IProps> = ({ align, onChange, label }) => {
  const styles = useStyles();
  const theme = useTheme();
  return (
    <div data-testid="text-align-switcher" css={styles.wrapper}>
      {label && <Typography>{label}</Typography>}
      <div>
        <ToggleButtonGroup
          value={align}
          exclusive
          sx={{ gap: '10px' }}
          onChange={(e, value) => !!value && onChange(value)}
        >
          {alignVariants.map(({ name, icon }) => (
            <ToggleButton
              key={name}
              value={name}
              size="small"
              data-testid={`text-align-toggle-${name}`}
              css={styles.button}
            >
              {icon({
                color:
                  align === name
                    ? theme.palette.primary.contrastText
                    : theme.palette.text.primary,
              })}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    </div>
  );
};

export default TextAlignSwitcher;
