import { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Navigate, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import BuilderThemeConfigProvider from '@brame/theme/src/BuilderThemeConfigProvider';
import Footer from '../../components/Footer';
import RightPanel from '../../components/RightPanel';
import LeftPanel from '../../components/LeftPanel';
import { Canvas } from '../../components/Canvas';
import { useAppDispatch, useAppSelector } from '../../reducers/app/hooks';
import {
  fetchCampaignMetaDataAsync,
  fetchInitialStateAsync,
  fetchInitialThemeState,
  fetchTemplatesData,
} from '../../reducers/builder/asyncThunks';
import { selectAuth } from '../../reducers/auth/authSlice';
import { selectCampaign } from '../../reducers/campaign/campaignSlice';
import { getReadinessOfBuilder } from '../../reducers/builder/builderSlice';
import Loading from '../../components/Loading';
import { ErrorFallback } from '../../components/ErrorFallback';

function Main() {
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuth);
  const campaignState = useAppSelector(selectCampaign);
  const readiness = useAppSelector(getReadinessOfBuilder);
  const location = useLocation();
  const currentCampaign = location.pathname.replace('/', '');
  const currentCompany = authState.parsedToken.company_id;
  useEffect(() => {
    const loadInitialStates = async () => {
      await dispatch(fetchInitialThemeState(currentCompany));
      await dispatch(fetchCampaignMetaDataAsync(currentCampaign));
      await dispatch(
        fetchInitialStateAsync({
          companyId: currentCompany,
          campaignId: currentCampaign,
        })
      );
      await dispatch(fetchTemplatesData(currentCompany));
    };
    loadInitialStates();
  }, [currentCompany, currentCampaign, dispatch]);

  if (campaignState.status === 'failed') {
    return <Navigate to="/" replace={true} />;
  }

  if (readiness === 'loading') {
    return <Loading />;
  } else {
    return (
      <main id="builder-main-layout">
        <DndProvider backend={HTML5Backend}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <LeftPanel />
          </ErrorBoundary>
          <section id="builder-workbench">
            <div id="builder-wrapper-view">
              <div id="builder-canvas">
                <BuilderThemeConfigProvider>
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Canvas />
                  </ErrorBoundary>
                </BuilderThemeConfigProvider>
              </div>
              <Footer />
            </div>
          </section>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <RightPanel />
          </ErrorBoundary>
        </DndProvider>
      </main>
    );
  }
}

export default Main;
