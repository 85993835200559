import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const useStyles = (theme: Theme) => ({
  tabs: css`
    min-height: 40px;
    height: 40px;
    margin-bottom: 24px;

    & .MuiTabs-indicator {
      height: 1px;
    }
  `,
  tabPanel: css`
    padding: 0;
  `,
  tab: css`
    color: ${theme.palette.text.primary};
    padding: 6px 15px;

    &.Mui-selected {
      color: ${theme.palette.text.primary};
    }
  `,
  toolbarTitle: css`
    text-transform: capitalize;
    font-size: 16px;
    color: ${theme.palette.text.primary};
    font-weight: 700;
  `,
  titleMargin: css`
    margin-bottom: 8px;
  `,
  settingsBlock: css`
    display: flex;
    gap: 8px;

    & > div {
      width: 100%;
    }
  `,
  column: css`
    flex-direction: column;
  `,
  checkableTitle: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
});
