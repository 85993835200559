import { css } from '@emotion/react';

interface IProps {
  value: string;
}

export const useStyles = ({ value }: IProps) => ({
  widget: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  swatch: css`
    width: 26px;
    height: 26px;
    border-radius: 4px;
    margin: 0 8px;
    cursor: pointer;
    border: 1px dashed rgba(128, 128, 128, 0.5);
    background: ${value};
  `,
});
