import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function Loading() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#75BB3F',
      }}
      style={{ minHeight: '100vh' }}
      data-testid="loading-page"
    >
      <CircularProgress color="inherit" />
    </Box>
  );
}
