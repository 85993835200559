import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { fetchInitialStateAsync, fetchInitialThemeState } from './asyncThunks';
import { initialThemeState } from './fixtures';

const themeSlice = createSlice({
  name: 'theme',
  initialState: initialThemeState,
  reducers: {
    setCurrentTheme: (state, action) => {
      state.currentTheme = { ...state.currentTheme, ...action.payload };
    },
    resetCurrentTheme: (state, action) => {
      const id = action.payload;
      state.currentTheme = state.themeCollection[id];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInitialThemeState.fulfilled, (state, action) => {
      /* eslint-disable */
      action.payload.forEach((theme: any) => {
        _.assign(state.themeCollection, { [theme.data.id]: theme.data });
      });
    });
    builder.addCase(fetchInitialStateAsync.fulfilled, (state, action) => {
      const data = action.payload.data.data;
      // Define a fallback theme
      if (data.selectedTheme === 'undefined') {
        state.chosenTheme = state.themeCollection['theme1'];
        state.currentTheme = state.themeCollection['theme1'];
      } else {
        state.chosenTheme = state.themeCollection[data.selectedTheme];
        state.currentTheme = state.themeCollection[data.selectedTheme];
      }
    });
  },
});

export const { setCurrentTheme, resetCurrentTheme } = themeSlice.actions;

export default themeSlice.reducer;
