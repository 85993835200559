/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Plus, MagicWand, GearSix } from 'phosphor-react';
import { ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import { IconProps, tabsType } from '../../types';
import { setActiveTab, Tabs } from '../../reducers/activeTab/activeTabSlice';
import { RootState } from '../../reducers/store';
import { useStyles } from './styles';

export const tabs: tabsType[] = [
  {
    name: Tabs.ELEMENTS,
    text: 'Add Element',
    icon: (props: IconProps) => (
      <Plus color={props.color} weight={props.weight} size={props.size} />
    ),
  },
  {
    name: Tabs.THEME_SETTINGS,
    text: 'Theme Settings',
    icon: (props: IconProps) => (
      <MagicWand color={props.color} weight={props.weight} size={props.size} />
    ),
  },
  {
    name: Tabs.SETTINGS,
    text: 'Settings',
    icon: (props: IconProps) => (
      <GearSix color={props.color} weight={props.weight} size={props.size} />
    ),
  },
];

const TabSwitcher: FC = () => {
  const currentTab = useSelector((state: RootState) => state.activeTab);
  const dispatch = useDispatch();
  const styles = useStyles();
  const theme = useTheme();

  return (
    <div data-testid="tab-switcher" css={styles.wrapper}>
      <ToggleButtonGroup
        value={currentTab}
        exclusive
        onChange={(e, value) => !!value && dispatch(setActiveTab(value))}
      >
        {tabs.map(({ name, icon }) => (
          <ToggleButton
            key={name}
            value={name}
            data-testid={`toggle-${name}`}
            disableRipple
            sx={{
              border: 'none',
              borderRadius: '4px 4px 0 0 !important',
              height: 60,
              alignSelf: 'flex-end',
              alignItems: 'center',
              position: 'relative',
              '&.Mui-selected': {
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,

                '&:hover': {
                  background: theme.palette.primary.main,
                },
              },
            }}
          >
            {currentTab === name
              ? icon({
                  color: theme.palette.primary.contrastText,
                  weight: 'bold',
                  size: 24,
                })
              : icon({
                  color: theme.palette.text.primary,
                  weight: 'light',
                  size: 24,
                })}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};

export default TabSwitcher;
