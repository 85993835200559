import { css } from '@emotion/react';

export const useStyles = () => ({
  container: css`
    display: grid;
    height: 100px;
    width: 100%;
    grid-template-columns: 1fr auto;
    overflow-x: hidden;
    overflow-y: hidden;
  `,
  addPageButton: css`
    margin-right: 40px;
  `,
  pageScroller: css`
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0 10px;

    &::-webkit-scrollbar {
      display: none;
    },
  `,
  pageScrollerContent: css`
    display: flex;
    width: max-content;
    margin: 0 auto;
  `,
});
