import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';

export const useStyles = () => ({
  container: css`
    width: 96px;
    background: #ffffff;
    margin-bottom: 4px;
    box-shadow: 0px -10px 20px rgba(174, 174, 174, 0.3);
    border-radius: ${brameTheme.shape.borderRadius}px
      ${brameTheme.shape.borderRadius}px 0 0;
    overflow: hidden;
  `,
  item: css`
    width: 100%;
    display: flex;
    padding: 5px 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    gap: 8px;

    &:hover {
      background: ${brameTheme.palette.primary.main};

      * {
        color: #ffffff;
      }
    }
  `,
  popItem: css`
    color: ${brameTheme.palette.text.primary};
  `,
});
