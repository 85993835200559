/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { EntityId } from '@reduxjs/toolkit';
import startCase from 'lodash/startCase';
import capitalize from 'lodash/capitalize';
import { Link } from 'phosphor-react';
import {
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import {
  ButtonAction,
  ButtonActionType,
  PageElement,
  PageType,
} from '../../reducers/builder';
import { useStyles } from './styles';

interface IProps {
  pages: PageElement[];
  additionalPages: PageElement[];
  action: ButtonAction;
  onChange: (action: ButtonAction) => void;
}

const buttonActions = Object.values(ButtonActionType).map((value) => ({
  value,
  name: capitalize(startCase(value)),
}));

const targetDefaultValues = {
  [ButtonActionType.CHANGE_PAGE]: 'NEXT',
  [ButtonActionType.ADDITIONAL_PAGE]: '',
  [ButtonActionType.LINK]: '',
  [ButtonActionType.SUBMIT_FORM]: '',
};

const parsePages = (pages: PageElement[]) => {
  return pages.map((page, idx) => ({
    id: page.id,
    name: page.name || `${idx + 1} - ${page.id}`,
  }));
};

const ButtonNavigationWidget: FC<IProps> = ({
  pages,
  additionalPages,
  action,
  onChange,
}) => {
  const styles = useStyles();
  const [type, setType] = useState(action.type);
  const [target, setTarget] = useState(action.target);
  const [targetBlank, setTargetBlank] = useState<boolean>(!!action.openNewTab);

  useEffect(() => {
    setType(action.type);
    setTarget(action.target);
    setTargetBlank(!!action.openNewTab);
  }, [action]);

  const pagesMap = useMemo(() => parsePages(pages), [pages]);
  const additionalPagesMap = useMemo(
    () =>
      parsePages(
        additionalPages.filter((page) => page.pageType !== PageType.Email)
      ),
    [additionalPages]
  );

  const pageTargets: Array<{
    id: EntityId | 'NEXT' | 'PREVIOUS';
    name: string;
  }> = useMemo(
    () => [
      { id: 'NEXT', name: 'Next page' },
      { id: 'PREVIOUS', name: 'Previous page' },
      ...pagesMap,
    ],
    [pagesMap]
  );

  const onTypeChange = useCallback(
    (e: SelectChangeEvent<ButtonActionType>) => {
      const newType = e.target.value as ButtonActionType;
      const newTarget = targetDefaultValues[newType];
      const updatedAction: ButtonAction = { type: newType, target: newTarget };
      setType(newType);
      setTarget(newTarget);

      if (newType === ButtonActionType.LINK) {
        setTargetBlank(true);
        updatedAction.openNewTab = true;
      }
      onChange(updatedAction);
    },
    [onChange]
  );

  const onTargetChange = useCallback(
    (e: SelectChangeEvent | ChangeEventHandler<HTMLInputElement>) => {
      //@ts-ignore
      const newTarget = e.target.value;
      setTarget(newTarget);
      onChange({ target: newTarget, type });
    },
    [onChange, type]
  );

  const onBlankChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.checked;
      setTargetBlank(value);
      onChange({ target, type, openNewTab: value });
    },
    [onChange, type, target]
  );

  return (
    <div css={styles.widget} data-testid="button-navigation-props-widget">
      <div css={styles.widgetItem}>
        <Typography css={styles.title}>On Click Go To</Typography>
        <div css={styles.styleControl}>
          <Select
            css={styles.select}
            value={type}
            inputProps={{ 'data-testid': 'button-action-type-select' }}
            onChange={onTypeChange}
            fullWidth
          >
            {buttonActions.map(({ name, value }) => (
              <MenuItem value={value} key={value} css={styles.menuItem}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      {type === ButtonActionType.LINK && (
        <div css={styles.widgetItem}>
          <div css={styles.styleControl}>
            <TextField
              id="link-input"
              type="url"
              onChange={onTargetChange as ChangeEventHandler<HTMLInputElement>}
              value={target}
              data-testid="input-target-link"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Link size={16} />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={targetBlank}
                  onChange={onBlankChange}
                />
              }
              label="Open the link in a new tab"
              labelPlacement="start"
              sx={{ marginLeft: 0 }}
            />
          </div>
        </div>
      )}

      {(type === ButtonActionType.CHANGE_PAGE ||
        type === ButtonActionType.ADDITIONAL_PAGE) && (
        <div css={styles.widgetItem}>
          <div css={styles.styleControl}>
            <Select
              css={styles.select}
              value={target as string}
              inputProps={{ 'data-testid': 'button-action-page-select' }}
              onChange={onTargetChange}
              fullWidth
            >
              {(type === ButtonActionType.CHANGE_PAGE
                ? pageTargets
                : additionalPagesMap
              ).map(({ id, name }) => {
                return (
                  <MenuItem value={id} key={id} css={styles.menuItem}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </div>
      )}
    </div>
  );
};

export default ButtonNavigationWidget;
