import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';

export const useStyles = () => ({
  placeholderContainer: css`
    width: 100%;
    cursor: pointer;
  `,
  placeholderLabel: css`
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    line-height: 24px;
    height: 24px;
    background-color: ${brameTheme.palette.primary.main};
    color: ${brameTheme.palette.primary.contrastText};
    border-radius: 5px 5px 0 0;
  `,
  placeholder: css`
    width: inherit;
    border-radius: 0 0 5px 5px;
  `,
});
