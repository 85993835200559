import { AxiosError } from 'axios';
import axiosI from './axios.service';
import { mockCampaignConfig } from './fixtures';

class CampaignService {
  campaignEndpointUrl = `${process.env.REACT_APP_API_URL}/campaign-configurations/api/campaigns`;
  campaignMetadataUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/campaign`;

  async getCampaignDataByCompanyAndCampaign(
    companyId: string | undefined,
    campaignId: string | undefined
  ) {
    const config = {
      headers: {},
    };
    const url = `${this.campaignEndpointUrl}/${companyId}/${campaignId}`;
    try {
      const res = await axiosI.get(url, config);
      if (res.data === 'undefined') {
        return res;
      }
      return res.data;
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  }

  async putCampaignDataByCompanyAndCampaign(
    companyId: string | undefined,
    campaignId: string | undefined,
    // eslint-disable-next-line
    data: any
  ) {
    const dataString = JSON.stringify({
      jsonData: data,
      companyId: companyId,
      campaignId: campaignId,
    });
    const url = `${this.campaignEndpointUrl}`;
    try {
      const res = await axiosI.put(url, dataString);
      if (res.data === 'undefined') {
        return res;
      }
      return res.data;
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  }

  async putCampaignMetaData(data: any) {
    const url = `${this.campaignMetadataUrl}/`;
    try {
      const res = await axiosI.put(url, data);
      if (res.data === 'undefined') {
        return res;
      }
      return res.data;
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  }

  async postInitialCampaignDataByCompanyAndCampaign(
    companyId: string | undefined,
    campaignId: string | undefined,
    // eslint-disable-next-line
    data: any
  ) {
    const dataString = JSON.stringify({
      jsonData: data,
      companyId: companyId,
      campaignId: campaignId,
    });
    const url = `${this.campaignEndpointUrl}`;
    try {
      const res = await axiosI.post(url, dataString);
      if (res.data === 'undefined') {
        return res;
      }
      return res.data;
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  }

  /**
   *
   * @param campaignId campaignId for which the data should be fetched
   *
   * The authentication is sent in the auth header
   */
  async getCampaignMetaData(campaignId: string | undefined) {
    const config = {
      headers: {},
    };
    const url = `${this.campaignMetadataUrl}/${campaignId}`;
    try {
      const res = await axiosI.get(url, config);
      if (res.data === 'undefined') {
        return res;
      }
      return res.data;
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  }

  async getAllCampaignsByCompany(companyId: string | undefined) {
    const config = {
      headers: {},
    };
    const url = `${this.campaignMetadataUrl}/company-id/${companyId}`;
    try {
      const res = await axiosI.get(url, config);
      if (res.data === 'undefined') {
        return res;
      }
      return res.data;
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  }

  async createCampaignByCompany(companyId: string | undefined, name: string) {
    const data = { ...mockCampaignConfig, name: name, companyId };
    const url = `${this.campaignMetadataUrl}/`;
    try {
      const res = await axiosI.post(url, data);
      if (res.data === 'undefined') {
        return res;
      }
      return res.data;
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  }
}

export default new CampaignService();
