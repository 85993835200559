/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { Text } from '../Text';
import { useRunnerSelector } from '../../slices/store/store';
import { IPhaserTextProps } from './IProps';

//@ts-ignore
const getParamFromId = (id) => (store) => {
  return store['gameState'][id];
};

const PhaserText: FC<IPhaserTextProps> = React.forwardRef(
  ({ builderMode, valueID, prefix, suffix, ...props }, ref) => {
    let value: any;
    if (prefix === undefined) {
      prefix = '';
    }
    if (suffix === undefined) {
      suffix = '';
    }
    if (builderMode) {
      value = 'value';
    } else {
      //@ts-ignore
      value = useRunnerSelector(getParamFromId(valueID));
    }
    return (
      <Text ref={ref} {...props}>
        {
          //@ts-ignore
          prefix + value + suffix
        }
      </Text>
    );
  }
);

export default PhaserText;
