import { Portal } from 'react-portal';
import { FC, RefObject } from 'react';
import { IElement } from '../../reducers/builder';
import { IElementStyleProps } from '../../types';
import Resizable from './Resizable';

interface IProps {
  isOpen: boolean;
  element: IElement;
  styleProps: IElementStyleProps;
  elementRef: RefObject<any>;
}

const ResizablePortal: FC<IProps> = ({
  isOpen,
  element,
  styleProps,
  elementRef,
}) => {
  const position = {
    top: elementRef.current.offsetTop,
    left: elementRef.current.offsetLeft,
  };
  const size = {
    width: elementRef.current.offsetWidth,
    height: elementRef.current.offsetHeight,
  };

  const hasParent = document.getElementById(
    element.id as string
  )!.parentElement;

  return (
    <>
      {isOpen && (
        <Portal
          node={
            hasParent &&
            document.getElementById(element.id as string)!.parentElement
          }
        >
          <Resizable
            element={element}
            styleProps={styleProps}
            size={size}
            position={position}
          />
        </Portal>
      )}
    </>
  );
};

export default ResizablePortal;
