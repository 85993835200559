import React, { useState } from 'react';

const usePageRemoveModal = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openConfirmationModal = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setIsModalOpen(false);
  };

  return {
    isModalOpen,
    openConfirmationModal,
    closeConfirmationModal,
  };
};

export default usePageRemoveModal;
