import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';

export const useStyles = () => ({
  textarea: css`
    width: 100%;
    max-height: 246px;
    font-family: 'Roboto';
    margin-top: 20px;
    border-radius: 5px;
    padding: 10px;
    resize: vertical;
    color: ${brameTheme.palette.text.primary};

    &:focus {
      outline-color: ${brameTheme.palette.primary.main};
    }
  `,
  label: css`
    font-size: 16px;
    color: #4d4d4d;
    text-transform: capitalize;
    padding-bottom: 10px;
    border-bottom: 1px solid ${brameTheme.palette.text.primary};
  `,
});
