import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';
import { RootState } from '../../reducers/store';
import { RenderTree } from '../../types';
import {
  renameElement,
  removeElement,
  removePage,
} from '../../reducers/builder/builderSlice';
import { Element, IElementNameUpdate } from '../../reducers/builder';

const useElementTree = () => {
  const builderTree = useSelector((state: RootState) => state.elements);
  const dispatch = useDispatch();

  const renameTreeElement = (updates: IElementNameUpdate) => {
    dispatch(renameElement(updates));
  };

  const removeTreeElement = (tree: RenderTree) => {
    if (tree.type === 'page') {
      dispatch(removePage(tree.id));
    } else {
      dispatch(removeElement(tree.id));
    }
  };

  const buildElementTree = (id: EntityId) => {
    const element = builderTree.elements.entities[id] as Element;

    const isRootElement = Object.entries(builderTree.pages.entities).find(
      (item) => item[1]?.elementRoot === id
    );

    const node: RenderTree = {
      id: element?.id,
      parentId: element?.parentId,
      name: element?.name || element?.value || element?.type,
      type: 'child',
      children: [],
    };

    if (element?.childrenIds?.length) {
      node.type = 'subcategory';

      element.childrenIds.forEach((id: EntityId) =>
        // @ts-ignore
        node?.children.push(buildElementTree(id))
      );
    }

    if (isRootElement) {
      node.type = 'root';
    }

    return node;
  };

  const buildPageStructure = () => {
    const pagesKeys = builderTree.pages.ids;

    return pagesKeys.map((id) => {
      const page = builderTree.pages.entities[id];

      const pageNode: RenderTree = {
        id: page?.id,
        name: page?.name,
        type: 'page',
        children: [...[buildElementTree(page?.elementRoot as EntityId)]],
      };
      return pageNode;
    });
  };

  const elementsTree = useMemo(buildPageStructure, [builderTree]);

  return { elementsTree, renameTreeElement, removeTreeElement };
};

export default useElementTree;
