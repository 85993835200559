import { MagnifyingGlass, X } from 'phosphor-react';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import throttle from 'lodash/throttle';
import { IconButton, InputAdornment, TextField } from '@mui/material';

const AssetsSearchInput = ({
  onSearch,
}: {
  onSearch: (query: string) => void;
}) => {
  const [query, setQuery] = useState<string | null>(null);
  const throttled = useRef(throttle(onSearch, 1000));
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setQuery(e.target.value),
    [setQuery]
  );

  const clearInput = useCallback(() => {
    setQuery('');
  }, [setQuery]);

  useEffect(() => {
    if (query !== null) {
      throttled.current(query);
    }
  }, [query]);

  return (
    <TextField
      name="search-assets"
      placeholder="Search"
      onChange={onChange}
      value={query || ''}
      autoComplete="off"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MagnifyingGlass size={16} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={clearInput}>
              <X size={16} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default AssetsSearchInput;
