import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const useStyles = (theme: Theme) => ({
  tabs: css`
    min-height: 40px;
    height: 40px;
    margin-bottom: 24px;

    & .MuiTabs-indicator {
      height: 1px;
    }
  `,
  tabPanel: css`
    padding: 0;
  `,
  tab: css`
    color: ${theme.palette.text.primary};
    padding: 6px 15px;

    &.Mui-selected {
      color: ${theme.palette.text.primary};
    }
  `,
  themePreviewWrapper: css`
    max-height: 465px;
    padding: 8px 24px;
    overflow-y: scroll;
  `,
  buttonBlock: css`
    margin-top: 20px;
    display: flex;
    justify-content: center;
  `,
});
