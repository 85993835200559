/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC, useCallback, useState } from 'react';
import { Eye, RocketLaunch } from 'phosphor-react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import CollapsableAlert from '../CollapsableAlert';
import { Breakpoints } from '../Breakpoints';
import { TabSwitcher } from '../TabSwitcher';
import PublisherService from '../../api-client/publisher.service';
import { useAppDispatch, useAppSelector } from '../../reducers/app/hooks';
import { selectAuth } from '../../reducers/auth/authSlice';
import { selectCampaign } from '../../reducers/campaign/campaignSlice';
import {
  getBreakpoints,
  getSelectedBreakpoint,
  selectBreakpoint,
} from '../../reducers/shared/sharedSlice';
import { CanvasFormats } from '../CanvasFormats';
import { useStyles } from './styles';
import useCanvasResize from './useCanvasResize';

const Toolbar: FC = () => {
  const dispatch = useAppDispatch();
  const styles = useStyles();
  const breakpoints = useAppSelector(getBreakpoints);
  const activeBreakpoint = useAppSelector(getSelectedBreakpoint);
  const [publishing, setPublishing] = useState(false);
  const [publishUrl, setPublishUrl] = useState('');
  const [showAlert, setAlert] = useState(false);
  const { campaignId } = useParams();
  const authState = useAppSelector(selectAuth);
  const companyId = authState.parsedToken.company_id;
  const campaignState = useAppSelector(selectCampaign);
  const navigate = useNavigate();

  const onClickPublish = async () => {
    setPublishing(true);
    await PublisherService.publishCampaign(
      companyId,
      campaignState.selectedCampaign,
      authState.token
    ).then((response) => {
      setPublishing(false);
      setAlert(true);
      if (response) {
        setPublishUrl(
          `${process.env.REACT_APP_CAMPAIGN_FRAMEWORK_URL}/${companyId}/${campaignId}`
        );
      }
    });
  };

  const onBreakpointSelect = useCallback(
    (id: string) => {
      dispatch(selectBreakpoint(id));
    },
    [dispatch]
  );

  const {
    canvasResponsiveness,
    canvasDimensions,
    changeCanvasDimensions,
    canvasFormats,
  } = useCanvasResize();

  return (
    <header css={styles.toolbar} data-testid="toolbar">
      <TabSwitcher />
      <div css={[styles.centered, styles.buttonsBlock]}>
        {canvasResponsiveness === true && (
          <Breakpoints
            breakpoints={breakpoints}
            activeBreakpoint={activeBreakpoint}
            onSelect={onBreakpointSelect}
          />
        )}

        {canvasResponsiveness === false && (
          <CanvasFormats
            formats={canvasFormats}
            currentFormat={canvasDimensions}
            onChange={changeCanvasDimensions}
          />
        )}
      </div>
      <div css={[styles.centered, styles.buttonsBlock]}>
        <Button
          css={styles.toolbarButton}
          endIcon={<Eye weight="light" size={23} />}
          onClick={() => {
            navigate(`/${campaignId}/${companyId}/preview`, { replace: true });
          }}
        >
          Preview
        </Button>
        <Button
          css={[styles.toolbarButton, styles.publishButton]}
          endIcon={
            publishing ? (
              <CircularProgress size="1rem" />
            ) : (
              <RocketLaunch weight="light" size={23} />
            )
          }
          onClick={onClickPublish}
        >
          {publishing ? 'Publishing' : 'Publish'}
        </Button>
        {showAlert ? (
          <CollapsableAlert
            alertText={
              <p>
                The website was published to: <br />
                <Link href={publishUrl}>
                  {publishUrl
                    ? `The website was published to: ${publishUrl}`
                    : 'Something went wrong.'}
                </Link>
              </p>
            }
            onChange={() => setAlert(false)}
          />
        ) : null}
      </div>
    </header>
  );
};

export default Toolbar;
