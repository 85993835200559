//@ts-nocheck
import { colord } from 'colord';
import { ITheme } from '@brame/theme';

export enum ButtonVariant {
  TEXT = 'text',
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
}

export const getBtnStylePreset = (variant: ButtonVariant, theme: ITheme) => {
  switch (variant) {
    case ButtonVariant.TEXT:
      return {
        background: '#ffffff00',
        borderWidth: 0,
        borderStyle: 'none',
        borderColor: '#ffffff00',
        boxShadow: 'none',
        color: theme.theme.palette?.text?.primary,
        hover: {
          background: colord('#ffffff00').darken(0.1).toHex(),
          boxShadow: 'none',
        },
      };

    case ButtonVariant.CONTAINED:
      return {
        background:
          theme.theme.components?.MuiButton?.styleOverrides?.root?.background,
        boxShadow:
          '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        color: theme.theme.palette?.primary?.contrastText,
        borderWidth: 0,
        borderStyle: 'none',
        borderColor: '#ffffff00',
        hover: {
          background: colord(
            theme.theme.components?.MuiButton?.styleOverrides?.root?.background
          )
            .darken(0.1)
            .toHex(),
          boxShadow:
            '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        },
      };

    case ButtonVariant.OUTLINED:
      return {
        background: '#ffffff00',
        color: theme.theme.palette?.text?.primary,
        boxShadow: 'none',
        borderWidth: 1,
        borderStyle:
          theme.theme.components?.MuiButton?.styleOverrides?.root?.borderStyle,
        borderColor: theme.theme.palette?.text?.primary,
        hover: {
          background: colord('#ffffff00').darken(0.1).toHex(),
          boxShadow: 'none',
        },
      };
  }
};
