/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Check, XCircle } from 'phosphor-react';
import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import ConfirmationModal from '@brame/components/src/components/ConfirmationModal/ConfirmationModal';
import { AssetType, IAsset } from '../../../types';
import usePageRemoveModal from '../../../hooks/usePageRemoveModal';
import { useStyles } from './styles';

const MasonryCard = ({
  asset,
  assetType,
  selected,
  canBeRemoved,
  onSelectAsset,
  onRemoveAsset,
}: {
  asset: IAsset;
  assetType: AssetType;
  selected: boolean;
  canBeRemoved: boolean;
  onSelectAsset: (asset: IAsset) => void;
  onRemoveAsset?: (asset: IAsset) => void;
}) => {
  const styles = useStyles(selected);
  const [assetIsLoaded, setAssetIsLoaded] = useState<boolean>(false);
  const { isModalOpen, openConfirmationModal, closeConfirmationModal } =
    usePageRemoveModal();

  const handleAssetRemove = () => {
    closeConfirmationModal();
    onRemoveAsset?.(asset);
  };

  const handleAssetLoaded = () => {
    setAssetIsLoaded(true);
  };

  return (
    <React.Fragment>
      <div
        key={asset.uuid}
        css={styles.assetContainer}
        onClick={() => onSelectAsset(asset)}
      >
        {assetType === AssetType.VIDEO ? (
          <video
            src={asset.urlSmall || asset.url}
            autoPlay
            loop
            muted
            css={styles.asset}
          />
        ) : (
          <img
            src={asset.urlSmall || asset.url}
            decoding="async"
            alt={asset.name}
            loading="lazy"
            onLoad={handleAssetLoaded}
            css={styles.asset}
          />
        )}
        {selected && (
          <Check size={24} weight="bold" css={styles.selectedIcon} />
        )}
        {canBeRemoved && !selected && assetIsLoaded && (
          <IconButton
            css={styles.removeIcon}
            size="medium"
            aria-label="remove asset"
            data-testid="remove-asset-button"
            onClick={openConfirmationModal}
          >
            <XCircle weight="fill" />
          </IconButton>
        )}
      </div>
      <ConfirmationModal
        isOpen={isModalOpen}
        title="Removal confirmation"
        description="Do you really want to remove this asset?"
        onConfirm={handleAssetRemove}
        onCancel={closeConfirmationModal}
      />
    </React.Fragment>
  );
};

export default MasonryCard;
