/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Drawer } from '@mui/material';
import { RootState } from '../../reducers/store';
import { ThemeEditor } from '../ThemeEditor';
import { findCurrentTab } from '../TabSwitcher/utils';
import { Tabs } from '../../reducers/activeTab/activeTabSlice';
import ComponentsLibrary from '../ComponentsLibrary/ComponentsLibrary';
import { Settings } from '../Settings';
import { useStyles } from './styles';

const LeftPanel: FC<{ children?: React.ReactNode }> = () => {
  const activeTab = useSelector((state: RootState) => state.activeTab);
  const styles = useStyles();

  return (
    <Drawer
      sx={{
        width: 344,
        flexShrink: 0,

        '& .MuiDrawer-paper': {
          width: 344,
          boxSizing: 'border-box',
          paddingTop: '110px',
          border: 'none',
          boxShadow: '0 10px 20px #23470720',
        },
      }}
      variant="permanent"
      data-testid="left-panel"
    >
      <div css={styles.tabTitle}>{findCurrentTab(activeTab)?.text}</div>

      {activeTab === Tabs.THEME_SETTINGS && <ThemeEditor />}
      {activeTab === Tabs.ELEMENTS && <ComponentsLibrary />}
      {activeTab === Tabs.SETTINGS && <Settings />}
    </Drawer>
  );
};

export default LeftPanel;
