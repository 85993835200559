import React from 'react';
import { useAppSelector } from '../../reducers/app/hooks';

export const RenderIndicator: React.FC = () => {
  const { show, ...style } = useAppSelector(({ shared }) => {
    return shared.dropIndicator;
  });
  if (!show) return null;

  return (
    <div
      style={{
        position: 'fixed',
        display: 'block',
        opacity: 1,
        borderStyle: 'solid',
        borderWidth: '1px',
        backgroundColor: 'lawngreen',
        borderColor: 'transparent',
        zIndex: 99999,
        transition: '0.2s ease-in',
        ...style,
      }}
    />
  );
};

export default RenderIndicator;
