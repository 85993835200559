import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';

export const useStyles = () => ({
  unit: css`
    cursor: pointer;
    text-transform: uppercase;
    color: ${brameTheme.palette.text.primary};
  `,
  title: css`
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 10px;
  `,
});
