/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { FC, useState } from 'react';
import { Button } from '@mui/material';
import { TextInput } from '../TextInput';
import { useAppSelector } from '../../reducers/app/hooks';
import { RootState } from '../../reducers/store';
import { useStyles } from './styles';

const CampaignLink: FC = () => {
  const styles = useStyles();
  const companyId = process.env.REACT_APP_TEST_USER_COMPANY_ID;
  const campaign = useAppSelector((state: RootState) => state.campaign);
  const campaignLink = `https://brame-campaign-framework-2-production.s3-website.eu-central-1.amazonaws.com/${companyId}/${campaign.selectedCampaign}`;
  const [buttonLabel, setButtonLabel] = useState('Copy');

  const copyCompanyLink = () => {
    navigator.clipboard.writeText(campaignLink);
    setButtonLabel('Copied');
    setTimeout(() => setButtonLabel('Copy'), 1000);
  };

  return (
    <div data-testid="campaign-link">
      <div css={styles.inputWrapper}>
        <TextInput
          title="Campaign link"
          value={campaignLink || 'Campaign link'}
          fullWidth
          onChange={(e) => e}
        />
        <Button
          size="small"
          variant="contained"
          css={styles.button}
          onClick={copyCompanyLink}
        >
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
};

export default CampaignLink;
