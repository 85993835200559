import React, { FC } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { IconButton, Popper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { BuilderComponent } from '../../reducers/builder';
import { useStyles } from './styles';

const DnDLabel: FC<{
  selected: boolean;
  elementName?: string;
  elementType: BuilderComponent;
  onDelete: () => void;
  anchorEl?: HTMLElement;
  id: string;
}> = ({ selected, elementName, elementType, onDelete, id, anchorEl }) => {
  const styles = useStyles();

  return selected ? (
    <Popper id={id} open anchorEl={anchorEl} placement="top-start">
      <div css={styles.label}>
        <span>{elementName || elementType}</span>
        {elementType !== BuilderComponent.ROOT_CONTAINER && (
          <IconButton
            aria-label="delete"
            size="small"
            title="Delete element"
            onClick={onDelete}
            sx={{ fontSize: '14px', padding: '3px' }}
          >
            <DeleteIcon fontSize="inherit" style={{ color: 'white' }} />
          </IconButton>
        )}
      </div>
    </Popper>
  ) : null;
};

export default DnDLabel;
