import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';

export const useStyles = () => ({
  container: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  removeButton: css`
    margin: 8px 0;
  `,
});
