import { fonts } from '../../components/src/assets/fonts';
import { images } from '../../components/src/assets/images';
import { IThemeCollection } from './types';

export const defaultThemes: IThemeCollection = {
  theme1: {
    name: 'Ability',
    id: 'theme1',
    type: 'default',
    assets: {
      defaultCreatives: {
        videos: {
          'default video': {
            name: 'default video',
            type: 'video',
            url: 'https://ak.picdn.net/shutterstock/videos/3096721/preview/stock-footage-hexadecimal-data-loopable-background.webm',
          },
        },
        images: {
          'default background': {
            name: 'default background',
            type: 'background',
            url: images.ability_1,
          },
          'default logo': {
            name: 'default logo',
            type: 'logo',
            url: images.ability_logo,
          },
          'default image 1': {
            name: 'default image 1',
            type: 'image',
            url: images.ability_1,
          },
          'default image 2': {
            name: 'default image 2',
            type: 'image',
            url: images.ability_1,
          },
          'default image 3': {
            name: 'default image 3',
            type: 'image',
            url: images.ability_1,
          },
          'default image 4': {
            name: 'default image 4',
            type: 'image',
            url: images.ability_1,
          },
        },
      },
    },
    theme: {
      palette: {
        primary: {
          main: '#2db2a6',
          contrastText: '#FFFFFF',
        },
        secondary: {
          main: '#2db2a6',
          contrastText: '#FFFFFF',
        },
        background: {
          default: '#fafafa',
          paper: '#fafafa',
        },
        text: {
          primary: '#202020',
          secondary: '#454545',
        },
      },
      shape: {
        borderRadius: 0,
      },
      components: {
        MuiScopedCssBaseline: {
          styleOverrides: {
            root: `
            @font-face {
              font-family: ${fonts.LatoRegular.fontFamily};
              font-display: swap;
              font-weight: ${fonts.LatoRegular.fontWeight};
              src: url(${fonts.LatoRegular.source}) format('truetype');
            }
            @font-face {
              font-family: ${fonts.LatoBold.fontFamily};
              font-display: swap;
              font-weight: ${fonts.LatoBold.fontWeight};
              src: url(${fonts.LatoBold.source}) format('truetype');
            }
            @font-face {
              font-family: ${fonts.BevanRegular.fontFamily};
              font-display: swap;
              font-weight: ${fonts.BevanRegular.fontWeight};
              src: url(${fonts.BevanRegular.source}) format('truetype');
            }
          `,
          },
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              fontFamily: 'Lato',
              fontWeight: 400,
              fontSize: 16,
              color: '#202020',
              background: '#ffffff00',
              textTransform: 'none',
              textAlign: 'left',
            },
            h1: {
              fontFamily: 'Bevan',
              fontSize: 32,
              color: '#202020',
              background: '#ffffff00',
              textTransform: 'none',
            },
            h2: {
              fontFamily: 'Bevan',
              fontSize: 22,
              color: '#202020',
              background: '#ffffff00',
              textTransform: 'none',
            },
            h3: {
              fontFamily: 'Bevan',
              fontSize: 18,
              color: '#202020',
              background: '#ffffff00',
              textTransform: 'none',
            },
          },
        },
        MuiButton: {
          defaultProps: {
            variant: 'contained',
          },
          styleOverrides: {
            root: {
              fontFamily: 'Lato',
              fontWeight: 700,
              fontSize: 18,
              borderRadius: 0,
              borderWidth: 0,
              borderStyle: 'solid',
              borderColor: '#2db2a6',
              background: '#2db2a6',
              color: '#ffffff',
              textTransform: 'uppercase',
            },
            contained: {
              background: '#2db2a6',
              color: '#ffffff',
              borderWidth: 0,
              borderColor: '#2db2a6',
              borderRadius: 0,
            },
            outlined: {
              background: '#ffffff00',
              color: '#2db2a6',
              borderWidth: 1,
              borderColor: '#2db2a6',
              borderRadius: 32,
            },
            text: {
              background: '#ffffff00',
              color: '#2db2a6',
              borderWidth: 0,
              borderColor: '#ffffff00',
              borderRadius: 0,
            },
          },
        },
        MuiContainer: {
          styleOverrides: {
            root: {
              background: '#ffffff00',
            },
          },
        },
      },
    },
  },
  theme2: {
    name: 'Youthful',
    id: 'theme2',
    type: 'default',
    assets: {
      defaultCreatives: {
        videos: {
          'default video': {
            name: 'default video',
            type: 'video',
            url: 'https://ak.picdn.net/shutterstock/videos/1055031707/preview/stock-footage-programming-code-abstract-technology-background-of-software-developer-and-computer-script-flow.webm',
          },
        },
        images: {
          'default background': {
            name: 'default background',
            type: 'background',
            url: images.youthful_1,
          },
          'default logo': {
            name: 'default logo',
            type: 'logo',
            url: images.youthful_logo,
          },
          'default image 1': {
            name: 'default image 1',
            type: 'image',
            url: images.youthful_1,
          },
          'default image 2': {
            name: 'default image 2',
            type: 'image',
            url: images.youthful_1,
          },
          'default image 3': {
            name: 'default image 3',
            type: 'image',
            url: images.youthful_1,
          },
          'default image 4': {
            name: 'default image 4',
            type: 'image',
            url: images.youthful_1,
          },
        },
      },
    },
    theme: {
      palette: {
        primary: {
          main: '#e10000',
          contrastText: '#ffffff',
        },
        secondary: {
          main: '#e10000',
          contrastText: '#ffffff',
        },
        background: {
          default: '#ffffff',
          paper: '#ffffff',
        },
        text: {
          primary: '#202020',
          secondary: '#454545',
        },
      },
      shape: {
        borderRadius: 4,
      },
      components: {
        MuiScopedCssBaseline: {
          styleOverrides: {
            root: `
              @font-face {
                font-family: ${fonts.RalewayRegular.fontFamily};
                font-display: swap;
                font-weight: ${fonts.RalewayRegular.fontWeight};
                src: url(${fonts.RalewayRegular.source}) format('truetype');
              }
              @font-face {
                font-family: ${fonts.RalewayBold.fontFamily};
                font-display: swap;
                font-weight: ${fonts.RalewayBold.fontWeight};
                src: url(${fonts.RalewayBold.source}) format('truetype');
              }
              @font-face {
                font-family: ${fonts.LatoRegular.fontFamily};
                font-display: swap;
                font-weight: ${fonts.LatoRegular.fontWeight};
                src: url(${fonts.LatoRegular.source}) format('truetype');
              }
              @font-face {
                font-family: ${fonts.LatoBold.fontFamily};
                font-display: swap;
                font-weight: ${fonts.LatoBold.fontWeight};
                src: url(${fonts.LatoBold.source}) format('truetype');
              }
            `,
          },
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              fontFamily: 'Lato',
              fontWeight: 400,
              fontSize: 16,
              color: '#202020',
              background: '#ffffff00',
              textTransform: 'none',
            },
            h1: {
              fontFamily: 'Raleway',
              fontSize: 32,
              color: '#202020',
              background: '#ffffff00',
              textTransform: 'none',
              textAlign: 'left',
            },
            h2: {
              fontFamily: 'Raleway',
              fontSize: 22,
              color: '#202020',
              background: '#ffffff00',
              textTransform: 'none',
              textAlign: 'left',
            },
            h3: {
              fontFamily: 'Raleway',
              fontSize: 18,
              color: '#202020',
              background: '#ffffff00',
              textTransform: 'none',
              textAlign: 'left',
            },
          },
        },
        MuiButton: {
          defaultProps: {
            variant: 'contained',
          },
          styleOverrides: {
            root: {
              fontFamily: 'Lato',
              fontWeight: 700,
              fontSize: 18,
              borderRadius: 4,
              background: '#e10000',
              borderWidth: 0,
              borderStyle: 'solid',
              borderColor: '#e10000',
              color: '#ffffff',
              textTransform: 'uppercase',
            },
            contained: {
              background: '#e10000',
              color: '#ffffff',
              borderRadius: 4,
              borderWidth: 0,
              borderColor: '#e10000',
            },
            outlined: {
              background: '#ffffff00',
              color: '#e10000',
              borderRadius: 32,
              borderWidth: 1,
              borderColor: '#e10000',
            },
            text: {
              background: '#ffffff00',
              color: '#e10000',
              borderRadius: 4,
              borderWidth: 0,
              borderColor: '#ffffff00',
            },
          },
        },
        MuiContainer: {
          styleOverrides: {
            root: {
              background: '#ffffff00',
            },
          },
        },
      },
    },
  },
  theme3: {
    name: 'Consulting',
    id: 'theme3',
    type: 'default',
    assets: {
      defaultCreatives: {
        videos: {
          'default video': {
            name: 'default video',
            type: 'video',
            url: 'https://bnetcmsus-a.akamaihd.net/cms/template_resource/NTLYZ0PS4EY13258338831826.webm',
          },
        },
        images: {
          'default background': {
            name: 'default background',
            type: 'background',
            url: images.consulting_1,
          },
          'default logo': {
            name: 'default logo',
            type: 'logo',
            url: images.consulting_logo,
          },
          'default image 1': {
            name: 'default image 1',
            type: 'image',
            url: images.consulting_1,
          },
          'default image 2': {
            name: 'default image 2',
            type: 'image',
            url: images.consulting_2,
          },
          'default image 3': {
            name: 'default image 3',
            type: 'image',
            url: images.consulting_1,
          },
          'default image 4': {
            name: 'default image 4',
            type: 'image',
            url: images.consulting_2,
          },
        },
      },
    },
    theme: {
      palette: {
        primary: {
          main: '#0071bc',
          contrastText: '#ffffff',
        },
        secondary: {
          main: '#000000',
          contrastText: '#ffffff',
        },
        background: {
          default: '#ffffff',
          paper: '#ffffff',
        },
        text: {
          primary: '#202020',
          secondary: '#202020',
        },
        divider: '#4e5252',
      },
      shape: {
        borderRadius: 4,
      },
      components: {
        MuiScopedCssBaseline: {
          styleOverrides: {
            root: `
              @font-face {
                font-family: ${fonts.MontserratRegular.fontFamily};
                font-display: swap;
                font-weight: ${fonts.MontserratRegular.fontWeight};
                src: url(${fonts.MontserratRegular.source}) format('truetype');
              }
              @font-face {
                 font-family: ${fonts.MontserratBold.fontFamily};
                 font-display: swap;
                 font-weight: ${fonts.MontserratBold.fontWeight};
                 src: url(${fonts.MontserratBold.source}) format('truetype');
              }
            `,
          },
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              fontFamily: 'Montserrat',
              fontWeight: 400,
              fontSize: 16,
              color: '#202020',
              background: '#ffffff00',
              textTransform: 'none',
              textAlign: 'left',
            },
            h1: {
              fontFamily: 'Montserrat',
              fontSize: 32,
              color: '#202020',
              background: '#ffffff00',
              textTransform: 'none',
            },
            h2: {
              fontFamily: 'Montserrat',
              fontSize: 22,
              color: '#202020',
              background: '#ffffff00',
              textTransform: 'none',
            },
            h3: {
              fontFamily: 'Montserrat',
              fontSize: 18,
              color: '#202020',
              background: '#ffffff00',
              textTransform: 'none',
            },
          },
        },
        MuiButton: {
          defaultProps: {
            variant: 'contained',
          },
          styleOverrides: {
            root: {
              fontFamily: 'Montserrat',
              fontWeight: 700,
              fontSize: 18,
              borderRadius: 32,
              borderWidth: 0,
              background: '#0071bc',
              borderStyle: 'solid',
              borderColor: '#0071bc',
              color: '#ffffff',
              textTransform: 'uppercase',
            },
            contained: {
              background: '#0071bc',
              color: '#ffffff',
              borderRadius: 32,
              borderWidth: 0,
              borderColor: '#0071bc',
            },
            outlined: {
              background: '#ffffff00',
              color: '#0071bc',
              borderRadius: 32,
              borderWidth: 1,
              borderColor: '#0071bc',
            },
            text: {
              background: '#ffffff00',
              color: '#0071bc',
              borderRadius: 32,
              borderWidth: 0,
              borderColor: '#ffffff00',
            },
          },
        },
        MuiContainer: {
          styleOverrides: {
            root: {
              background: '#ffffff00',
            },
          },
        },
      },
    },
  },
  theme4: {
    name: 'Conceptual',
    id: 'theme4',
    type: 'default',
    assets: {
      defaultCreatives: {
        videos: {
          'default video': {
            name: 'default video',
            type: 'video',
            url: 'https://ak.picdn.net/shutterstock/videos/1046849542/preview/stock-footage-hand-of-asian-family-with-marshmallow-on-a-stick-being-roasted-over-a-camping-fire-at-night.webm',
          },
        },
        images: {
          'default background': {
            name: 'default background 1',
            type: 'background',
            url: images.concept_1,
          },
          'default logo': {
            name: 'default logo',
            type: 'logo',
            url: images.concept_logo,
          },
          'default image 1': {
            name: 'default image 1',
            type: 'image',
            url: images.concept_1,
          },
          'default image 2': {
            name: 'default image 2',
            type: 'image',
            url: images.concept_1,
          },
          'default image 3': {
            name: 'default image 3',
            type: 'image',
            url: images.concept_1,
          },
          'default image 4': {
            name: 'default image 4',
            type: 'image',
            url: images.concept_1,
          },
        },
      },
    },
    theme: {
      palette: {
        primary: {
          main: '#FFF9CF',
          contrastText: '#4C4C66',
        },
        secondary: {
          main: '#4C4C66',
          contrastText: '#FFF9CF',
        },
        background: {
          default: '#0d080e',
          paper: '#0d080e',
        },
        text: {
          primary: '#FFF9CF',
          secondary: '#FFF9CF',
        },
        divider: '#4e5252',
      },
      shape: {
        borderRadius: 0,
      },
      components: {
        MuiScopedCssBaseline: {
          styleOverrides: {
            root: `
            @font-face {
              font-family: ${fonts.PressStart2P.fontFamily};
              font-display: swap;
              font-weight: ${fonts.PressStart2P.fontWeight};
              src: url(${fonts.PressStart2P.source}) format('truetype');
           }
           @font-face {
              font-family: ${fonts.FrederickaTheGreat.fontFamily};
              font-display: swap;
              font-weight: ${fonts.FrederickaTheGreat.fontWeight};
              src: url(${fonts.FrederickaTheGreat.source}) format('truetype');
           }
            `,
          },
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              fontFamily: `${fonts.PressStart2P.fontFamily}`,
              fontWeight: 400,
              fontSize: 16,
              color: '#FFF9CF',
              background: '#ffffff00',
              textTransform: 'none',
              textAlign: 'left',
            },
            h1: {
              fontFamily: `${fonts.FrederickaTheGreat.fontFamily}`,
              fontSize: 40,
              color: '#FFF9CF',
              background: '#ffffff00',
              textTransform: 'none',
            },
            h2: {
              fontFamily: `${fonts.FrederickaTheGreat.fontFamily}`,
              fontSize: 32,
              color: '#FFF9CF',
              background: '#ffffff00',
              textTransform: 'none',
            },
            h3: {
              fontFamily: `${fonts.FrederickaTheGreat.fontFamily}`,
              fontSize: 22,
              color: '#FFF9CF',
              background: '#ffffff00',
              textTransform: 'none',
            },
          },
        },
        MuiButton: {
          defaultProps: {
            variant: 'contained',
          },
          styleOverrides: {
            root: {
              fontFamily: `${fonts.PressStart2P.fontFamily}`,
              fontWeight: 700,
              fontSize: 18,
              background: '#ffffff00',
              borderRadius: 0,
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: '#FFF9CF',
              color: '#FFF9CF',
              textTransform: 'uppercase',
              '&:hover': {
                borderWidth: 2,
              },
            },
            contained: {
              background: '#ffffff00',
              color: '#FFF9CF',
              borderRadius: 0,
              borderWidth: 2,
              borderColor: '#FFF9CF',
            },
            outlined: {
              background: '#ffffff00',
              color: '#FFF9CF',
              borderRadius: 32,
              borderWidth: 2,
              borderColor: '#FFF9CF',
            },
            text: {
              background: '#ffffff00',
              color: '#FFF9CF',
              borderRadius: 32,
              borderWidth: 2,
              borderColor: '#ffffff00',
            },
          },
        },
        MuiContainer: {
          styleOverrides: {
            root: {
              background: '#ffffff00',
            },
          },
        },
      },
    },
  },
  theme5: {
    name: 'Fresh',
    id: 'theme5',
    type: 'default',
    assets: {
      defaultCreatives: {
        videos: {
          'default video': {
            name: 'default video',
            type: 'video',
            url: 'https://ak.picdn.net/shutterstock/videos/1077627401/preview/stock-footage-aerial-car-factory-d-concept-automated-robot-arm-assembly-line-manufacturing-high-tech-green.webm',
          },
        },
        images: {
          'default background': {
            name: 'default background',
            type: 'background',
            url: images.fresh_1,
          },
          'default logo': {
            name: 'default logo',
            type: 'logo',
            url: images.concept_logo,
          },
          'default image 1': {
            name: 'default image 1',
            type: 'image',
            url: images.concept_1,
          },
          'default image 2': {
            name: 'default image 2',
            type: 'image',
            url: images.concept_1,
          },
          'default image 3': {
            name: 'default image 3',
            type: 'image',
            url: images.concept_1,
          },
          'default image 4': {
            name: 'default image 4',
            type: 'image',
            url: images.concept_1,
          },
        },
      },
    },
    theme: {
      palette: {
        primary: {
          main: '#FFF9CF',
          contrastText: '#1a1a1a',
        },
        secondary: {
          main: '#32985F',
          contrastText: '#FFE6EC',
        },
        background: {
          default: '#ffffff',
          paper: '#ffffff',
        },
        text: {
          primary: '#1a1a1a',
          secondary: '#1a1a1a',
        },
        divider: '#4e5252',
      },
      shape: {
        borderRadius: 0,
      },
      components: {
        MuiScopedCssBaseline: {
          styleOverrides: {
            root: `
              @font-face {
                font-family: ${fonts.PressStart2P.fontFamily};
                font-display: swap;
                font-weight: ${fonts.PressStart2P.fontWeight};
                src: url(${fonts.PressStart2P.source}) format('truetype');
              }
              @font-face {
                 font-family: ${fonts.FrederickaTheGreat.fontFamily};
                 font-display: swap;
                 font-weight: ${fonts.FrederickaTheGreat.fontWeight};
                 src: url(${fonts.FrederickaTheGreat.source}) format('truetype');
              }
            `,
          },
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              fontFamily: `${fonts.PressStart2P.fontFamily}`,
              fontWeight: 400,
              fontSize: 16,
              color: '#FFF9CF',
              background: '#ffffff00',
              textTransform: 'none',
              textAlign: 'left',
            },
            h1: {
              fontFamily: `${fonts.FrederickaTheGreat.fontFamily}`,
              fontSize: 40,
              color: '#FFF9CF',
              background: '#ffffff00',
              textTransform: 'none',
            },
            h2: {
              fontFamily: `${fonts.FrederickaTheGreat.fontFamily}`,
              fontSize: 32,
              color: '#FFF9CF',
              background: '#ffffff00',
              textTransform: 'none',
            },
            h3: {
              fontFamily: `${fonts.FrederickaTheGreat.fontFamily}`,
              fontSize: 22,
              color: '#FFF9CF',
              background: '#ffffff00',
              textTransform: 'none',
            },
          },
        },
        MuiButton: {
          defaultProps: {
            variant: 'outlined',
          },
          styleOverrides: {
            root: {
              fontFamily: `${fonts.PressStart2P.fontFamily}`,
              fontWeight: 700,
              fontSize: 18,
              background: '#ffffff00',
              borderRadius: 0,
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: '#FFF9CF',
              color: '#FFF9CF',
              textTransform: 'uppercase',
              '&:hover': {
                borderWidth: 2,
              },
            },
            contained: {
              background: '#ffffff00',
              color: '#FFF9CF',
              borderRadius: 0,
              borderWidth: 2,
              borderColor: '#FFF9CF',
              '&:hover': {
                background: '#ffffff00',
              },
            },
            outlined: {
              background: '#ffffff00',
              color: '#FFF9CF',
              borderRadius: 32,
              borderWidth: 2,
              borderColor: '#FFF9CF',
            },
            text: {
              background: '#ffffff00',
              color: '#FFF9CF',
              borderRadius: 32,
              borderWidth: 0,
              borderColor: '#ffffff00',
              '&:hover': {
                borderWidth: 0,
              },
            },
          },
        },
        MuiContainer: {
          styleOverrides: {
            root: {
              background: '#ffffff00',
            },
          },
        },
      },
    },
  },
};
