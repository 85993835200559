import AWS from 'aws-sdk';

class PublisherService {
  async publishCampaign(
    companyId: string | undefined,
    campaignId: string | undefined,
    token: string | undefined
  ) {
    const payload = {
      action: 'publish',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      body: {
        companyId: companyId,
        campaignId: campaignId,
      },
    };

    const config = {
      credentials: {
        accessKeyId: process.env.LAMBDA_PUBLISHER_ACCESS_KEY_ID ?? '',
        secretAccessKey: process.env.LAMBDA_PUBLISHER_SECRET_ACCESS_KEY ?? '',
      },
      apiVersion: 'latest',
      region: 'eu-central-1',
    };

    AWS.config.update(config);

    const lambda = new AWS.Lambda(config);

    const params = {
      FunctionName: 'publisher-lambda',
      InvocationType: 'RequestResponse',
      Payload: JSON.stringify(payload),
    };

    return lambda
      .invoke(params)
      .promise()
      .then((response) => {
        if (response.Payload) {
          return response;
        }
        console.log(response);
        return false;
      });
  }
}

export default new PublisherService();
