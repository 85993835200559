import { css } from '@emotion/react';

export const useStyles = () => ({
  block: css`
    position: relative;
  `,
  container: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  iconsContainer: css`
    display: flex;
    gap: 8px;
    position: absolute;
    top: 3px;
    right: 3px;
  `,
});
