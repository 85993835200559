/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useState } from 'react';
import { Eye } from 'phosphor-react';
import { camelCase } from 'lodash';
import {
  Button,
  Card,
  Grid,
  ScopedCssBaseline,
  Typography,
} from '@mui/material';
import { ButtonUnstyled } from '@mui/base';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { expandThemeWithFonts, ITheme } from '@brame/theme';
import { WizzardType } from '../../CampaignType/tabsData';
import { IWizzard } from '../../initialWizzardState';
import { useStyles } from './styles';

interface IThemeCard {
  wizzardState: IWizzard;
  handleProgressBar: (activeType: string) => void;
  theme: ITheme;
  onSelect: (stepName: string, payload: IWizzard) => void;
  active: boolean;
}

const ThemeCard: FC<IThemeCard> = ({
  wizzardState,
  handleProgressBar,
  theme,
  onSelect,
  active,
}) => {
  const { name, id } = theme;
  const styles = useStyles({ theme, active });
  const [visiblePreview, setVisiblePreview] = useState(false);

  const handleActiveCard = (active: boolean) => {
    if (active) {
      onSelect(WizzardType.CAMPAIGN_THEME, {
        ...wizzardState,
        [camelCase(WizzardType.CAMPAIGN_THEME)]: {
          themeId: '',
          isDisable: false,
        },
      });
      handleProgressBar(WizzardType.CAMPAIGN_DETAILS);
    } else {
      onSelect(WizzardType.CAMPAIGN_THEME, {
        ...wizzardState,
        [camelCase(WizzardType.CAMPAIGN_THEME)]: {
          themeId: id.toString(),
          isDisable: true,
        },
      });
      handleProgressBar(WizzardType.CAMPAIGN_THEME);
    }
  };

  return (
    <Grid item xs={12} sm={6} lg={4} xl={3} data-testid="theme-card">
      <Card
        css={styles.card}
        onMouseOver={() => setVisiblePreview(true)}
        onMouseOut={() => setVisiblePreview(false)}
      >
        <div css={styles.titleContainer}>
          <div css={styles.title} data-testid="card-title">
            {theme.name}
          </div>
          {(visiblePreview || active) && (
            <Button
              href="#"
              endIcon={<Eye weight="light" size={23} />}
              css={styles.previewButton}
              data-testid="preview-button"
            />
          )}
        </div>
        <div css={styles.themePreview} onClick={() => handleActiveCard(active)}>
          <ThemeProvider theme={createTheme(expandThemeWithFonts(theme))}>
            <ScopedCssBaseline hidden />
            <Typography
              variant="h2"
              css={styles.heading}
              data-testid="theme-name"
            >
              {name || 'Theme'}
            </Typography>
            <Typography variant="body1" css={styles.body}>
              Example of body text
            </Typography>
            <ButtonUnstyled css={styles.button}>Button</ButtonUnstyled>
          </ThemeProvider>
        </div>
      </Card>
    </Grid>
  );
};

export default ThemeCard;
