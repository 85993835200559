import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import CampaignService from '../../api-client/campaigns.service';
import {
  addGameArrayEntry,
  removeGameArrayEntry,
  setGameParam,
  changeAsset,
  changeGameType,
} from '../game/gameSlice';
import {
  addCanvasDimension,
  setCanvasDimensions,
  setCanvasResponsiveness,
} from '../shared/sharedSlice';
import {
  addElement,
  addPage,
  moveElement,
  movePage,
  removeElement,
  removePage,
  renameElement,
  resetElementDimensions,
  resetStyles,
  setSelectedTheme,
  updateElement,
  updatePage,
  updateStyles,
} from './builderSlice';
import { setAnalyticsTracking } from './analyticsSlice';

const listenerMiddleware = createListenerMiddleware();

const syncJson = process.env.REACT_APP_SERVER_SYNCING === 'true';

listenerMiddleware.startListening({
  matcher: isAnyOf(
    addElement,
    moveElement,
    renameElement,
    removeElement,
    addPage,
    updatePage,
    movePage,
    removePage,
    updateStyles,
    resetStyles,
    resetElementDimensions,
    setCanvasResponsiveness,
    setCanvasDimensions,
    addCanvasDimension,
    renameElement,
    removeElement,
    updateElement,
    setSelectedTheme,
    setGameParam,
    addGameArrayEntry,
    removeGameArrayEntry,
    changeAsset,
    changeGameType,
    setAnalyticsTracking
  ),
  effect: async (action, listenerApi) => {
    // eslint-disable-next-line
    const stateToUpload: any = await listenerApi.getState();
    const companyId = stateToUpload.auth.parsedToken.company_id;
    const campaignId = stateToUpload.campaign.selectedCampaign;
    if (syncJson) {
      // eslint-disable-next-line
      const stateToUpload: any = await listenerApi.getState();
      const concatState = {
        ...stateToUpload['elements'],
        ...stateToUpload['shared'],
        ...stateToUpload['analytics'],
        ...{ game: stateToUpload['game'] },
      };
      await CampaignService.putCampaignDataByCompanyAndCampaign(
        companyId,
        campaignId,
        concatState
      );
      // Can cancel other running instances
      listenerApi.cancelActiveListeners();
    }
  },
});

export default listenerMiddleware;
