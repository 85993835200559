/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { CaretLeft, X } from 'phosphor-react';
import { AppBar, Button, LinearProgress, useTheme } from '@mui/material';
import { useStyles } from './styles';

export interface IWizzardHeader {
  progressBarValue: number;
  title: string;
  activeStep: number;
  handleStepBack: () => void;
}

const WizzardHeader: FC<IWizzardHeader> = ({
  progressBarValue,
  title,
  handleStepBack,
  activeStep,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <div data-testid="wizzard-header">
      <AppBar css={styles.header} position="static" elevation={0}>
        <div css={styles.container}>
          <Button
            endIcon={<CaretLeft weight="light" size={23} />}
            disabled={!activeStep}
            onClick={() => handleStepBack()}
            css={styles.button}
          />
          <h1 css={styles.headerTitle} data-testid="wizzard-title">
            {title}
          </h1>
          <Button
            href="/"
            endIcon={<X weight="light" size={23} />}
            css={styles.button}
          />
        </div>
      </AppBar>
      <LinearProgress
        sx={{ background: 'transparent' }}
        variant="determinate"
        value={progressBarValue}
      />
    </div>
  );
};

export default WizzardHeader;
