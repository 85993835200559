/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import useAlert from '../../Alert/useAlert';
import { CustomError } from '../../../types';
import { useStyles } from './styles';
import useFileUploader from './useFileUploader';

const FILE_ERRORS = {
  WRONG_TYPE: 'File type is not allowed',
  READING_ABORTED: 'File reading was aborted',
  UPLOADING_FAILED: 'File uploading has failed',
  SIZE_LIMIT: 'Files bigger than 5 MB are not allowed',
};

const FileUploader: FC = () => {
  const { uploadFile, fileIsLoading } = useFileUploader();
  const { toast, openAlert } = useAlert();
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles && acceptedFiles.length) {
        acceptedFiles.forEach((file) => {
          const reader = new FileReader();

          reader.onabort = () =>
            openAlert(FILE_ERRORS.READING_ABORTED, 'error');
          reader.onerror = () =>
            openAlert(FILE_ERRORS.UPLOADING_FAILED, 'error');
          reader.onload = () =>
            uploadFile(file, reader.result)
              .then(() => {
                openAlert('File was successfully uploaded', 'success');
              })
              .catch((error: CustomError) => {
                const message =
                  error?.statusCode && error.statusCode === 413
                    ? FILE_ERRORS.SIZE_LIMIT
                    : error?.message || FILE_ERRORS.UPLOADING_FAILED;
                openAlert(message, 'error');
              });

          reader.readAsDataURL(file);
        });
      } else {
        openAlert(FILE_ERRORS.WRONG_TYPE, 'error');
      }
    },
    [openAlert, uploadFile]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.gif', '.jpeg', '.jpg', '.webp', '.bmp'],
    },
  });
  const styles = useStyles(isDragActive);

  return (
    <div css={styles.dropArea} {...getRootProps()}>
      <input {...getInputProps()} />
      <Typography css={styles.label}>
        {fileIsLoading
          ? 'File is uploading'
          : "Drag 'n' drop some files here, or click to select files"}
      </Typography>
      {fileIsLoading && <CircularProgress css={styles.spinner} size="1rem" />}
      {toast}
    </div>
  );
};

export default FileUploader;
