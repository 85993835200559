/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import useThemeData from '@brame/components/src/hooks/useThemeData';
import { ITheme } from '@brame/theme';
import { IWizzard } from '../initialWizzardState';
import { useStyles } from './styles';
import { TemplateCard } from './TemplateCard';
import { ITemplateState } from './templateTypes';

export interface ITemplates {
  template: ITemplateState;
  id: string;
  name: string;
}

export interface ICampaignTemplate {
  wizzardState: IWizzard;
  handleWizzardState: (stepName: string, payload: IWizzard) => void;
  handleProgressBar: (activeType: string) => void;
  customThemes: ITheme[];
  templates?: ITemplates;
}

const CampaignTemplate: FC<ICampaignTemplate> = ({
  wizzardState,
  handleWizzardState,
  handleProgressBar,
  customThemes,
  templates,
}) => {
  const currentTemplateId = wizzardState.campaignTemplate.templateId;

  const { themeCollection } = useThemeData();
  const allThemes: ITheme[] =
    Object.values(themeCollection).concat(customThemes);
  const currentTheme = allThemes.find(
    (theme) => theme.id === wizzardState.campaignTheme.themeId
  );
  const styles = useStyles(!wizzardState.campaignTemplate.isDisable);

  return (
    <div data-testid="campaign-template">
      <Typography css={styles.successText}>
        Everything is set up and ready to create
      </Typography>
      <div
        css={styles.templateContainer}
        data-testid="campaign-template-content"
      >
        <Grid css={styles.gridContainer} container spacing={2}>
          {templates &&
            Object.values(templates).map((template) => (
              <TemplateCard
                wizzardState={wizzardState}
                handleProgressBar={handleProgressBar}
                onSelect={handleWizzardState}
                currentTemplate={template}
                currentTheme={currentTheme}
                active={currentTemplateId === template.id}
                key={template.id}
              />
            ))}
        </Grid>
      </div>
    </div>
  );
};

export default CampaignTemplate;
