import { css } from '@emotion/react';

export const useStyles = () => ({
  dialog: css`
    width: 460px;
    max-width: 100%;
  `,
  dialogTitle: css`
    font-size: 22px;
  `,
  dialogContent: css`
    font-size: 15px;
  `,
  dialogButtons: css`
    padding: 0 24px 24px;

    & > :not(:first-of-type) {
      margin-left: 16px;
    }
  `,
});
