import { useCallback } from 'react';
import Paper from '@mui/material/Paper';
import { renderTree } from '@brame/components/src/utils/renderComponentsTree';
import {
  elementsSelectors,
  selectCurrentRootId,
} from '../../reducers/builder/builderSlice';
import { dndWrapper } from '../DragNDropWrapper';
import RenderIndicator from '../DragNDropWrapper/RenderIndicator';
import { useAppSelector } from '../../reducers/app/hooks';
import {
  getBreakpoints,
  getCanvasDimensions,
  getCanvasResponsiveness,
  getSelectedBreakpoint,
} from '../../reducers/shared/sharedSlice';
import useDeleteKey from './useDeleteKey';

const Canvas = () => {
  const elements = useAppSelector(elementsSelectors.selectEntities);
  const pageRootId = useAppSelector(selectCurrentRootId)!;
  const activeBreakpoint = useAppSelector(getSelectedBreakpoint);
  const breakpoints = useAppSelector(getBreakpoints);
  const currentTheme = useAppSelector((state) => state.theme.currentTheme);
  const isResponsive = useAppSelector(getCanvasResponsiveness);
  const canvasDimensions = useAppSelector(getCanvasDimensions);
  useDeleteKey();

  const canvasWidth = isResponsive
    ? `${activeBreakpoint.breakpoint}px`
    : `${canvasDimensions.width}px`;
  const canvasHeight = isResponsive ? null : `${canvasDimensions.height}px`;
  const canvasMinHeight = isResponsive ? '920px' : null;

  const renderTreeStructure = useCallback(() => {
    return renderTree({
      rootElementId: pageRootId,
      elements,
      wrapper: dndWrapper,
      breakpoints,
      activeBreakpoint,
      theme: currentTheme,
      builderMode: true,
    });
  }, [activeBreakpoint, breakpoints, elements, pageRootId, currentTheme]);

  return (
    <>
      <Paper
        sx={{
          minHeight: canvasMinHeight,
          overflowY: 'visible',
          display: 'flex',
          alignItems: 'stretch',
          width: canvasWidth,
          height: canvasHeight,
          margin: '0 auto',
        }}
        square
        data-testid="canvas"
      >
        {renderTreeStructure()}
      </Paper>
      <RenderIndicator />
    </>
  );
};

export default Canvas;
