import { css } from '@emotion/react';

export const useStyles = () => ({
  title: css`
    display: block;
    text-transform: capitalize;
  `,
  container: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
});
