/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, Fragment, useRef, useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import {
  IPageRenameModel,
  PageElement,
  PageType,
} from '@brame/builder/src/reducers/builder';
import { Page } from './Page';
import { useStyles } from './styles';
import { AddPageButton } from './AddPageButton';
import { PageDropArea } from './PageDropArea';
import { AddPagePopper } from './AddPagePopper';
import GroupedPages from './GroupedPages/GroupedPages';

export interface IProperties {
  pages: PageElement[];
  currentPageId: EntityId;
  onSelectPage: (id: EntityId) => void;
  onRemovePage: (id: EntityId) => void;
  onRenamePage: (data: IPageRenameModel) => void;
  onAddPage: (type: PageType) => void;
  isAdditional?: boolean;
}

const PageNavigator: FC<IProperties> = ({
  pages,
  currentPageId,
  onSelectPage,
  onRemovePage,
  onRenamePage,
  onAddPage,
  isAdditional,
}) => {
  const styles = useStyles();

  const handleAddPage = (type: PageType): void => {
    setAnchorEl(null);
    onAddPage(type);
  };

  const ref = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleScroll = (e: React.WheelEvent<HTMLElement>) => {
    if (ref.current && isScrollable) {
      // @ts-ignore
      ref.current.scrollLeft += e.deltaY;
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const outcomes = pages.filter((page) => page.pageType === PageType.Outcome);
  const basics = pages.filter((page) => page.pageType !== PageType.Outcome);

  return (
    <div css={styles.container} data-testid="page-navigator">
      <Fragment>
        <div
          css={styles.pageScroller}
          ref={ref}
          onMouseEnter={() => setIsScrollable(true)}
          onMouseLeave={() => setIsScrollable(false)}
          onWheel={handleScroll}
        >
          <div css={styles.pageScrollerContent}>
            {basics?.map((page, index) => (
              <div
                key={page.id}
                style={{ position: 'relative', display: 'flex' }}
              >
                <PageDropArea pageIndex={index} />
                <Page
                  page={page}
                  selected={page.id === currentPageId}
                  onSelectPage={onSelectPage}
                  onRemovePage={onRemovePage}
                  onRenamePage={onRenamePage}
                  index={index + 1}
                />
                {index === basics.length - 1 && (
                  <PageDropArea pageIndex={index + 1} />
                )}
              </div>
            ))}

            {!!outcomes.length && (
              <GroupedPages
                group={outcomes}
                title="Results"
                currentPageId={currentPageId}
                onSelectPage={onSelectPage}
                onRemovePage={onRemovePage}
                onRenamePage={onRenamePage}
              />
            )}
          </div>
        </div>
        <div css={styles.addPageButton}>
          <AddPageButton onAddPage={handleClick} active={open} />
        </div>
      </Fragment>

      <AddPagePopper
        open={open}
        anchorEl={anchorEl}
        onPageAdd={handleAddPage}
        isAdditional={isAdditional}
      />
    </div>
  );
};

export default PageNavigator;
