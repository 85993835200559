export interface IWizzard {
  campaignType: {
    gameId: string;
    isDisable: boolean;
  };
  campaignDetails: {
    name: string;
    description: string;
    isDisable: boolean;
  };
  campaignTheme: {
    themeId: string;
    isDisable: boolean;
  };
  campaignTemplate: {
    templateId: string;
    isDisable: boolean;
  };
}

export const initialWizzardState: IWizzard = {
  campaignType: {
    gameId: '',
    isDisable: true,
  },
  campaignDetails: {
    name: '',
    description: '',
    isDisable: true,
  },
  campaignTheme: {
    themeId: '',
    isDisable: true,
  },
  campaignTemplate: {
    templateId: '',
    isDisable: true,
  },
};
