//@ts-nocheck
import { css } from '@emotion/react';
import { ITheme } from '@brame/theme';

interface IProps {
  theme: ITheme;
  active: boolean;
}

export const useStyles = ({ theme, active }: IProps) => ({
  card: css`
    border: ${active ? `1px solid #00000099` : `1px solid transparent`};
    box-shadow: ${
      active
        ? `7px 7px 10px rgba(0 0 0 / 25%);`
        : `7px 7px 10px rgba(255, 255, 255, 1);`
    }
    padding: 5px 15px 15px;
  `,
  titleContainer: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 6px 0 10px;
    transform: ${active ? `scale(1.05)` : `scale(1)`};
    transform-origin: top center;
    transition: all 0.3s ease;
  `,
  title: css`
    color: #00000099;
    padding: 0 8px 2px;
  `,
  previewButton: css`
    color: #00000099;
    padding: 0;
    transition: all 0.3s ease;
    min-width: 35px;
    &:hover {
      background-color: transparent;
    }
    span {
      margin: 0;
    }
    a {
      padding: 0;
    }
  `,
  themePreview: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    justify-content: space-around;
    border-radius: 5px;
    min-height: 135px;
    cursor: pointer;
    background: ${theme.theme.palette?.background?.paper}
      url(${
        theme.assets?.defaultCreatives?.images?.['default background']
          ? theme.assets?.defaultCreatives?.images?.['default background']?.url
          : theme.assets?.defaultCreatives?.images?.['default background 1']
              ?.url
      })
      center no-repeat;
    background-size: cover;
    box-shadow: ${
      active
        ? `7px 7px 10px rgba(255, 255, 255, 1);`
        : `7px 7px 10px rgba(0 0 0 / 25%);`
    }
    transform: ${active ? `scale(1.05)` : `scale(1)`};
    transform-origin: top center;
    transition: all 0.3s ease;
    padding: 10px;
  `,
  heading: css`
    font-family: ${theme.theme.components?.MuiTypography?.styleOverrides?.h2
      ?.fontFamily};
    font-size: ${theme.theme.components?.MuiTypography?.styleOverrides?.h2
      ?.fontSize}px;
    background: ${theme.theme.components?.MuiTypography?.styleOverrides?.h2
      ?.background};
    text-transform: ${theme.theme.components?.MuiTypography?.styleOverrides?.h2
      ?.textTransform};
    color: ${theme.theme.palette?.primary?.main};
    width: 100%;
    text-align: center;
  `,
  body: css`
    font-family: ${theme.theme.components?.MuiTypography?.styleOverrides?.root
      ?.fontFamily};
    background: ${theme.theme.components?.MuiTypography?.styleOverrides?.root
      ?.background};
    text-transform: ${theme.theme.components?.MuiTypography?.styleOverrides
      ?.root?.textTransform};
    color: ${theme.theme.palette?.text?.primary};
    font-size: 1rem;
    width: 100%;
    text-align: center;
  `,
  button: css`
    font-family: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.fontFamily};
    font-weight: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.fontWeight};
    font-size: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.fontSize}px;
    background: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.background || theme.theme.palette?.primary?.main};
    border-radius: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.borderRadius}px;
    border-width: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.borderWidth}px;
    border-style: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.borderStyle};
    border-color: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.borderColor};
    color: ${theme.theme.components?.MuiButton?.styleOverrides?.root?.color};
    text-transform: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.textTransform};
    pointer-events: none;
    padding: 6px 16px;
    line-height: 1.75;
  `,
});
