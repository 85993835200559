import { BuilderComponent } from '../reducers/builder';

export enum AssetType {
  VIDEO = 'video',
  GAME = 'game',
  PHOTO = 'photo',
  // FONT = 'font',
  LOGO = 'logo',
}

export enum ThemedAssetType {
  IMAGE = 'themedImage',
  VIDEO = 'themedVideo',
}

export enum AssetScope {
  GLOBAL = 'global',
  BRAME = 'brame',
  PERSONAL = 'personal',
}

export interface AssetTypeTab {
  label: string;
  assetType: AssetType;
  elementTypes: AssetElements[];
  scopes: AssetScope[];
}

export interface IAsset {
  uuid: string | number;
  name: string;
  url: string;
  urlSmall?: string;
  labels?: string[];
}

export interface IPersonalAsset {
  assetId: string;
  name: string;
  url: string;
  extension?: string;
  labels: string[];
}

export interface IAssetData extends IAsset {
  uuid: string;
  scope: AssetScope;
  type: AssetType;
}

export type AssetElements =
  | BuilderComponent.VIDEO
  | BuilderComponent.IMAGE
  | BuilderComponent.CONTAINER;
