/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import {
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useFormEditorStyles } from '../../styles';

export interface IProps {
  props: any;
}

const EditTextarea: FC<IProps> = ({ props }) => {
  const styles = useFormEditorStyles();

  return (
    <div>
      <div css={styles.container}>
        <FormControl fullWidth>
          <Typography css={styles.label}>
            Required minimum characters
          </Typography>
          <TextField
            type="number"
            size="small"
            name="minCharacters"
            value={props.values.minCharacters}
            onChange={props.handleChange}
            fullWidth
          />
        </FormControl>
      </div>
      <div css={styles.container}>
        <FormControl fullWidth sx={{ paddingTop: '8px' }}>
          <TextField
            type="number"
            size="small"
            name="rows"
            id="rows"
            value={props.values.rows}
            label="Area size"
            fullWidth
            InputProps={{
              inputProps: { min: 0 },
              sx: { paddingRight: '10px' },
              endAdornment: (
                <InputAdornment position="end">rows</InputAdornment>
              ),
            }}
            onChange={props.handleChange}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default EditTextarea;
