/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { FC } from 'react';
import { camelCase } from 'lodash';
import { TextField } from '@mui/material';
import { WizzardType } from '../CampaignType/tabsData';
import { IWizzard } from '../initialWizzardState';
import { useStyles } from './styles';

export interface ICampaignDetails {
  wizzardState: IWizzard;
  handleWizzardState: (stepName: string, payload: IWizzard) => void;
  handleProgressBar: (activeType: string) => void;
}

const CampaignDetails: FC<ICampaignDetails> = ({
  wizzardState,
  handleWizzardState,
  handleProgressBar,
}) => {
  const styles = useStyles();

  const setProgressBarValue = (value: string) => {
    if (value.length) {
      handleProgressBar(WizzardType.CAMPAIGN_DETAILS);
    } else {
      handleProgressBar(WizzardType.CAMPAIGN_TYPE);
    }
  };

  return (
    <div css={styles.formContainer} data-testid="campaign-details-content">
      <form css={styles.form}>
        <div css={styles.title}>Campaign Name</div>
        <TextField
          fullWidth
          data-testid="company-name"
          inputProps={{ maxLength: 50, style: { fontSize: 14 } }}
          placeholder="E.g. My Campaign"
          value={wizzardState.campaignDetails.name}
          onChange={(e) => {
            handleWizzardState(WizzardType.CAMPAIGN_DETAILS, {
              ...wizzardState,
              [camelCase(WizzardType.CAMPAIGN_DETAILS)]: {
                name: e.target.value,
                description: wizzardState.campaignDetails.description,
                isDisable: false,
              },
            });
            setProgressBarValue(e.target.value);
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& > fieldset': { borderColor: '#0000004D' },
            },
            '& .MuiOutlinedInput-root.Mui-focused fieldset': {
              borderColor: '#00000099',
            },
            '& .MuiInputBase-input': {
              padding: '12px 20px',
            },
            marginBottom: '40px',
          }}
        />
        <div css={styles.title}>Campaign description</div>
        <TextField
          fullWidth
          data-testid="company-description"
          multiline
          rows={9}
          inputProps={{ maxLength: 300, style: { fontSize: 14 } }}
          placeholder="You can use it to destinguish it from other campaigns, and players will not be able to see this info."
          value={wizzardState.campaignDetails.description}
          onChange={(e) =>
            handleWizzardState(WizzardType.CAMPAIGN_DETAILS, {
              ...wizzardState,
              [camelCase(WizzardType.CAMPAIGN_DETAILS)]: {
                name: wizzardState.campaignDetails.name,
                description: e.target.value,
              },
            })
          }
          sx={{
            '& .MuiOutlinedInput-root': {
              '& > fieldset': { borderColor: '#0000004D' },
            },
            '& .MuiOutlinedInput-root.Mui-focused fieldset': {
              borderColor: '#000000',
            },
          }}
        />
      </form>
    </div>
  );
};

export default CampaignDetails;
