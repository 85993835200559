import { createSlice } from '@reduxjs/toolkit';
import { parseJwt } from '../../auth/utils';
import { fetchCampaignMetaDataAsync } from '../builder/asyncThunks';
import { RootState } from '../store';
import { authStateType } from './types';

const initialState: authStateType = {
  token: '',
  status: 'loading',
  parsedToken: {},
  campaignMetaData: {},
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state: authStateType, action) => {
      if (!action.payload || action.payload === 'loggedOut') {
        state.status = 'loggedOut';
      } else {
        state.token = action.payload;
        state.parsedToken = parseJwt(action.payload);
        state.status = 'loggedIn';
      }
    },
    setCampaignMetaData: (state: authStateType, action) => {
      state.campaignMetaData = action.payload;
    },
  },
  /* Async reducers => fetch */
  extraReducers: (builder) => {
    builder.addCase(fetchCampaignMetaDataAsync.fulfilled, (state, action) => {
      state.campaignMetaData = action.payload.data;
    });
  },
});

export const { setAuth, setCampaignMetaData } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export const selectCampaign = (state: RootState) => state.auth.campaignMetaData;

export default authSlice.reducer;
