import { css } from '@emotion/react';

export const useStyles = () => {
  return {
    pageContainer: css`
      overflow: hidden;
    `,
    contentContainer: css`
      max-width: 1100px;
      width: 100%;
      margin: 0 auto;
      overflow: hidden;
    `,
    buttonContainer: css`
      display: flex;
      justify-content: center;
    `,
    button: css`
      max-width: 120px;
      width: 100%;
      margin: 10px;
    `,
  };
};
