import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';

export const useStyles = () => ({
  widget: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  itemWrapper: css`
    display: flex;
    gap: 8px;
    align-items: flex-start;
  `,
  gap10: css`
    gap: 10px;
  `,
  shapeSelect: css`
    width: 170px;
    text-transform: capitalize;
  `,
  menuItem: css`
    text-transform: capitalize;
  `,
  title: css`
    display: block;
    margin-bottom: 8px;
    text-transform: capitalize;
  `,
  styleControl: css`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `,
  widthSelect: css`
    width: 80px;
  `,
  widgetItem: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  button: css`
    border: none;
    border-radius: ${brameTheme.shape.borderRadius}px !important;
    width: 36px;
    height: 36px;
    padding: 0;

    &.Mui-selected {
      background: ${brameTheme.palette.primary.main};
      * {
        border-color: #ffffff;
      }

      &:hover {
        background: ${brameTheme.palette.primary.main};
        * {
          border-color: #ffffff;
        }
      }
    }
  `,
  buttonShape: css`
    width: 25px;
    height: 15px;
    border-width: 2px;
    border-style: solid;
  `,
});
