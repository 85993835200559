/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { ITheme } from '@brame/theme';
import CampaignService from '../../api-client/campaigns.service';
import { useAppSelector } from '../../reducers/app/hooks';
import { selectAuth } from '../../reducers/auth/authSlice';
import { defaultCanvasFormats } from '../../components/CanvasFormats/fixtures';
import useTemplate from '../../templates/useTemplate';
import { ICanvasDimensions } from '../../reducers/shared/types';
import { normaliseTemplateData } from '../../reducers/builder/utils';
import { useTemplatesData } from '../../hooks/useTemplatesData';
import { campaignMetaDataIProps } from '../../reducers/auth/types';
import { useStyles } from './styles';
import { WizzardHeader } from './WizzardHeader';
import { CampaignType } from './CampaignType';
import { useProgressBar } from './hooks/useProgressBar';
import { WizzardType } from './CampaignType/tabsData';
import { CampaignDetails } from './CampaignDetails';
import { useWizzardState } from './hooks/useWizardState';
import { CampaignTheme } from './CampaignTheme';
import { CampaignTemplate } from './CanpaignTemplate';

const Wizzard: FC = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [activeType, setActiveType] = useState<string>('');
  const [disabled, setDisabled] = useState(false);
  const [customThemes, setCustomThemes] = useState<ITheme[]>([]);
  const [availableTemplates, setAvailableTemplates] = useState();
  const { getTemplatesData } = useTemplatesData();
  const authState = useAppSelector(selectAuth);
  const canvasDimensions: ICanvasDimensions = { width: 600, height: 300 };
  const { prepareTemplate } = useTemplate({
    fixed: false,
    dimensions: canvasDimensions,
  });

  const steps = [
    WizzardType.CAMPAIGN_TYPE,
    WizzardType.CAMPAIGN_DETAILS,
    WizzardType.CAMPAIGN_THEME,
    WizzardType.CAMPAIGN_TEMPLATE,
  ];
  const { progressBarValue } = useProgressBar(activeType);
  const { wizzardState, handleWizardState } = useWizzardState();

  useEffect(() => {
    const loadTemplates = async () => {
      const templateData = await getTemplatesData();
      const templates = normaliseTemplateData(templateData);
      setAvailableTemplates(templates);
    };
    loadTemplates();
  }, []);

  const handleProgressBar = (chosenType: string) => {
    setActiveType(chosenType);
  };

  const renderStepContent = (stepNumber: number) => {
    switch (stepNumber) {
      case 0:
        return (
          <CampaignType
            wizzardState={wizzardState}
            handleWizzardState={handleWizardState}
            handleProgressBar={handleProgressBar}
          />
        );
      case 1:
        return (
          <CampaignDetails
            wizzardState={wizzardState}
            handleWizzardState={handleWizardState}
            handleProgressBar={handleProgressBar}
          />
        );
      case 2:
        return (
          <CampaignTheme
            wizzardState={wizzardState}
            handleWizzardState={handleWizardState}
            handleProgressBar={handleProgressBar}
            handleCustomThemes={setCustomThemes}
          />
        );
      case 3:
        if (
          availableTemplates &&
          Object.values(availableTemplates).length !== 0
        ) {
          return (
            <CampaignTemplate
              wizzardState={wizzardState}
              handleWizzardState={handleWizardState}
              handleProgressBar={handleProgressBar}
              customThemes={customThemes}
              templates={availableTemplates}
            />
          );
        }
        break;
      default:
        return <div>Not Found</div>;
    }
  };

  const handleStepBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };
  const handleStepNext = () => {
    setDisabled(true);
    if (activeStep < 4) {
      setActiveStep(activeStep + 1);
    }
    setDisabled(false);
  };

  const loadCampaigns = async () => {
    const campaigns = await CampaignService.getAllCampaignsByCompany(
      authState.parsedToken.company_id
    );

    let filterCampaigns = campaigns.data.filter(
      (campaign: campaignMetaDataIProps) => campaign.status !== 'DELETED'
    );
    filterCampaigns = filterCampaigns.sort((a: any, b: any) =>
      a.createdDate > b.createdDate ? -1 : b.createdDate > a.createdDate ? 1 : 0
    );
    return filterCampaigns;
  };

  const creatingCampaignHandler = async () => {
    setDisabled(true);

    const res = await CampaignService.createCampaignByCompany(
      authState.parsedToken.company_id,
      wizzardState.campaignDetails.name
    );
    if (
      res.message === 'Successfully saved campaign details' &&
      availableTemplates
    ) {
      await CampaignService.postInitialCampaignDataByCompanyAndCampaign(
        authState.parsedToken.company_id,
        res.data.id,
        {
          ...prepareTemplate(
            availableTemplates[wizzardState.campaignTemplate.templateId]
          ).template,
          canvasResponsiveness: true,
          canvasDimensions,
          dimensions: defaultCanvasFormats,
          selectedTheme: wizzardState.campaignTheme.themeId,
        }
      );
    }
    const campaigns = await loadCampaigns();
    if (campaigns) {
      navigate(`/${campaigns[0].id}`);
    }

    setDisabled(false);
  };

  return (
    <div data-testid="wizzard" css={styles.pageContainer}>
      <WizzardHeader
        progressBarValue={progressBarValue}
        title={steps[activeStep]}
        activeStep={activeStep}
        handleStepBack={handleStepBack}
      />
      <div css={styles.contentContainer}>{renderStepContent(activeStep)}</div>
      <div css={styles.buttonContainer}>
        <Button
          disabled={
            disabled || Object.values(wizzardState)[activeStep].isDisable
          }
          variant="contained"
          css={styles.button}
          onClick={() => {
            activeStep < 3 ? handleStepNext() : creatingCampaignHandler();
          }}
        >
          {activeStep === 3 ? 'Create' : 'Next'}
        </Button>
      </div>
    </div>
  );
};

export default Wizzard;
