/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useState } from 'react';
import { PlusCircle } from 'phosphor-react';
import {
  Button,
  IconButton,
  Popper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useAppDispatch } from '../../reducers/app/hooks';
import { addCanvasDimension } from '../../reducers/shared/sharedSlice';
import { ICanvasDimensions } from '../../reducers/shared/types';
import { CanvasFormat } from './CanvasFormat';
import { useStyles } from './styles';

interface IProps {
  formats: ICanvasDimensions[];
  currentFormat: ICanvasDimensions;
  onChange: (format: ICanvasDimensions) => void;
}

const CanvasFormats: FC<IProps> = ({ formats, currentFormat, onChange }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [newFormat, setNewFormat] = useState({ width: 100, height: 100 });
  const popperOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const confirmAddFormat = () => {
    dispatch(addCanvasDimension(newFormat));
    setAnchorEl(null);
  };

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const styles = useStyles();

  return (
    <div css={styles.formatsContainer} data-testid="canvas-formats">
      <div css={styles.formatsBox}>
        {formats.map((format) => (
          <CanvasFormat
            format={format}
            active={format === currentFormat}
            onClick={onChange}
            key={`${format.width}x${format.height}`}
          />
        ))}
      </div>

      <IconButton onClick={handleClick}>
        <PlusCircle size={20} color={theme.palette.primary.main} />
      </IconButton>

      <Popper
        open={popperOpen}
        anchorEl={anchorEl}
        placement="bottom"
        css={styles.popper}
      >
        <div>
          <Typography>Chose canvas size</Typography>
          <div css={styles.popperBox}>
            <TextField
              value={newFormat.width}
              css={styles.popperInput}
              type="number"
              label="Width"
              onChange={(e) =>
                setNewFormat({ ...newFormat, width: Number(e.target.value) })
              }
            />
            <TextField
              value={newFormat.height}
              css={styles.popperInput}
              type="number"
              label="Height"
              onChange={(e) =>
                setNewFormat({ ...newFormat, height: Number(e.target.value) })
              }
            />
          </div>
          <div css={[styles.popperBox, styles.flexEnd]}>
            <Button
              variant="text"
              size="small"
              onClick={() => setAnchorEl(null)}
            >
              Cancel
            </Button>
            <Button variant="contained" size="small" onClick={confirmAddFormat}>
              OK
            </Button>
          </div>
        </div>
      </Popper>
    </div>
  );
};

export default CanvasFormats;
