/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { useCallback, useRef } from 'react';
import { DragPreviewImage } from 'react-dnd';
import useThemeData from '@brame/components/src/hooks/useThemeData';
import { getMediaQueryStyles } from '@brame/components/src/utils/renderComponentsTree';
import { BuilderComponent, CanvasElement } from '../../reducers/builder';
import { useAppDispatch, useAppSelector } from '../../reducers/app/hooks';
import { removeElement } from '../../reducers/builder/builderSlice';
import FileIcon from '../../assets/componentsPreviewIcons/file.png';
import ContainerIcon from '../../assets/componentsPreviewIcons/container.png';
import ImageIcon from '../../assets/componentsPreviewIcons/image.png';
import ButtonIcon from '../../assets/componentsPreviewIcons/button.png';
import VideoIcon from '../../assets/componentsPreviewIcons/video.png';
import TextIcon from '../../assets/componentsPreviewIcons/text.png';
import FormIcon from '../../assets/componentsPreviewIcons/form.png';
import GameIcon from '../../assets/componentsPreviewIcons/game.png';
import {
  getBreakpoints,
  getSelectedBreakpoint,
} from '../../reducers/shared/sharedSlice';
import { buildDefaultStylesFromTheme } from '../RightPanel/Editors/utils';
import ResizablePortal from '../Resizable/ResizablePortal';
import { resetGameSettings } from '../../reducers/game/gameSlice';
import {
  useDraggableElement,
  useDroppableContainer,
  useSelectElement,
} from './hooks';
import { DraggableElementsType } from './types';
import DnDLabel from './DnDLabel';

const previewImageMap: { [key: string]: string } = {
  [BuilderComponent.CONTAINER]: ContainerIcon,
  [BuilderComponent.IMAGE]: ImageIcon,
  [BuilderComponent.BUTTON]: ButtonIcon,
  [BuilderComponent.VIDEO]: VideoIcon,
  [BuilderComponent.TEXT]: TextIcon,
  [BuilderComponent.FORM]: FormIcon,
  [BuilderComponent.PHASER_GAME]: GameIcon,
  [BuilderComponent.PHASER_TEXT]: GameIcon,
  [BuilderComponent.PHASER_BUTTON]: GameIcon,
} as const;

const canDropStyle = { backgroundColor: 'rgba(0,0,255,0.4)' };
const selectedStyle = { outline: '2px solid #0085FF !important' };

export const dndWrapper =
  (Component: DraggableElementsType) =>
  ({ element, ...props }: { element: CanvasElement & { index: number } }) => {
    const dispatch = useAppDispatch();
    const ref = useRef<HTMLElement>(null);
    const { selected, onSelect } = useSelectElement(element);
    const { drag, dragPreview } = useDraggableElement(element);
    //@ts-ignore
    const { canDrop, isOver } = useDroppableContainer(element, ref);
    drag(ref);

    const { currentTheme } = useThemeData();
    const activeBreakpoint = useAppSelector(getSelectedBreakpoint);
    const breakpoints = useAppSelector(getBreakpoints);

    const mediaQueryStyles = getMediaQueryStyles({
      breakpoints,
      activeBreakpoint,
      responsive: element?.styles?.responsive,
    });

    const styleProps = {
      ...buildDefaultStylesFromTheme(element, currentTheme),
      ...element?.styles?.defaults,
      ...element?.styles?.overrides,
      ...mediaQueryStyles,
    };

    const onDelete = useCallback(() => {
      if (element.type === BuilderComponent.PHASER_GAME) {
        //@ts-ignore
        dispatch(resetGameSettings());
      }
      dispatch(removeElement(element.id));
    }, [dispatch, element]);

    // @ts-ignore
    const wrapperStyles = {};
    if (isOver && canDrop) {
      Object.assign(wrapperStyles, canDropStyle);
    }
    if (element.type === BuilderComponent.ROOT_CONTAINER) {
      Object.assign(wrapperStyles, { minHeight: '100%' });
    }
    if (
      element.type === BuilderComponent.ROOT_CONTAINER ||
      element.type === BuilderComponent.CONTAINER ||
      element.type === BuilderComponent.TEXT
    ) {
      Object.assign(wrapperStyles, { outline: '1px dashed lightgrey' });
    }
    if (selected && element.type === BuilderComponent.ROOT_CONTAINER) {
      Object.assign(wrapperStyles, { outline: '2px solid #0085ff' });
    }

    return (
      <React.Fragment>
        <DragPreviewImage
          connect={dragPreview}
          src={previewImageMap[element.type] || FileIcon}
        />
        {!!ref.current && selected && (
          <DnDLabel
            id={`selected-${element.id}`}
            anchorEl={ref.current}
            elementType={element.type}
            onDelete={onDelete}
            selected={selected}
            elementName={element.name}
          />
        )}

        {selected && element.type !== BuilderComponent.ROOT_CONTAINER && (
          <ResizablePortal
            isOpen
            element={element}
            styleProps={styleProps}
            elementRef={ref}
          />
        )}

        <Component
          //@ts-ignore
          ref={ref}
          onClick={onSelect}
          {...props}
          style={wrapperStyles}
          id={element.id as string}
          //@ts-ignore
          element={element}
          elementStyles={styleProps}
          currentTheme={currentTheme}
        />
      </React.Fragment>
    );
  };

export const libraryElementsWrapper =
  (Component: any) =>
  ({
    elementType,
    option,
    ...props
  }: {
    elementType: BuilderComponent;
    option?: { variant: string };
  }) => {
    const ref = useRef(null);
    const { drag, dragPreview } = useDraggableElement({
      type: elementType,
      option: option,
    } as CanvasElement);
    drag(ref);

    return (
      <React.Fragment>
        <DragPreviewImage
          connect={dragPreview}
          src={previewImageMap[elementType] || FileIcon}
        />
        {/*@ts-ignore*/}
        <Component ref={ref} {...props} />
      </React.Fragment>
    );
  };
