import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';

export const useStyles = () => ({
  button: css`
    border: none;
    border-radius: ${brameTheme.shape.borderRadius}px !important;
    width: 36px;
    height: 36px;
    padding: 0;

    &.Mui-selected {
      background: ${brameTheme.palette.primary.main};

      &:hover {
        background: ${brameTheme.palette.primary.main};
      }
    }
  `,
  wrapper: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
});
