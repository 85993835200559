import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const useStyles = (theme: Theme) => ({
  titleBox: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  toolbarTitle: css`
    text-transform: capitalize;
    margin-bottom: 8px;
    font-size: 16px;
    color: ${theme.palette.text.primary};
    font-weight: 700;
  `,
  itemsWrapper: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
});
