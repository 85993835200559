/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { LinkSimpleHorizontal } from 'phosphor-react';
import { useTheme, ToggleButton } from '@mui/material';
import { useStyles } from './styles';

interface IProps {
  selected: boolean;
  onChange: () => void;
}

const LinkPropsButton: FC<IProps> = ({ selected, onChange }) => {
  const styles = useStyles();
  const theme = useTheme();

  return (
    <ToggleButton
      value={selected}
      selected={selected}
      css={styles.toggleButton}
      disableRipple
      onChange={onChange}
      data-testid="link-props-button"
    >
      <LinkSimpleHorizontal
        size={25}
        color={
          selected ? theme.palette.primary.main : theme.palette.text.primary
        }
      />
    </ToggleButton>
  );
};

export default LinkPropsButton;
