import { css } from '@emotion/react';

export const useStyles = () => ({
  widget: css`
    margin-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  `,
  select: css`
    text-transform: capitalize;
  `,
  menuItem: css`
    text-transform: capitalize;
  `,
  title: css`
    display: block;
    margin-bottom: 8px;
    text-transform: capitalize;
  `,
  styleControl: css`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `,
  widthSelect: css`
    width: 80px;
  `,
  widgetItem: css`
    width: 100%;
  `,
});
