import { css } from '@emotion/react';

export const useStyles = () => ({
  container: css`
    margin-bottom: 8px;
  `,
  title: css`
    margin-bottom: 8px;
  `,
});
