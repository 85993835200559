import axios from 'axios';
import tokenService from '../auth/token.service';

// TODO: Interceptor should actually be used but causes cors issues since the header is changed on creating the request.
// Now if you log in the first time the axios instance is created with the token even it is unset, so you get Bearer unedfined
// Its bascially just a problem of having the Axios instance statically initialized
const axiosI = axios.create({
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${tokenService.getCookieAccessToken()}`,
  },
});

// axiosI.interceptors.request.use(
//   (config): AxiosRequestConfig => {
//     const token = tokenService.getCookieAccessToken();

//     if (token) {
//       // eslint-disable-next-line
//       config.headers!.Authorization = `Bearer ${token}`;
//     } else {
//       delete axiosI.defaults.headers.common.Authorization;
//     }
//     return config;
//   },

//   (error) => Promise.reject(error)
// );

export default axiosI;
