import { v4 as uuidv4 } from 'uuid';
import { EntityId } from '@reduxjs/toolkit';
import {
  defaultFormFields,
  defaultTermsAndConditionFields,
} from '@brame/components/src/components/Form';
import { ICanvasDimensions } from '../shared/types';
import { defaultTemplates } from '../../templates';
import { ElementsState } from './builderSlice';
import {
  BuilderComponent,
  ButtonActionType,
  CanvasElement,
  ContainerElement,
  ITemplateData,
  ITemplateDataEntry,
  PageElement,
  PageType,
  IBuilderTemplate,
  TextVariant,
} from './types';
import { defaultElementStyles } from './fixtures';

export const generateId = () => {
  return uuidv4();
};

export const generatePageId = () => {
  return `page-${generateId()}`;
};

export const createRootElement = (
  dimensions?: ICanvasDimensions
): CanvasElement => {
  return {
    id: generatePageId(),
    childrenIds: [],
    type: BuilderComponent.ROOT_CONTAINER,
    styles: {
      defaults: defaultElementStyles.rootContainer,
      overrides: {
        ...(dimensions && {
          size: {
            width: { value: dimensions.width, unit: 'px' },
            height: { value: dimensions.height, unit: 'px' },
          },
        }),
      },
    },
  };
};

export const createCanvasElement = (
  parentId: EntityId,
  type: BuilderComponent,
  additionalProps?: any
): CanvasElement => {
  const props = additionalProps ? additionalProps : {};
  const element = {
    id: `${type}-${generateId()}`,
    parentId,
    type,
    ...props,
  } as CanvasElement;

  switch (element.type) {
    case BuilderComponent.CONTAINER:
      element.childrenIds = [];
      element.styles = {
        defaults: defaultElementStyles.container,
        overrides: {},
      };
      break;
    case BuilderComponent.BUTTON:
      element.variant = additionalProps.variant;
      element.color = 'primary';
      element.styles = {
        defaults: { ...defaultElementStyles.button },
        overrides: {},
      };
      if (!element.value) {
        element.value = 'Button';
      }
      if (!element.action) {
        element.action = {
          type: ButtonActionType.CHANGE_PAGE,
          target: 'NEXT',
        };
      }
      break;
    case BuilderComponent.PHASER_BUTTON:
      element.variant = 'contained';
      element.color = 'primary';
      element.styles = {
        defaults: defaultElementStyles.phaserbutton,
        overrides: {},
      };
      if (!element.value) {
        element.value = 'Button';
      }
      break;
    case BuilderComponent.TEXT:
      switch (additionalProps.variant) {
        case TextVariant.H1:
          element.value = 'H1';
          element.variant = additionalProps.variant;
          element.styles = {
            defaults: defaultElementStyles.h1,
            overrides: {},
          };
          break;
        case TextVariant.H2:
          element.value = 'H2';
          element.variant = additionalProps.variant;
          element.styles = {
            defaults: defaultElementStyles.h2,
            overrides: {},
          };
          break;
        case TextVariant.H3:
          element.value = 'H3';
          element.variant = additionalProps.variant;
          element.styles = {
            defaults: defaultElementStyles.h3,
            overrides: {},
          };
          break;
        case TextVariant.BODY:
          element.value = 'Text';
          element.variant = additionalProps.variant;
          element.styles = {
            defaults: defaultElementStyles.text,
            overrides: {},
          };
          break;
      }
      break;
    case BuilderComponent.PHASER_TEXT:
      element.styles = {
        defaults: defaultElementStyles.phasertext,
        overrides: {},
      };
      break;
    case BuilderComponent.PHASER_GAME:
      element.styles = {
        defaults: defaultElementStyles.phasergame,
        overrides: {},
      };
      break;
    case BuilderComponent.IMAGE:
      element.styles = {
        defaults: defaultElementStyles.image,
        overrides: {},
      };
      break;
    case BuilderComponent.VIDEO:
      element.styles = {
        defaults: defaultElementStyles.video,
        overrides: {},
      };
      element.controls = true;
      break;
    case BuilderComponent.FORM:
      element.styles = {
        defaults: {
          ...defaultElementStyles.container,
          ...defaultElementStyles.form,
        },
        overrides: {},
      };
      element.fields = defaultFormFields;
      break;
    case BuilderComponent.TERMS_AND_CONDITIONS:
      element.styles = {
        defaults: {
          ...defaultElementStyles.container,
          ...defaultElementStyles.form,
        },
        overrides: {},
      };
      element.fields = defaultTermsAndConditionFields;
      break;
  }
  return element;
};

export const createPage = (
  pageType: PageType,
  elementId: EntityId
): PageElement => {
  return {
    id: generatePageId(),
    name: `${pageType} page`,
    elementRoot: elementId,
    pageType,
  };
};

const getElementChildrenIds = (
  state: ElementsState,
  elementId: EntityId
): EntityId[] => {
  const element = state.elements.entities[elementId] as ContainerElement;
  if (!element) return [];

  const childrenIds = element?.childrenIds as EntityId[];

  if (childrenIds?.length) {
    let ids: EntityId[] = [...childrenIds];

    childrenIds.forEach((id: EntityId) => {
      ids = [...ids, ...getElementChildrenIds(state, id)];
    });

    return ids;
  }

  return [];
};

export const getElementIds = (
  state: ElementsState,
  pageId: EntityId
): EntityId[] => {
  const rootElementId =
    state.pages.entities[pageId]?.elementRoot ||
    state.additionalPages.entities[pageId]?.elementRoot;
  let allElementIdsOnPage: EntityId[] = [];

  if (rootElementId) {
    allElementIdsOnPage = [
      rootElementId,
      ...getElementChildrenIds(state, rootElementId),
    ];
  }

  return allElementIdsOnPage;
};

export function arrayMoveMutable<T>(
  array: T[],
  fromIndex: number,
  toIndex: number
) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

export function arrayMoveImmutable<T>(
  array: T[],
  fromIndex: number,
  toIndex: number
): T[] {
  array = [...array];
  arrayMoveMutable(array, fromIndex, toIndex);
  return array;
}

export function isBasicPage(pageType: PageType): boolean {
  return (
    pageType === PageType.Basic ||
    pageType === PageType.Game ||
    pageType === PageType.Outcome
  );
}

export function getFormId(formId: EntityId): string {
  return `submit-${formId}`;
}

export const normaliseTemplateData = (data: ITemplateData) => {
  return data.reduce(
    (previous: any, template: ITemplateDataEntry) => ({
      ...previous,
      [template.templateId]: template.data as IBuilderTemplate,
    }),
    { ...defaultTemplates }
  );
};

export const getResponsiveHeightOverride = (auto?: boolean) => ({
  styles: {
    overrides: {
      size: {
        height: {
          value: auto ? 'auto' : 150,
          unit: auto ? 'auto' : 'px',
        },
      },
    },
  },
});
