import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { builderSlice } from './builder';
import { activeTabSlice } from './activeTab';
import counter from './counter/counterSlice';
import { authSlice } from './auth';
import themeSlice from './builder/themeSlice';
import listenerMiddleware from './builder/middleware';
import { sharedSlice } from './shared';
import { campaignSlice } from './campaign';
import assetSlice from './builder/assetSlice';
import { gameSlice } from './game';
import templateSlice from './builder/templateSlice';
import analyticsSlice from './builder/analyticsSlice';

export const store = configureStore({
  reducer: {
    elements: builderSlice,
    assets: assetSlice,
    activeTab: activeTabSlice,
    theme: themeSlice,
    counter,
    auth: authSlice,
    shared: sharedSlice,
    campaign: campaignSlice,
    game: gameSlice,
    templates: templateSlice,
    analytics: analyticsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
