import { css } from '@emotion/react';

export const useStyles = () => ({
  container: css`
    display: flex;
    gap: 8px;
    align-items: center;
  `,
  input: css`
    width: 100px;
  `,
});
