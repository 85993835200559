import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const useStyles = (theme?: Theme) => {
  return {
    header: css`
      position: relative;
      background-color: ${theme?.palette.background.default};
      box-shadow: 0 5px 10px rgba(35, 71, 7, 0.15);
      color: ${theme?.palette.text.primary};
      padding: 0 66px;
      @media (max-width: 768px) {
        padding: 0 10px;
      }
      a {
        color: ${theme?.palette.text.primary};
        &:active,
        &:hover {
          background-color: transparent;
        }
      }
    `,
    container: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    `,
    headerTitle: css`
      font-size: 25px;
      @media (max-width: 479px) {
        font-size: 20px;
      }
    `,
    button: css`
      color: #666666;
    `,
  };
};
