/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useState } from 'react';
import { Box, IconButton, ListItem, Popover, Typography } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useAppSelector } from '../../../../reducers/app/hooks';
import { selectGameSettings } from '../../../../reducers/game/gameSlice';

import { IGameArrayElementProps } from '../../GameArrayEditor/IProps';
import GameNumberEditor from '../../GameNumberEditor';
import GameAssetEditor from '../../GameAssetEditor';

import { useStyles } from './styles';

const ParallaxEditor: FC<IGameArrayElementProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLImageElement | null>(null);

  const styles = useStyles();

  const gameState = useAppSelector(selectGameSettings);
  //@ts-ignore
  const texKey = gameState[props.name][props.index]['textureKey'];
  //@ts-ignore
  const imgSrc = gameState['assets'][texKey];

  const handleOpen = (event: any) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setOpen(false);

  const handleRemove = () => {
    props.removeFunction(props.index);
  };

  return (
    <ListItem data-testid="parallax-item" css={styles.listItem}>
      <div css={styles.wrapper}>
        <div css={styles.imageWrapper}>
          <img src={imgSrc} height="100%" alt={texKey} />
        </div>
        <div>
          <Typography css={styles.itemText}>{texKey}</Typography>
        </div>
        <div>
          <IconButton onClick={handleOpen}>
            <ModeEditOutlineOutlinedIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={handleRemove}>
            <DeleteOutlinedIcon fontSize="small" />
          </IconButton>
        </div>
        <Popover open={open} onClose={handleClose} anchorEl={anchorEl}>
          <div css={styles.popoverBox}>
            <Box style={{ marginBottom: 10 }}>
              <GameAssetEditor
                name="textureKey"
                scalingName=""
                index={props.index}
                arrayCategory={props.name}
                label="Asset"
              />
            </Box>
            <GameNumberEditor
              name="scrollSpeedX"
              index={props.index}
              arrayCategory={props.name}
              label="X Speed"
            />
            <GameNumberEditor
              name="scrollSpeedY"
              index={props.index}
              arrayCategory={props.name}
              label="Y Speed"
            />
          </div>
        </Popover>
      </div>
    </ListItem>
  );
};

export default ParallaxEditor;
