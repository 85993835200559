/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Masonry } from '@mui/lab';
import {
  AssetScope,
  AssetType,
  CustomError,
  IAsset,
  IAssetData,
} from '../../../types';
import { useAppDispatch, useAppSelector } from '../../../reducers/app/hooks';
import {
  brameGamesSelector,
  brameIconsSelector,
  brameImagesSelector,
  brameVideosSelector,
  globalImagesSelector,
  globalVideosSelector,
  personalImagesSelector,
  selectAsset,
} from '../../../reducers/builder/assetSlice';
import { RootState } from '../../../reducers/store';
import useAssets from '../useAssets';
import useAlert from '../../Alert/useAlert';
import MasonryCard from './MasonryCard';

export interface IProps {
  assetType: AssetType;
  library: AssetScope;
  selectedAsset: IAssetData | null;
  query: string;
}

const getBrameSelector = (assetType: AssetType) => {
  switch (assetType) {
    case AssetType.VIDEO:
      return brameVideosSelector;
    case AssetType.LOGO:
      return brameIconsSelector;
    case AssetType.GAME:
      return brameGamesSelector;
    case AssetType.PHOTO:
    default:
      return brameImagesSelector;
  }
};

const getGlobalSelector = (assetType: AssetType) => {
  switch (assetType) {
    case AssetType.VIDEO:
      return globalVideosSelector;
    case AssetType.PHOTO:
    default:
      return globalImagesSelector;
  }
};

const getPersonalSelector = (assetType: AssetType) => {
  switch (assetType) {
    case AssetType.PHOTO:
    default:
      return personalImagesSelector;
  }
};

const getSelector = (
  library: AssetScope,
  assetType: AssetType
): { (state: RootState): IAsset[] } => {
  switch (library) {
    case AssetScope.GLOBAL:
      return getGlobalSelector(assetType);
    case AssetScope.PERSONAL:
      return getPersonalSelector(assetType);
    case AssetScope.BRAME:
    default:
      return getBrameSelector(assetType);
  }
};

const AssetsLayout: FC<IProps> = ({
  assetType,
  library,
  selectedAsset,
  query,
}) => {
  const dispatch = useAppDispatch();
  const assetsList: IAsset[] = useAppSelector(getSelector(library, assetType));
  const [assets, setAssets] = useState<IAsset[]>(assetsList);
  const { removePersonalAsset } = useAssets();
  const { toast, openAlert } = useAlert();

  const onSelectAsset = (asset: IAsset) => {
    dispatch(
      selectAsset(
        selectedAsset?.uuid === asset.uuid
          ? null
          : ({
              ...asset,
              scope: library,
              type: assetType,
            } as IAssetData)
      )
    );
  };
  const onRemoveAsset = (asset: IAsset) => {
    if (library === AssetScope.PERSONAL) {
      removePersonalAsset(asset)
        .then(() => {
          openAlert('Asset was successfully removed', 'success');
        })
        .catch((error: CustomError) => {
          openAlert(error?.message || 'Asset removing has failed', 'error');
        });
    }
  };

  useEffect(() => {
    setAssets(assetsList);
  }, [setAssets, assetsList]);

  useEffect(() => {
    if (library === AssetScope.BRAME) {
      setAssets(() => {
        if (!query) return assetsList;
        return assetsList.filter(
          ({ name }) => name.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
      });
    }
  }, [library, assetsList, query]);

  return (
    <Box sx={{ width: 552, minHeight: 300, paddingTop: 2 }}>
      <Masonry columns={4} spacing={1}>
        {assets.map((asset) => (
          <MasonryCard
            key={asset.uuid}
            asset={asset}
            assetType={assetType}
            selected={selectedAsset?.uuid === asset.uuid}
            canBeRemoved={library === AssetScope.PERSONAL}
            onSelectAsset={onSelectAsset}
            onRemoveAsset={onRemoveAsset}
          />
        ))}
      </Masonry>
      {toast}
    </Box>
  );
};

export default AssetsLayout;
