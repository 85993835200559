/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { FC, useCallback, useState } from 'react';
import { Eye, Info } from 'phosphor-react';
import { EntityId } from '@reduxjs/toolkit';
import { camelCase, startCase } from 'lodash';
import {
  Button,
  Card,
  createTheme,
  Grid,
  Popover,
  ScopedCssBaseline,
  Typography,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ITheme } from '@brame/theme';
import { renderTree } from '@brame/components/src/utils/renderComponentsTree';
import { WizzardType } from '../../CampaignType/tabsData';
import { IWizzard } from '../../initialWizzardState';
import { ITemplates } from '../CampaignTemplate';
import { useStyles } from './styles';

export interface ITemplateCard {
  wizzardState: IWizzard;
  handleProgressBar: (activeType: string) => void;
  onSelect: (stepName: string, payload: IWizzard) => void;
  currentTemplate: ITemplates;
  currentTheme: ITheme | undefined;
  active: boolean;
}
const TemplateCard: FC<ITemplateCard> = ({
  wizzardState,
  handleProgressBar,
  onSelect,
  currentTemplate,
  currentTheme,
  active,
}) => {
  const styles = useStyles(active);
  const element = currentTemplate.template.elements.entities;
  const { id, name } = currentTemplate;
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;
  const rootElementId: EntityId = currentTemplate.template.elements.ids[0];
  const pageQuantity = currentTemplate.template.pages.ids.length - 1;

  const renderTreeStructure = useCallback(() => {
    return renderTree({
      rootElementId: rootElementId,
      elements: element,
      theme: currentTheme,
    });
  }, [element, currentTheme]);

  const handleActiveCard = (active: boolean) => {
    if (active) {
      onSelect(WizzardType.CAMPAIGN_TEMPLATE, {
        ...wizzardState,
        [camelCase(WizzardType.CAMPAIGN_TEMPLATE)]: {
          templateId: '',
          isDisable: false,
        },
      });
      handleProgressBar(WizzardType.CAMPAIGN_THEME);
    } else {
      onSelect(WizzardType.CAMPAIGN_TEMPLATE, {
        ...wizzardState,
        [camelCase(WizzardType.CAMPAIGN_TEMPLATE)]: {
          templateId: id.toString(),
          isDisable: true,
        },
      });
      handleProgressBar(WizzardType.CAMPAIGN_TEMPLATE);
    }
  };

  return (
    <Grid item xs={12} sm={6} lg={4} xl={3} data-testid="template-card">
      <Card
        css={styles.card}
        onMouseOver={() => setVisiblePreview(true)}
        onMouseOut={() => setVisiblePreview(false)}
      >
        <div css={styles.titleContainer}>
          <div css={styles.titleWrapper}>
            <div css={styles.title} data-testid="card-title">
              {startCase(name)}
            </div>
            <Button
              endIcon={<Info weight="light" size={23} />}
              css={styles.infoButton}
              data-testid="info-button"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            />
            <Popover
              id={popoverId}
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Typography
                sx={{ p: 2 }}
                data-testid="popover-title"
              >{`Avaliable ${pageQuantity} pages`}</Typography>
            </Popover>
          </div>
          {(visiblePreview || active) && (
            <Button
              href="#"
              endIcon={<Eye weight="light" size={23} />}
              css={styles.previewButton}
              data-testid="preview-button"
            />
          )}
        </div>

        <div
          css={styles.template}
          data-textid="template"
          onClick={() => handleActiveCard(active)}
        >
          <ThemeProvider theme={createTheme(currentTheme?.theme)}>
            <ScopedCssBaseline hidden />
            {renderTreeStructure()}
          </ThemeProvider>
        </div>
      </Card>
    </Grid>
  );
};

export default TemplateCard;
