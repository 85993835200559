import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export const useStyles = (theme?: Theme, selected?: boolean) => ({
  container: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px 0;
    background-color: ${selected ? theme?.palette.primary.main : 'transparent'};
  `,
  page: css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 40px;
    width: 80px;
    cursor: pointer;
    margin: 11px;
    border-radius: 4px;
  `,
  removeIcon: css`
    position: absolute;
    top: -14px;
    right: -14px;
    cursor: pointer;
    color: ${selected ? '#dedede' : 'red'};
  `,
  title: css`
    font-size: 15px;
    max-width: 140px;
    padding: 0 4px;
    font-weight: ${selected ? 600 : 400};
    color: ${selected
      ? theme?.palette.primary.contrastText
      : theme?.palette.text.primary};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  `,
  inputWrapper: css`
    width: 102px;
    height: 19px;
    padding: 0 5px;
  `,
  iconWrapper: css`
    position: relative;
  `,
  iconUpper: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
  `,
  groupedPagesLabel: css`
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    inset: auto auto 1px 0px !important;
  `,
  tabButton: css`
    transform: rotate(90deg) scaleY(0.66) scaleX(0.8);
    fill: ${!selected && '#ffffff'};
  `,
  groupedPagesLabelText: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    color: ${selected ? '#ffffff' : theme?.palette.text.primary};
  `,
  groupedPagesPageOuter: css`
    position: relative;
    min-width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
  `,
  groupedPagesPageInner: css`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  `,
});
