import { css } from '@emotion/react';

export const useStyles = () => ({
  inputWrapper: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  button: css`
    margin: 10px;
  `,
});
