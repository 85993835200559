/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { Box, Button, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { loseSpin } from '@brame/components/src/phaser/games/SlotMachine/SlotMachineScene';
import {
  GameStringEditor,
  GameNumberEditor,
  SlotMachineIconEditor,
  GameSizingEditor,
} from '../..';
import GameArrayEditor from '../../GameArrayEditor/GameArrayEditor';
import GameAssetEditor from '../../GameAssetEditor';
import SlotMachineOutcomeEditor from '../SlotMachineOutcomeEditor';
import { ISlotMachineToolbarProps } from './IProps';
import { useStyles } from './styles';

const SlotMachineToolbar: FC<ISlotMachineToolbarProps> = (props) => {
  const styles = useStyles();
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleLoseTest = () => {
    loseSpin();
  };

  return (
    <div data-testid="game-editor">
      <GameStringEditor
        name="game.placeHolderImage"
        label="Placeholder Image"
      />
      <TabContext value={value.toString()}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="Drop game tabs">
            <Tab label="Settings" value="0" />
            <Tab label="Visuals" value="1" />
            <Tab label="Outcomes" value="2" />
          </TabList>
        </Box>
        <TabPanel value="0" sx={{ p: 0 }}>
          <div css={styles.startDivTitle}>Basic Settings</div>
          <div css={styles.divTitle}> Game Screen Size </div>
          <div css={styles.flexDiv}>
            <GameSizingEditor
              name="game.size"
              label=""
              aspectRatio={true}
              updateAspectRatio={false}
              styleProps={props.styleProps}
              onChange={props.onChange}
            />
          </div>
        </TabPanel>
        <TabPanel value="1" sx={{ p: 0 }}>
          <GameNumberEditor name="game.iconSize" label="Icon Size" />
          <GameArrayEditor
            name="game.icons"
            label="Game Icons"
            ComponentType={SlotMachineIconEditor}
            uuid={true}
          />
          <Button onClick={handleLoseTest}> Test Loss </Button>
          <GameAssetEditor
            name="game.frameTex"
            label="Slots Frame Texture"
            scalingName="game.frameSize"
          />
          <div css={styles.divTitle}> Slot Machine Frame Position </div>
          <GameSizingEditor name="game.framePos" label="" aspectRatio={false} />
          <GameAssetEditor
            name="game.hullTex"
            label="Slots Hull Texture"
            scalingName="game.hullSize"
          />
          <div css={styles.divTitle}> Slot Machine Hull Position </div>
          <GameSizingEditor name="game.hullPos" label="" aspectRatio={false} />
        </TabPanel>
        <TabPanel value="2">
          <GameArrayEditor
            name="outcomes"
            label="Game Outcomes"
            ComponentType={SlotMachineOutcomeEditor}
            uuid={true}
          />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default SlotMachineToolbar;
